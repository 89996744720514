import { FORM_THEMES, SURVEY_THEMES } from './themes_constants'
import './SurveyThemeSelector.scss'
import { SurveyDetailsTypes } from '../SurveyDetailsSetup/hooks'
import { SURVEY } from 'pages/Settings/ManageSurveys/ManageSurveys'

interface SurveyThemeSelectorProps {
    selectedTheme: string
    handleChange: (key: keyof SurveyDetailsTypes, value: string | boolean) => void
    surveyType: string
}

function SurveyThemeSelector ({ selectedTheme, handleChange, surveyType }: SurveyThemeSelectorProps) {
  const themes = surveyType === SURVEY ? SURVEY_THEMES : FORM_THEMES
  return (
    <div className='survey-themes-container'>
      {themes.map((theme) => (
        <div key={theme.id} className={`survey-theme ${selectedTheme === theme.id ? 'selected' : ''}`} onClick={() => handleChange('selectedTheme', theme.id)}>
          <img src={theme.theme_preview} alt={theme.theme_name} />
          <span>{theme.theme_name}</span>
        </div>
      ))}
    </div>
  )
}

export default SurveyThemeSelector
