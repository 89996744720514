import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { USERS } from 'requests/Settings'
import { userQuery } from 'types/user'

export const useGetUsers = (all?: boolean) => {
  const [queryParam, setQueryParam] = useState('')
  const { data, loading, refetch }: userQuery = useQuery(USERS, {
    variables: { first: 10, search: queryParam, all }
  })

  return { data, loading, queryParam, setQueryParam, refetch }
}
