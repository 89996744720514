import { Button, SettingsHeader } from '@reply-pro/component-library'
import { FunctionComponent, SVGProps } from 'react'
import { useNavigate } from 'react-router-dom'

interface SurveysManager {
  url: string,
  title: string,
  icon: FunctionComponent<SVGProps<SVGSVGElement> & {
    title?: string | undefined;
}>
  buttonTextContent: string
}

function AccountDetailsHeader ({ url, title, icon: Icon, buttonTextContent } : SurveysManager) {
  const navigate = useNavigate()

  const goToAddNewSurvey = () => {
    navigate(url)
  }

  return (
    <SettingsHeader
      title={title}
      icon={<Icon />}
      button={<Button onClick={goToAddNewSurvey}>{buttonTextContent}</Button>}
    />
  )
}

export default AccountDetailsHeader
