import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { HIERARCHY_GROUP, HIERARCHY_GROUP_EXPAND } from 'requests/Accounts/locations'
import { HierarchyGroup } from 'types/location'
import { findAndAppendChild } from '../utils'
import { useNavigate } from 'react-router-dom'

export function useGetHierarchy ({ id }: { id?: string }) {
  const [childrenId, setChildrenId] = useState<string>('')
  const [hierarchyGroup, setHierarchyGroup] = useState<HierarchyGroup>()
  const navigate = useNavigate()

  const { data, loading } = useQuery(HIERARCHY_GROUP, {
    variables: { id }
  })

  const [getChildren, { data: childrenData }] = useLazyQuery(HIERARCHY_GROUP_EXPAND, {
    variables: { id: childrenId }
  })

  const onExpand = (id?: string) => {
    if (id) {
      setChildrenId(id)
      getChildren()
    }
  }

  const onClick = (id?: string) => {
    navigate(`/settings/location-permissions/hierarchies/${id}`)
  }

  useEffect(() => {
    setChildrenId('')
    setHierarchyGroup(data?.hierarchyGroup)
  }, [data])

  useEffect(() => {
    if (childrenId && childrenData) {
      setHierarchyGroup((prevData) => findAndAppendChild(prevData, childrenId, childrenData.hierarchyGroup) as HierarchyGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenId, childrenData])

  useEffect(() => {
    setHierarchyGroup(data?.hierarchyGroup)
  }, [data])

  return { hierarchyGroup, loading, onClick, onExpand }
}
