import { SettingsPageStructure, SettingsHeader, MaintenanceIcon, NavItem, ToggleInput, PencilIcon } from '@reply-pro/component-library'
import './MaintenancePage.scss'
import { useMaintenance } from './hooks'
import MaintenanceModal from './MaintenanceModal/MaintenanceModal'

function MaintenancePage () {
  const { featureMaintenance, featuresForMaintenanceArray, isModalOpen, openMaintenanceModal, closeMaintenanceModal, maintenanceDetails, getIsMaintenance, turnOnMaintenance, turnOffMaintenance, setMaintenanceDetails } = useMaintenance()

  return (
    <SettingsPageStructure
      settingsHeader={
        <SettingsHeader
          title='Maintenance'
          icon={<MaintenanceIcon />}
        />
      }
    >
      <div className='feature-list'>
        {featuresForMaintenanceArray.map((feature) => {
          const featureDetails = featureMaintenance[feature as keyof typeof featureMaintenance]

          const isMaintenanceActive = featureDetails && getIsMaintenance(featureDetails)

          return (
            <NavItem className='feature-nav' key={feature}>
              <div className='single-feature'>
                <div className='feature-name'>
                  {feature}
                </div>
                <div className='feature-actions'>
                  <PencilIcon
                    className={`edit-maintenance ${!isMaintenanceActive ? 'disabled' : ''}`}
                    onClick={() => openMaintenanceModal(feature, 'edit')}
                  />
                  <ToggleInput
                    checked={isMaintenanceActive}
                    onChange={() => isMaintenanceActive ? turnOffMaintenance(feature) : openMaintenanceModal(feature, 'toggle')}
                  />
                </div>
              </div>
            </NavItem>
          )
        })}
      </div>
      {isModalOpen && maintenanceDetails && <MaintenanceModal isModalOpen={isModalOpen} closeMaintenanceModal={closeMaintenanceModal} maintenanceDetails={maintenanceDetails} turnOnMaintenance={turnOnMaintenance} setMaintenanceDetails={setMaintenanceDetails} />}
    </SettingsPageStructure>
  )
}

export default MaintenancePage
