import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { retrieveSurvey } from 'store/surveysSlice/surveysDispatchers'

export const useSurveySetupId = (id?: string) => {
  const dispatch: AppDispatch = useDispatch()
  useEffect(() => {
    if (id) {
      dispatch(retrieveSurvey(id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
}
