import { gql } from '@apollo/client'

export const REQUEST_PASSWORD_RESET = gql`
  mutation userRequestPasswordReset($input: UserRequestPasswordResetInput!) {
    userRequestPasswordReset(input: $input) {
      created {
        id
      }
      errors {
        message
        code
      }
      success
    }
  }
`
export const USER_NEW_PASSWORD = gql`
  mutation userNewPasswordSetupByEmployee($input: UserNewPasswordSetupByEmployeeInput!) {
    userNewPasswordSetupByEmployee(input: $input) {
        modified {
          id
        }
        errors {
          message
          code
        }
        success
    }
  }
`

export const USER_GROUP_ADD_GROUP = gql`
  mutation userGroupAddNew($input: UserGroupAddNewInput!) {
      userGroupAddNew(input: $input) {
          created {
              id
              data
          }
          errors {
              message
              code
          }
          success
      }
  }
`

export const USER_GROUP_EDIT_GROUP = gql`
  mutation userGroupEdit($input: UserGroupEditInput!) {
    userGroupEdit(input: $input) {
      modified {
        id
        data
      }
      errors {
        message
        code
      }
      success
    }
  }
`

export const USER_GROUP_REMOVE_GROUP = gql`
  mutation userGroupRemove($input: UserGroupRemoveInput!) {
    userGroupRemove(input: $input) {
      deleted {
        id
      }
      errors {
        message
        code
      }
      success
    }
  }
`

export const GET_USER_GROUPS = gql`
  query userGroups(
          $search: String,
          $offset: Int,
          $before: String,
          $after: String,
          $first: Int,
          $last: Int
      ) {
      userGroups(
          search: $search,
          offset: $offset,
          before: $before,
          after: $after,
          first: $first,
          last: $last
      ) {
          edges {
              node {
                  id
                  name
                  description
                  userCount
              }
          }
      }
  }
`

export const GET_USER_GROUPS_FEATURES = gql`
  query userGroups(
          $search: String,
          $sortByFeatures: Boolean,
          $offset: Int,
          $before: String,
          $after: String,
          $first: Int,
          $last: Int
      ) {
      userGroups(
          search: $search,
          sortByFeatures: $sortByFeatures,
          offset: $offset,
          before: $before,
          after: $after,
          first: $first,
          last: $last
      ) {
          edges {
              node {
                  id
                  name
                  description
                  features
                  hasReportPage
              }
          }
      }
  }
`

export const USER_GROUP_SET_FEATURES = gql`
  mutation userGroupSetFeatures($input: UserGroupSetFeaturesInput!) {
    userGroupSetFeatures(input: $input) {
      modified {
        id
        data
      }
      errors {
        message
        code
      }
      success
    }
  }
`

export const USER_GROUP_ADD_USERS = gql`
  mutation userGroupAddUsers($input: UserGroupAddUsersInput!) {
    userGroupAddUsers(input: $input) {
      modified {
        id
        data
      }
      errors {
        message
        code
      }
      success
    }
  }
`

export const USER_GROUP_REMOVE_USERS = gql`
  mutation userGroupRemoveUsers($input: UserGroupRemoveUsersInput!) {
    userGroupRemoveUsers(input: $input) {
      deleted {
        id
        data
      }
      errors {
        message
        code
      }
      success
    }
  }
`

export const USER_GROUP_USERS = gql`
  query userGroupUsers(
    $userGroup: GlobalID!,
    $search: String,
    $offset: Int,
    $before: String,
    $after: String,
    $first: Int,
    $last: Int
  ) {
    userGroupUsers(
      userGroup: $userGroup,
      search: $search,
      offset: $offset,
      before: $before,
      after: $after,
      first: $first,
      last: $last
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`
