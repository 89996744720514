import { Button, NotificationsOffIcon, NotificationsOnIcon } from '@reply-pro/component-library'

interface ToggleAccountNotificationsProps {
    id: string
    notificationsDeactivated?: boolean
    toggleModalOpen: ({ id } : {id: string}) => void
}

function ToggleAccountNotifications ({ toggleModalOpen, notificationsDeactivated, id }: ToggleAccountNotificationsProps) {
  return (
    <div className='round-buttons'>
      <Button
        className='toggle-notifications-button'
        backgroundColor={notificationsDeactivated ? 'lightestGray' : 'mainLight'}
        color={notificationsDeactivated ? 'gray' : 'white'}
        small
        onClick={() => toggleModalOpen({ id })}
      >
        {notificationsDeactivated ? <NotificationsOffIcon className='button-icon' /> : <NotificationsOnIcon className='button-icon' />}
      </Button>
    </div>
  )
}

export default ToggleAccountNotifications
