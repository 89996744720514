import { Conditions, SettingsHeader } from '@reply-pro/component-library'

function LocationPermissionsHeader () {
  return (
    <SettingsHeader
      title='Location Permissions'
      subtitle="See user's access through the different Location Groups and Hierarchies."
      icon={<Conditions className='icon-title' />}
    />
  )
}

export default LocationPermissionsHeader
