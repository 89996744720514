import { Button } from '@reply-pro/component-library'
import { useState } from 'react'
import { useAddUserGroup } from './hooks'
import UserGroupEditor from '../UserGroupEditor'

interface AddUserGroupProps {
  searchValueUserGroups?: string
}

const AddUserGroup = ({ searchValueUserGroups }: AddUserGroupProps) => {
  const [showModal, setShowModal] = useState(false)
  const { handleOnSubmit, loading, error } = useAddUserGroup({ setShowModal, searchValueUserGroups })

  return (
    <>
      <Button onClick={() => setShowModal(true)}>Add User Group</Button>
      {showModal &&
        <UserGroupEditor
          showModal={showModal}
          setShowModal={setShowModal}
          handleOnSubmit={handleOnSubmit}
          loading={loading}
          error={error}
        />}
    </>
  )
}

export default AddUserGroup
