import { ReplyProRocketLogo, MessageNavItem, Action, Mail, MultiActionButton, PencilIcon, NavigationBar, NavigationLink, Inbox, Reports, Settings, Rocket, Button } from '@reply-pro/component-library'
import { parseWhiteLabelInAppData } from 'utils/whiteLabel/parseWhiteLabelData'
import { useState } from 'react'
import classNames from 'classnames'
import './ComponentsShowroom.scss'

interface ComponentsShowroomProps {
  whiteLabelData?: ReturnType<typeof parseWhiteLabelInAppData> | null
}

const ComponentsShowroom = ({ whiteLabelData }: ComponentsShowroomProps) => {
  const [selectedInteraction, setSelectedInteraction] = useState(1)
  const [activeNavLink, setActiveNavLink] = useState(1)

  return (
    <div className='in-app-components-show-room'>
      <div className='top-components'>
        {!whiteLabelData?.logo
          ? <ReplyProRocketLogo className='logo' />
          : <img className='brand-logo' src={whiteLabelData?.logo} alt='brand-logo' />}
        {!whiteLabelData?.loginLogo
          ? <ReplyProRocketLogo className='logo' />
          : <img className='brand-logo' src={whiteLabelData?.loginLogo} alt='brand-logo' />}
      </div>
      <div className='inbox'>
        <div className='interactions'>
          <MessageNavItem
            key='interaction-example-1'
            to='Kassie Jones'
            from='Kassie Jones'
            preview='Experience of a lifetime'
            className={classNames('interaction', { active: selectedInteraction === 1 })}
            multiple={10}
            timestamp={new Date()}
            color={whiteLabelData?.brandColor}
            onClick={() => setSelectedInteraction(1)}
          />
          <MessageNavItem
            key='interaction-example-2'
            to='Jackson Browne'
            from='Jackson Browne'
            preview='Thank you!'
            className={classNames('interaction', { active: selectedInteraction === 2 })}
            multiple={2}
            timestamp={new Date()}
            color={whiteLabelData?.brandColor}
            onClick={() => setSelectedInteraction(2)}
          />
        </div>
        <Action
          className='action'
          align='incoming'
          from={selectedInteraction === 1 ? 'Kimberly McElroy' : 'Jackson Browne'}
          to={[{ name: 'ReplyPro Customer Care', attribute: 'replypro.cc@replypro.io' }]}
          sourceIcon={Mail}
          sourceLabel='Email'
          isOriginallyToDisplayed={false}
          subject={selectedInteraction === 1 ? 'Experience of a lifetime' : 'Thank you!'}
          timestamp={new Date()}
          color={whiteLabelData?.brandColor}
        >
          {selectedInteraction === 1 ? ' Fantastic burger, highly recommended! Absolutely delicious. The French fries were also excellent.' : 'Thank you! The night at Rocket Restaurant was the best night ever.'}
        </Action>
      </div>
      <div className='bottom-components'>
        <Button
          className='single-action-button'
          backgroundColor={whiteLabelData?.loginBrandColor || whiteLabelData?.brandColor}
        >
          Log In
        </Button>
        <MultiActionButton
          className='multi-action-button'
          actions={[{
            label: 'Campaign',
            onClick: () => null
          }]}
          backgroundColor={whiteLabelData?.brandColor}
        >
          <PencilIcon />Start New
        </MultiActionButton>
        <NavigationBar className='nav-bar'>
          <NavigationLink className={classNames({ active: activeNavLink === 1 })} onClick={() => setActiveNavLink(1)} icon={<Inbox />} color={whiteLabelData?.brandColor} />
          <NavigationLink className={classNames({ active: activeNavLink === 2 })} onClick={() => setActiveNavLink(2)} icon={<Reports />} color={whiteLabelData?.brandColor} />
          <NavigationLink className={classNames({ active: activeNavLink === 3 })} onClick={() => setActiveNavLink(3)} icon={<Settings />} color={whiteLabelData?.brandColor} />
        </NavigationBar>
        <MultiActionButton
          className='multi-action-button'
          actions={[{
            label: 'Send & Close Interaction',
            onClick: () => null
          }]}
          backgroundColor={whiteLabelData?.brandColor}
        >
          <Rocket />Send
        </MultiActionButton>
      </div>
    </div>
  )
}

export default ComponentsShowroom
