import { SelectInput } from '@reply-pro/component-library'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'store'
import { retrieveAccounts, setAccount } from 'store/accountsSlice/accountsDispatchers'
import { useDispatchAction } from 'utils/common/useDispatchAction'
import { SetStateAction, Dispatch } from 'react'
import { eventTargetValue } from 'types/common'
import { accountSetFromDropdownTrackingEvent } from 'utils/dataTracking/accountsTracking'
import './accountSetter.scss'

type AccountSetterProps = {
  accountSelected?: string
  setAccountSelected: Dispatch<SetStateAction<string | undefined>>
}
function AccountSetter ({ accountSelected, setAccountSelected }: AccountSetterProps) {
  const accounts = useSelector((state: RootState) => state.accounts)
  const { data: accountsData, loading } = useSelector((state: RootState) => state.accounts.accounts)

  const dispatch = useDispatch<AppDispatch>()

  const handleSetAccount = (e: eventTargetValue) => {
    dispatch(setAccount(e.target.value[0]))
    accountSetFromDropdownTrackingEvent(e.target.value[0])
    setAccountSelected(e.target.value[1])
    window.location.reload()
  }

  useDispatchAction(retrieveAccounts)
  return (
    <div className='account-selector-container'>
      <SelectInput
        className='account-selector'
        value={accountSelected || accounts.accounts.data?.accounts.edges.find(account => account.node.id === accounts.accountId)?.node.name}
        onChange={handleSetAccount}
        popoverAlign='left'
        popoverWidth={220}
        placeholder='Select Account'
        loading={loading}
      >
        {
        accountsData?.accounts.edges.map(({ node }) => (
          <option
            key={node.id}
            value={[node.id, node.name]}
          >
            {node.name}
          </option>
        ))
    }
      </SelectInput>
    </div>
  )
}

export default AccountSetter
