import { Edit } from '@reply-pro/component-library'
import ReportEditor from 'components/ReportEditor'
import Tooltip from 'components/Tooltip/Tooltip'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { setReportPageUserGroups, setReportPageUsers } from 'store/settingsSlice'
import { editReportPage } from 'store/settingsSlice/settingsReducers'
import { userGroupNode, userNode } from 'store/settingsSlice/settingsTypes'
import { createMetabaseUrlFromEmbed, getMetabaseDashboardIdFromEmbed } from 'utils/common/metabaseUrlHandlers'
import EMBED_TYPE from 'utils/constants/embedType'

interface EditUsersProps {
  priorReportUsers: userNode[]
  priorReportUserGroups: userGroupNode[]
  reportPageId: string
  priorLink?: string
  priorRemoteId?: string
  priorName: string
  priorEmbedType: keyof typeof EMBED_TYPE
  reportSectionId: string
  authorized: boolean
  parameters: Record<string, string[]>
}

export default function EditReport ({ priorReportUsers, priorReportUserGroups, reportPageId, priorLink, priorRemoteId, priorName, priorEmbedType, reportSectionId, authorized, parameters }: EditUsersProps) {
  const users = useSelector((state: RootState) => state.settings.reportPageUsers)
  const userGroups = useSelector((state: RootState) => state.settings.reportPageUserGroups)

  const [isShowEditReportModal, setIsShowEditReportModal] = useState(false)
  const [isForAllUsers, setIsForAllUsers] = useState(priorReportUsers.length === 0 && priorReportUserGroups.length === 0)
  const [reportName, setReportName] = useState(priorName)
  const [isBasicEmbedType, setIsBasicEmbedType] = useState(priorEmbedType === EMBED_TYPE.basic)
  const [reportLink, setReportLink] = useState(priorEmbedType === EMBED_TYPE.basic ? priorLink : priorLink ? getMetabaseDashboardIdFromEmbed(priorLink) : '')
  const [reportDashboardId, setReportDashboardId] = useState(priorEmbedType !== EMBED_TYPE.basic ? priorRemoteId : '')
  const [isAuthorized, setIsAuthorized] = useState(authorized)
  const [addedFilters, setAddedFilters] = useState<Record<string, string[] | null> | undefined>(Object.keys(parameters).length > 0 ? parameters : undefined)

  const dispatch = useDispatch<AppDispatch>()
  const toggleIsShowEditReportModal = () => {
    if (!isShowEditReportModal) {
      dispatch(setReportPageUsers(priorReportUsers))
      dispatch(setReportPageUserGroups(priorReportUserGroups))
    }
    setIsShowEditReportModal(prev => !prev)
  }

  const editReport = () => {
    dispatch(editReportPage({
      reportPageId,
      isForAllUsers,
      name: reportName,
      link: reportLink,
      remoteId: !isBasicEmbedType ? reportDashboardId : undefined,
      authorized: isAuthorized,
      parameters: addedFilters
    }))
    toggleIsShowEditReportModal()
  }

  const usersEdit = useRef(null)
  return (
    <div ref={usersEdit}>
      <Edit onClick={toggleIsShowEditReportModal} className='action-icon' />
      <Tooltip anchor={usersEdit}>
        Edit report
      </Tooltip>
      <ReportEditor
        title='Edit Report'
        isShowModal={isShowEditReportModal}
        toggleModalOpen={toggleIsShowEditReportModal}
        reportName={reportName}
        isBasicEmbedType={isBasicEmbedType}
        reportLink={priorEmbedType === EMBED_TYPE.basic ? priorLink : priorLink && priorRemoteId ? createMetabaseUrlFromEmbed(priorLink, priorRemoteId) : ''}
        onSubmit={editReport}
        setReportName={setReportName}
        setIsBasicEmbedType={setIsBasicEmbedType}
        setReportLink={setReportLink}
        setReportDashboardId={setReportDashboardId}
        isForAllUsers={isForAllUsers}
        setIsForAllUsers={setIsForAllUsers}
        isAuthorized={isAuthorized}
        setIsAuthorized={setIsAuthorized}
        addedFilters={addedFilters}
        setAddedFilters={setAddedFilters}
        disabled={(!isForAllUsers && Object.values(users).length === 0 && Object.values(userGroups).length === 0) || !reportName || !reportLink}
        disabledReason={!reportName ? 'Input a report name to proceed' : !reportLink ? 'Input a report link (basic) or a URL (custom) to proceed' : 'Toggle ‘All Users’ or select individual users to proceed.'}
      />
    </div>
  )
}
