import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { retrieveLocationGroups, retrieveHierarchyGroups } from 'store/accountsSlice/locations/dispatchers.locations'

export function useFetchLocationPermissions () {
  const dispatch: AppDispatch = useDispatch()

  const { data } = useSelector((store: RootState) => store.locations.locationGroups)
  const { data: hierarchyGroupsData } = useSelector((store: RootState) => store.locations.hierarchyGroups)

  useEffect(() => {
    if (!data || !hierarchyGroupsData) {
      dispatch(retrieveLocationGroups(''))
      dispatch(retrieveHierarchyGroups(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hierarchyGroupsData])
}
