import { reportPage } from 'store/settingsSlice/settingsTypes'
import Report from './Report/Report'
import './reportsList.scss'

interface ReportsListProps {
  reportPages: reportPage[]
  sectionId: string
}

export default function ReportsList ({ reportPages, sectionId }:ReportsListProps) {
  return (
    <section className='report-list'>
      <div className='report-list-header'>REPORT</div>
      <div className='report-list-header'>PERMISSIONS</div>
      <div className='report-list-header'>ACTIONS</div>
      {reportPages.map((page, index) =>
        <Report
          users={page.users.edges}
          userGroups={page.userGroups.edges}
          reportName={page.name}
          isForAnyUser={page.isForAnyUser}
          key={`${index}-${page.name}-${page.link}`}
          link={page.link}
          remoteId={page.remoteId}
          embedType={page.embedType}
          reportId={page.id}
          sectionId={sectionId}
          authorized={page.authorized}
          parameters={JSON.parse(page.parameters)}
        />)}
    </section>
  )
}
