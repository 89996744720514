/* eslint-disable operator-linebreak */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Button, LoadingSpinner, MultiSelectInput, PencilIcon, PlusAdd, SelectInput, TextInput, X } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import { LockedFilter } from 'types/metabase'
import Tooltip from 'components/Tooltip'
import { isEqual } from 'lodash'
import { useGetFilterValues, useGetLockedParams } from './hooks'
import './ManageCustomFilters.scss'
import arraysHaveSameElements from 'utils/common/arraysHaveSameElements'

const AddCustomFilters = ({
  showCustomFiltersModal,
  setShowCustomFiltersModal,
  baseUrl,
  dashboardId,
  addedFilters,
  setAddedFilters
}:{
    showCustomFiltersModal: boolean
    setShowCustomFiltersModal: Dispatch<SetStateAction<boolean>>
    baseUrl: string
    dashboardId: string,
    addedFilters?: Record<string, string[] | null>
    setAddedFilters: Dispatch<SetStateAction<Record<string, string[] | null> | undefined>>
  }) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]> | undefined>(undefined)
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const { dashboardFilters, loadingFilters, dashboardDoesNotExist, getLockedParams } = useGetLockedParams(baseUrl, dashboardId)
  const { filterValues, loadingFilterValues, getFilterValues } = useGetFilterValues(baseUrl, dashboardId, { dashboardFilters, selectedFilters, setSelectedValues })

  const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined)

  const handleRemoveFilter = async (key: string) => {
    const { [key]: _, ...remainingKeys } = selectedFilters as Record<string, string[]>
    setSelectedFilters(remainingKeys)

    if (Object.keys(remainingKeys).length === 0) {
      setSelectedFilters(undefined)
    }

    if (selectedKey) {
      const { [key]: _, ...otherSelectedFilters } = selectedFilters ?? {}
      await getFilterValues(selectedKey, otherSelectedFilters)
    }
  }

  const handleSetFilters = () => {
    if (selectedFilters) {
      const addedFiltersEntriesWithSlug = Object.entries(selectedFilters).map(([key, value]) => [dashboardFilters?.find(dashboardFilter => dashboardFilter.id === key)!.slug, value])
      const notAddedFiltersEntriesWithSlug: ([string, null])[] = []
      dashboardFilters?.forEach(dashboardFilter => !Object.keys(selectedFilters).some(key => key === dashboardFilter.id) && notAddedFiltersEntriesWithSlug.push([dashboardFilter.slug, null]))
      const addedFilters = Object.fromEntries([...addedFiltersEntriesWithSlug, ...notAddedFiltersEntriesWithSlug])
      setAddedFilters(addedFilters)
    } else {
      setAddedFilters(undefined)
    }
    setShowCustomFiltersModal(prev => !prev)
  }

  useEffect(() => {
    if (dashboardFilters && addedFilters) {
      setSelectedFilters(slugKeysToIdsKeys(dashboardFilters, addedFilters))
    }
  }, [addedFilters, dashboardFilters])

  return (
    <Modal
      title='Add Custom Filters'
      className='add-custom-filters'
      showModal={showCustomFiltersModal}
      toggleModalOpen={() => setShowCustomFiltersModal(prev => !prev)}
      actions={
        <Button
          onClick={handleSetFilters}
          disabled={!dashboardFilters ? true : isEqual(selectedFilters, addedFilters ? slugKeysToIdsKeys(dashboardFilters, addedFilters) : undefined)}
        >
          Set Filters
        </Button>
      }
    >
      <div className='metabase-filters-body'>
        <div className='metabase-selected-filters'>
          {loadingFilters ?
            <div className='metabase-no-filters'><LoadingSpinner size='small' /></div> :
              !selectedFilters ?
                dashboardDoesNotExist ?
                  <div className='metabase-no-filters'>The entered dashboard doesn't exist</div> :
                  dashboardFilters?.length === 0 ?
                    <div className='metabase-no-filters'>
                      <div>This dashboard doesn't have locked filters</div>
                      <Button small stroked onClick={getLockedParams}>Update</Button>
                    </div> :
                    <div className='metabase-no-filters'>There are no filters yet</div> :
                Object.entries(selectedFilters).map(([filterKey, filterValue]) =>
                  <div key={filterKey} className='metabase-selected-filter'>
                    <span>{dashboardFilters?.find(filter => filter.id === filterKey)?.name}</span>
                    <span>{filterValue.join(', ')}</span>
                    <button onClick={() => handleRemoveFilter(filterKey)}><X /></button>
                  </div>)}
        </div>
        <FilterAdd
          dashboardFilters={dashboardFilters}
          setSelectedFilters={setSelectedFilters}
          getFilterValues={getFilterValues}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          filterValues={filterValues}
          loadingFilterValues={loadingFilterValues}
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          selectedFilters={selectedFilters}
        />
      </div>
    </Modal>
  )
}

const FilterAdd = ({
  dashboardFilters,
  selectedFilters,
  setSelectedFilters,
  selectedValues,
  setSelectedValues,
  selectedKey,
  setSelectedKey,
  filterValues,
  getFilterValues,
  loadingFilterValues
}: {
  dashboardFilters?: LockedFilter[]
  selectedFilters?: Record<string, string[]>
  setSelectedFilters: Dispatch<SetStateAction<Record<string, string[]> | undefined>>
  selectedValues: string[]
  setSelectedValues: Dispatch<SetStateAction<string[]>>
  selectedKey: string | undefined
  setSelectedKey: Dispatch<SetStateAction<string | undefined>>
  filterValues?: string[]
  getFilterValues: (value: string, otherSelectedFilters: Record<string, string[]>) => Promise<void>
  loadingFilterValues: boolean
}) => {
  const dateRef = useRef<HTMLInputElement>(null)

  const handleSelectedFilterKey = async (filterKey: string) => {
    setSelectedKey(filterKey)

    const { [filterKey]: _, ...otherSelectedFilters } = selectedFilters ?? {}
    await getFilterValues(filterKey, otherSelectedFilters)
  }

  const handleAddFilterValue = (filterValue: string) => {
    setSelectedValues((prev) => [...(prev ?? []), filterValue])
  }

  const handleRemoveFilterValue = (filterValue: string) => {
    const newValues = selectedValues.filter(value => value !== filterValue)
    setSelectedValues(newValues)
  }

  const handleAddFilters = () => {
    setSelectedKey(undefined)
    setSelectedValues([])
    setSelectedFilters(prev => ({ ...prev, [selectedKey as string]: selectedValues as string[] }))
  }

  return (
    <div className='metabase-filters-add'>
      <SelectInput
        className='metabase-filters-select-input'
        label='Filter Key'
        value={selectedKey}
        disabled={!dashboardFilters || dashboardFilters.length === 0}
        onChange={(e) => handleSelectedFilterKey(e.target.value)}
      >
        {dashboardFilters?.map(filter =>
          <option key={filter.id} value={filter.id}>
            <div className='metabase-key-option'>
              <span>{filter.name}</span>
              <span>{selectedFilters && Object.keys(selectedFilters).some(key => key === filter.id) && <PencilIcon className='pencil-icon' />}</span>
            </div>
          </option>
        )}
      </SelectInput>
      {dashboardFilters?.find(filter => filter.id === selectedKey)?.sectionId !== 'date' ?
        <MultiSelectInput
          className='metabase-filters-select-input'
          label='Filter Value'
          values={selectedValues}
          loading={loadingFilterValues}
          disabled={!selectedKey || loadingFilterValues}
          onAdd={handleAddFilterValue}
          onRemove={handleRemoveFilterValue}
          onClear={() => setSelectedValues([])}
        >
          {filterValues?.map((filterValue, index) =>
            <option key={`filter-value-${index}`} value={filterValue}>{filterValue}</option>
          )}
        </MultiSelectInput> :
        <>
          <Tooltip anchor={dateRef}>Dates are not yet supported</Tooltip>
          <TextInput ref={dateRef} type='date' className='metabase-filters-select-input' disabled />
        </>}
      <Button
        icon
        className='metabase-filters-add-button'
        onClick={handleAddFilters}
        disabled={!selectedKey || selectedValues.length === 0 || (selectedFilters && Array.isArray(selectedFilters[selectedKey]) && Array.isArray(selectedValues) && arraysHaveSameElements(selectedFilters[selectedKey], selectedValues))}
      >
        {selectedFilters && Object.keys(selectedFilters).some(key => key === selectedKey) ? <PencilIcon /> : <PlusAdd />}
      </Button>
    </div>
  )
}

export default AddCustomFilters

const slugKeysToIdsKeys = (dashboardFilters: LockedFilter[], addedFilters: Record<string, string[] | null>) =>
  Object.fromEntries(
    Object.entries(addedFilters)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => [dashboardFilters?.find(dashboardFilter => dashboardFilter.slug === key)?.id, value])) as Record<string, string[]>
