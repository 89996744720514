/* eslint-disable operator-linebreak */
import { useState } from 'react'
import { RootState } from 'store'
import './collectionDetail.scss'
import { useSelector } from 'react-redux'
import SectionsList from './SectionsList'
import { Button, LoadingSpinner, SearchInput } from '@reply-pro/component-library'
import AddNewSectionModal from '../AddNewModal'
import { addReportSection } from 'store/settingsSlice/settingsReducers'

export default function CollectionDetail () {
  const [isAddNewSectionModalOpen, setIsAddNewSectionModalOpen] = useState(false)
  const { reportCollectionDetail, reportCollectionDetailLoading } = useSelector((state: RootState) => state.settings)
  const toggleIsAddNewSectionModalOpen = () => {
    setIsAddNewSectionModalOpen((prev) => !prev)
  }

  if (reportCollectionDetailLoading) {
    return <div className='central-loading-spinner'><LoadingSpinner /></div>
  }
  return (
    <>
      {reportCollectionDetail ?
        <>
          <div className='collection-detail-header'>
            <div>
              <h2>{reportCollectionDetail!.name}</h2>
            </div>
            <div className='report-sections-actions'>
              <SearchInput><option value='none'>none</option><option value='2'>none</option></SearchInput>
              <Button className='add-collection-button' onClick={toggleIsAddNewSectionModalOpen}>Add New Section</Button>
              <AddNewSectionModal
                toggleIsAddNewModalOpen={toggleIsAddNewSectionModalOpen}
                isAddNewModalOpen={isAddNewSectionModalOpen}
                dispatchAddNew={addReportSection}
                title='NEW SECTION'
                inputLabel='name of section'
              >
                <h3>Section Name</h3>
                <div>Sections are used by Report Views to segment your reports and provide ordering capabilities.</div>
              </AddNewSectionModal>
            </div>
          </div>
          <SectionsList />
        </>
        : <span className='no-items-found'>No collection Selected</span>}
    </>
  )
}
