import { Route, Routes, Navigate } from 'react-router-dom'
import NotFoundPage from 'components/NotFoundPage'
import ManageReports from 'pages/Settings/ManageReports'
import ManageAccount from 'pages/Settings/ManageAccounts'
import LocationPermissionsRouter from 'Router/LocationPermissionsRouter'
import UsersTabs from 'pages/Settings/ManageUsers/UsersTabs'
import AccountDetails from 'pages/Settings/AccountDetails'
import ManageSurveys from 'pages/Settings/ManageSurveys'
import SurveySetup from 'pages/Settings/ManageSurveys/SurveySetup'
import UserGroupsSettings from 'pages/Settings/UserGroups/UserGroups'
import ManageForms from 'pages/Settings/ManageForms'
import FormSetup from 'pages/Settings/ManageForms/FormSetup/FormSetup'
import MaintenancePage from 'pages/Settings/MaintenancePage'
import Webhooks from 'pages/Settings/Webhooks'

export default function SettingsRouter () {
  return (
    <Routes>
      <Route path='/account-details/' element={<AccountDetails />} />
      <Route path='/manage-reports/*' element={<ManageReports />} />
      <Route
        path='/manage-account/*'
        element={<ManageAccount />}
      />
      <Route path='/manage-users/*' element={<UsersTabs />} />
      <Route path='/user-groups/*' element={<UserGroupsSettings />} />
      <Route
        path='/'
        element={<Navigate to='/settings/manage-reports' replace />}
      />
      <Route
        path='/location-permissions/*'
        element={<LocationPermissionsRouter />}
      />
      <Route
        path='/manage-surveys/*'
        element={<ManageSurveys />}
      />
      <Route
        path='/manage-surveys/survey-setup/:id'
        element={<SurveySetup />}
      />
      <Route
        path='/manage-surveys/survey-setup/'
        element={<SurveySetup />}
      />
      <Route
        path='/manage-forms/*'
        element={<ManageForms />}
      />
      <Route
        path='/manage-forms/form-setup/:id'
        element={<FormSetup />}
      />
      <Route
        path='/manage-forms/form-setup/'
        element={<FormSetup />}
      />
      <Route
        path='/maintenance'
        element={<MaintenancePage />}
      />
      <Route
        path='/client-webhooks'
        element={<Webhooks />}
      />
      <Route path='*' element={<NotFoundPage routeToNavigate='settings/manage-reports' />} />
    </Routes>
  )
}
