import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { closeNotifications } from 'store/settingsSlice'

export const useNotifications = () => {
  const dispatch: AppDispatch = useDispatch()

  const { successMessage, errorMessage, loadingMessage, warningMessage } = useSelector((store: RootState) => store.settings)

  const dismissNotifications = () => dispatch(closeNotifications())
  return { successMessage, errorMessage, dismissNotifications, loadingMessage, warningMessage }
}
