import SelectButton from 'components/SelectButton'

export type FilterOptions = {
  [key: string]: {
    label: string
    value?: boolean
    isExperimental?: boolean
  }
}

export interface FilterSelectButtonProps {
  filterBy: FilterOptions
  setSelectedFilter?: (element: string) => void
  selectedFilter?: string
  loading?: boolean
  deactivateButton?: string | boolean
  className?: string
  small?: boolean
}

export default function FilterSelectButton ({ small, className, filterBy, setSelectedFilter, selectedFilter, loading, deactivateButton }: FilterSelectButtonProps) {
  return (
    <>
      {Object.values(filterBy).map(({ label }) => (
        <SelectButton
          key={label}
          disabled={deactivateButton === label || loading}
          onClick={() => setSelectedFilter && setSelectedFilter(label)}
          active={!loading && selectedFilter === label}
          small={small}
          className={`${className}-${label
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^a-z-]+/g, '')
            .replace(/-+/g, '-')}`}
        >
          {label}
        </SelectButton>
      ))}
    </>
  )
}
