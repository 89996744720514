import { ArrowRight, Conditions } from '@reply-pro/component-library'
import { useNavigate } from 'react-router-dom'
import './LocationParentNav.scss'

interface LocationParentNavProps {
  id: string
  name: string
}

function LocationParentNav ({ id, name }: LocationParentNavProps) {
  const navigate = useNavigate()

  const navigateToParent = () => {
    navigate(`/settings/location-permissions/groups/${id}`)
  }

  return (
    <div
      className='location-parent'
      onClick={navigateToParent}
    >
      <Conditions className='parent-icon' /> <span>This Location Group is part of {name} Location Group</span> <ArrowRight className='parent-icon' />
    </div>
  )
}
export default LocationParentNav
