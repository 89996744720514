import { ArrowRight } from '@reply-pro/component-library'
import { LOCATION_PERMISSIONS } from 'pages/Settings/LocationPermissionsSettings/constants'
import { useNavigate } from 'react-router-dom'

interface UserPermissionNavProps {
    id: string
    name: string
    type: string
}

const { GROUPS } = LOCATION_PERMISSIONS

function UserPermissionNav ({ id, name, type }: UserPermissionNavProps) {
  const navigate = useNavigate()
  return (
    <div onClick={() => navigate(`/settings/location-permissions/${type}/${id}`)} className='location-group'>
      <div className='location-group-container'>
        <div className='location-group'>
          <span className='location-group-name'>{name}</span>
          <div className='location-group-details'>
            <span className='type'>{type === GROUPS ? 'Location Group' : 'Hierarchy Group'}</span>
            <ArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserPermissionNav
