import { gql } from '@apollo/client'

export const ADD_SURVEY = gql`
mutation surveyAdd($input: SurveyAddInput!) {
  surveyAdd(input: $input) {
    created {
      id
    }
    errors {
      message
      code
    }
    success
  }
}
`

export const SURVEYS = gql`
query surveys(
  $offset: Int,
  $before: String,
  $after: String,
  $first: Int,
  $last: Int,
  $id: GlobalID,
  $remoteId: String,
  $title: String,
  $isActive: Boolean
  $type: String
) {
  surveys(
    offset: $offset,
    before: $before,
    after: $after,
    first: $first,
    last: $last,
    id: $id,
    remoteId: $remoteId,
    title: $title,
    isActive: $isActive
    type: $type
  ) {
    edges {
      node {
        id
        isActive
        internalName
      }
    }
  }
}
`
export const SURVEY_CODES = gql`
query surveyCodes(
  $survey: GlobalID,
) {
  surveyCodes(
    survey:$survey
  ) {
    edges {
      node {
        id
        code
        location {
          name
        }
        survey {
          internalName
        }
      }
    }
  }
}
`

export const SURVEY = gql`
query survey($id: GlobalID) {
  survey(id: $id) {
    id
    definition
    isAllowIncomplete
    description
    isActive
    internalName
    remoteId
    title
    theme
  }
}
`
export const SURVEY_EDIT = gql`
mutation surveyEdit($input: SurveyEditInput!) {
  surveyEdit(input: $input) {
    created {
      id
    }
    modified {
      id
      data
    }
    errors {
      message
      code
    }
    success
  }
}
`

export const SURVEY_DEACTIVATE = gql`mutation surveyDeactivate($input: SurveyDeactivateInput!) {
  surveyDeactivate(input: $input) {
      modified {
          id
          data
      }
      errors {
          code
      }
      success
  }
}
`

export const POST_SURVEY_ACTION_ADD = gql`
mutation surveyPostActionCreate($input: SurveyPostActionCreateInput!) {
  surveyPostActionCreate(input: $input) {
    created {
      id
    }
    modified {
      id
    }
    errors {
      message
      code
    }
    success
  }
}
`
export const POST_SURVEY_ACTION_REMOVE = gql`
mutation surveyPostActionRemove($input: SurveyPostActionRemoveInput!) {
  surveyPostActionRemove(input: $input) {
    deleted {
      id
    }
    modified {
      id
    }
    errors {
      message
      code
    }
    success
  }
}
`
export const POST_SURVEY_ACTIONS = gql`
query surveyPostActions(
  $survey: GlobalID,
) {
  surveyPostActions(
    survey: $survey,
  ) {
    edges {
      node {
        id
        rule
        postAction
        attributes
      }
    }
  }
}
`
