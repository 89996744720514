import { Conditions, KeyIcon, ToolTip } from '@reply-pro/component-library'
import { useRef, useLayoutEffect, useState } from 'react'
import { user } from 'types/user'
import UserToggleActive from './UserToggleActive'

type NodeUser = {
  node: user
}

interface UserActionsProps {
  handlePasswordManager: (user: user) => void
  handleUserPermissions: (user: user) => void
  user: NodeUser
  viewingActive: boolean
}

function UserActions ({ handlePasswordManager, handleUserPermissions, user, viewingActive }: UserActionsProps) {
  const keyRef = useRef<HTMLDivElement | null>(null)
  const permissionsRef = useRef<HTMLDivElement | null>(null)
  const [keyToolTipKey, setKeyToolTipKey] = useState(0)

  useLayoutEffect(() => {
    if (keyRef.current) {
      keyRef.current.setAttribute('data-keyref', 'true')
      setKeyToolTipKey((prevKey) => prevKey + 1)
    }
    if (permissionsRef.current) {
      permissionsRef.current.setAttribute('data-permissionsref', 'true')
    }
  }, [keyRef, permissionsRef])

  return (
    <div className='user-actions'>
      <div className={`user-key${!viewingActive ? ' disabled' : ''}`} ref={keyRef}>
        <KeyIcon onClick={() => handlePasswordManager(user.node)} className='user-password' />
      </div>
      <div className={`user-permissions${!viewingActive ? ' disabled' : ''}`} ref={permissionsRef}>
        <Conditions onClick={() => handleUserPermissions(user.node)} />
      </div>
      {keyRef.current && keyRef.current.getAttribute('data-keyref') === 'true' && (
        <ToolTip key={keyToolTipKey} anchor={keyRef}>
          Manage User Password
        </ToolTip>
      )}
      {permissionsRef.current && permissionsRef.current.getAttribute('data-permissionsref') === 'true' && (
        <ToolTip anchor={permissionsRef}>
          Check User Permissions
        </ToolTip>
      )}
      <UserToggleActive user={user.node} />
    </div>
  )
}

export default UserActions
