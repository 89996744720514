import { LoadingSpinner, RadioSelectorWithChildren, TextInput } from '@reply-pro/component-library'
import { useGetActivityWorkflows } from './hooks'
import { ActivityWorkflowEdge } from './WorkflowSelector.types'
import UserSelector from 'components/UserSelector'
interface WorkflowSelectorProps {
  onAttributeChange: (attribute: string, value: any) => void
  attributes: { [key: string]: any }
}

export default function WorkflowSelector ({ onAttributeChange, attributes }: WorkflowSelectorProps) {
  const { workflows, loading } = useGetActivityWorkflows(onAttributeChange)

  const handleSelectUser = ({ target }: { target: { value: string } }) => {
    onAttributeChange('assigned_to', target.value)
    if (!attributes.user) {
      onAttributeChange('user', target.value)
    }
  }

  return (
    <div className='workflow-selector-container'>
      {loading
        ? <div className='loading-workflows'> <LoadingSpinner size='small' /></div>
        : workflows?.activityWorkflows?.edges.map((edge: ActivityWorkflowEdge) => (
          <RadioSelectorWithChildren
            key={edge.node.id}
            label={edge.node.title}
            onClick={() => onAttributeChange('workflow', edge.node.uuid)}
            isSelected={attributes.workflow === edge.node.uuid}
          >
            <div className='workflow-details'>
              <div className='days-selector-container'>
                <TextInput
                  className='days-selector-input'
                  type='number'
                  placeholder='2'
                  value={attributes.due}
                  onChange={(e) => onAttributeChange('due', e.target.value)}
                />
                <p>Days until Due Date after submission</p>
              </div>
              <div className='user-selector-container'>
                <UserSelector
                  className='activity-user-selector'
                  user={attributes?.assigned_to || ''}
                  handleSelectUser={handleSelectUser}
                  placeholder='Assign To'
                />
              </div>
            </div>
          </RadioSelectorWithChildren>
        ))}
    </div>
  )
}
