import { isStaging } from 'utils/common/envDetection'
import { Webhooks } from './Webhooks.types'

export const data: Webhooks = [
  {
    account_id: isStaging() ? 'QWNjb3VudDo4NzE3YTRlYy1hODVlLTQ5MWEtOWRiNS0xYmUxYzJiNTMyZjE=' : 'QWNjb3VudDo2ZDc5N2Q0Mi1kNGI1LTQ3ZjgtYmY0ZC1kZmNmNWEwYTVjYjY=',
    endpoints: [
      {
        id: 'a',
        name: 'Discrict Alignment Process',
        description: 'This endpoint is for processing the Habit District Alignment Sheet.',
        path: '/habit/parse-district-alignment-sheet',
        method: 'POST',
        input: [
          {
            name: 'file',
            title: 'District Alignment Sheet',
            description: 'The Excel file for the District Alignment Sheet for The Habit.',
            type: 'file',
            required: true,
            formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
            maxSizeInBytes: 10485760
          }
        ]
      }
    ]
  },
  {
    account_id: isStaging() ? 'QWNjb3VudDo2ZDE5M2YyZC1iYmVmLTRmYmUtYWI5NS00MzBkNTkwYTY1MGQ=' : 'QWNjb3VudDowMjE4MTg0YS1jYmI5LTRmYTctYjdlNS02NGQ5NzMzZjJhMTk=',
    endpoints: [
      {
        id: 'b',
        name: 'Pizza Hut Alignment File Process',
        description: 'This endpoint is for processing the Pizza Hut Alignment File.',
        path: '/pizza_hut/parse-locations-csv',
        queryParams: { key: process.env.REACT_APP_PIZZA_HUT_PARSE_LOCATION_CSV_ENDPOINT_KEY ?? '' },
        method: 'POST',
        input: [
          {
            name: 'file',
            title: 'Alignment Sheet',
            description: 'The Excel file for the Alignment File for Pizza Hut.',
            type: 'file',
            required: true,
            formats: ['text/csv'],
            maxSizeInBytes: 10485760
          }
        ]
      }
    ]
  },
  {
    account_id: isStaging() ? 'QWNjb3VudDphOWZlYTYxZC1kZmY0LTQ1ZjAtYTk3ZC1mMzI2ODIyOGMyOTA=' : 'QWNjb3VudDozZGI2Zjg5YS00ZGMxLTQwNGQtYjg0Zi04MjMwZmQ5NTYzOTc=',
    endpoints: [
      {
        id: 'c',
        name: 'Clarks Alignment File Process',
        description: 'This endpoint is for processing the Clarks Alignment File.',
        path: '/clarks/process-alignment-sheet',
        method: 'POST',
        input: [
          {
            name: 'file',
            title: 'Alignment Sheet',
            description: 'The Excel file for the Alignment File for Clarks.',
            type: 'file',
            required: true,
            formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
            maxSizeInBytes: 10485760
          }
        ]
      }
    ]
  }
]
