import { RefObject, ReactNode } from 'react'
import { ToolTip } from '@reply-pro/component-library'

interface TooltipProps {
  anchor: RefObject<HTMLElement>
  children: ReactNode
}

export default function Tooltip ({ anchor, children }: TooltipProps) {
  return (
    <ToolTip
      position='top'
      paddingBetweenAnchorAndTooltip={15}
      wrapperSize={200}
      anchor={anchor}
      delay={0}
      shown={false}
    >
      {children}
    </ToolTip>
  )
}
