import { SearchEmptyState } from '@reply-pro/component-library'
import './noResultsCaption.scss'

const NoResultsCaption = ({ className }: { className?: string }) => {
  return (
    <div
      className={`search-empty-state${className ? ' ' + className : ''}`}
      style={{ textAlign: 'center' }}
    >
      <SearchEmptyState className='empty-state-svg' />
      <span>Sorry, we couldn't find any results...</span>
    </div>
  )
}

export default NoResultsCaption
