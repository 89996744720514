import { gql } from '@apollo/client'

export const LOCATIONS = gql`
  query locations($first: Int, $search: String, $after: String) {
    locations(first: $first, search: $search, after: $after) {
      edges {
        node {
          id
          name
          uuid
        }
      }
    }
  }
`

export const LOCATION_INPUTS = gql`
  mutation locationAdd($input: LocationAddInput!) {
    locationAdd(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`
export const TOGGLE_NOTIFICATIONS = gql`
  mutation accountToggleNotifications($input: AccountToggleNotificationsInput!) {
    accountToggleNotifications(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const LOCATION_TAGS_AND_GROUPS = gql`
  query locations($name: String, $first: Int, $search: String, $after: String, $before: String, $offset: Int) {
    locations(first: $first, name: $name, search: $search, after: $after, before: $before, offset: $offset) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          remoteId
          streetAddress
          locality
          province
          country
          timeZone
          tags {
            id
            tagField {
              id
              uuid
              label
              group {
                id
                label
                description
              }
              choices
            }
            value
          }
          account {
            name
          }
        }
      }
    }
  }
`

export const HIERARCHY = gql`
  query {
    hierarchy {
      levels {
        edges {
          node {
            id
            name
            subLevel {
              id
            }
            groups {
              edges {
                node {
                  id
                  name
                  parent {
                    id
                  }
                  locations {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const LOCATION_GROUPS = gql`
  query locationGroups($first: Int, $search: String, $after: String) {
    locationGroups(first: $first, search: $search, after: $after) {
      edges {
        node {
          id
          name
          label {
            id
            name
          }
        }
      }
    }
  }
`

export const LOCATION_GROUPS_BY_USER = gql`
  query locationGroups($first: Int, $search: String, $after: String, $user: GlobalID!) {
    locationGroups(first: $first, search: $search, after: $after, user: $user) {
      edges {
        node {
          id
          name
          label {
            id
            name
          }
        }
      }
    }
  }
`

export const LOCATION_GROUP = gql`
  query locationGroup($id: GlobalID!) {
    locationGroup(id: $id) {
      id
      name
      label {
        id
        name
      }
      users {
        edges {
          node {
            id
            email
            firstName
            lastName
          }
        }
      }
      locations {
        edges {
          node {
            id
            name
            streetAddress
            locality
            province
            postalCode
          }
        }
      }
      parents {
        edges {
          node {
            id
            name
          }
        }
      }
      children {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const LOCATION_GROUP_EXPANDED = gql`
  query locationGroup($id: GlobalID!) {
    locationGroup(id: $id) {
      id
      locations {
        edges {
          node {
            id
            name
            streetAddress
            locality
            province
            postalCode
          }
        }
      }
      children {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const HIERARCHY_GROUPS = gql`
  query hierarchyGroups($search: String, $first: Int) {
    hierarchyGroups(search: $search, first: $first) {
      edges {
        node {
          id
          isTop
          name
          label {
            id
            name
          }
        }
      }
    }
  }
`

export const HIERARCHY_GROUPS_BY_USER = gql`
  query hierarchyGroups($search: String, $first: Int, $user: GlobalID!) {
    hierarchyGroups(search: $search, first: $first, user: $user) {
      edges {
        node {
          id
          isTop
          name
          label {
            id
            name
          }
        }
      }
    }
  }
`

export const HIERARCHY_GROUP = gql`
  query hierarchyGroup($id: GlobalID) {
    hierarchyGroup(id: $id) {
      id
      isTop
      name
      remoteId
      label {
        id
        name
      }
      users {
        edges {
          node {
            id
            email
            firstName
            lastName
          }
        }
      }
      children {
        edges {
          node {
            id
            name
          }
        }
      }
      locations {
        edges {
          node {
            id
            name
            streetAddress
            locality
            province
            postalCode
          }
        }
      }
    }
  }
`

export const HIERARCHY_GROUP_EXPAND = gql`
  query hierarchyGroup($id: GlobalID) {
    hierarchyGroup(id: $id) {
      id
      name
      children {
        edges {
          node {
            id
            name
          }
        }
      }
      locations {
        edges {
          node {
            id
            name
            streetAddress
            locality
            province
            postalCode
          }
        }
      }
    }
  }
`
