import { ToggleInput } from '@reply-pro/component-library'
import { user } from 'types/user'
import { useUserToggleActive } from './hooks'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'

interface UserToggleActiveProps {
    user: user
}

function UserToggleActive ({ user }: UserToggleActiveProps) {
  const { handleToggle, isConfirmationModalOpen, toggleConfirmationModalOpen, handleConfirmToggle, loading } = useUserToggleActive(user.id)
  return (
    <>
      <ToggleInput checked={user.allowLogin} disabled={loading} onChange={user.allowLogin ? toggleConfirmationModalOpen : handleToggle} />
      {isConfirmationModalOpen &&
        <ConfirmationModal
          showModal={isConfirmationModalOpen}
          toggleModalOpen={toggleConfirmationModalOpen}
          warningFirstLine={`You are about to deactivate ${user.firstName}, this user will no longer be able to access its account.`}
          confirmText='DEACTIVATE'
          buttonText='DEACTIVATE'
          onConfirm={handleConfirmToggle}
          modalTitle='DEACTIVATE USER'
        />}
    </>
  )
}

export default UserToggleActive
