export const CHANNEL_TYPES = {
  EMAIL: 'email',
  SMS: 'sms',
  REVIEW: 'review',
  REVIEW_RESPONSE: 'review_response'
}

export const MAINTENANCE_STATUSES = {
  SCHEDULED: 'scheduled',
  ONGOING: 'ongoing',
  COMPLETED: 'completed'
  // ANNOUNCEMENT: 'announcement' // We'll need to prepare RP for HTML in order to make announcements as they can be more complex.
}

export const maintenanceStatusesArray = Object.values(MAINTENANCE_STATUSES)
