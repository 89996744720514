import { LazyQueryExecFunction, OperationVariables, useMutation } from '@apollo/client'
import { useState } from 'react'
import { getRequest, getEmailPayload } from '../../getRequest'
import { parseWhiteLabelEmailData } from 'utils/whiteLabel/parseWhiteLabelData'
import { WhiteLabel } from 'types/account'

interface useAddEmailBrandingAttributeProps {
  initialValue?: string
  emailBrandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData>
  getWhiteLabelData: LazyQueryExecFunction<WhiteLabel, OperationVariables>
}

export const useAddEmailBrandingAttribute = ({ initialValue, emailBrandingKey, getWhiteLabelData }: useAddEmailBrandingAttributeProps) => {
  const [value, setValue] = useState(initialValue ?? '')

  const [addEmailBrandingAttribute, { data, loading, error }] = useMutation(getRequest(emailBrandingKey))

  const handleSave = async () => {
    await addEmailBrandingAttribute({ variables: { input: getEmailPayload(emailBrandingKey, value) } })
    getWhiteLabelData()
  }

  return { value, setValue, data, loading, error, handleSave }
}
