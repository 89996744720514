import { HierarchyGroup, LocationGroup } from 'types/location'

export const findAndAppendChild = (node: HierarchyGroup | LocationGroup | undefined, targetId: string, locationGroupExpanded: any): HierarchyGroup | LocationGroup | undefined => {
  if (node?.id === targetId && !node?.children) {
    return { ...node, children: locationGroupExpanded.children, locations: locationGroupExpanded.locations }
  } else if (node?.id !== targetId && !node?.children) {
    return { id: node?.id || '', name: node?.name || '' } as any
  }

  if (node?.children && node.children.edges) {
    const updatedChildren = node.children.edges.map((child) => {
      return {
        node: findAndAppendChild(child.node as any, targetId, locationGroupExpanded)
      }
    })
    return {
      ...node,
      children: {
        edges: updatedChildren as any
      }
    }
  }
}
