import { Button, FileUploadIcon, MainModal, RoundCheckIcon } from '@reply-pro/component-library'
import './ManageSurveyThemeModal.scss'
import { useSurveyThemeManager } from './hooks'

interface ManageSurveyThemeProps {
  id: string
  showModal: boolean
  toggleModalOpen: () => void
}

function ManageSurveyThemeModal ({ id, showModal, toggleModalOpen }: ManageSurveyThemeProps) {
  const { fileName, jsonContent, handleFileChange, handleChangeTheme, loading } = useSurveyThemeManager(id)

  return (
    <MainModal
      showModal={showModal}
      toggleModalOpen={toggleModalOpen}
      className='theme-upload-modal'
      headerClassName='theme-upload-modal'
      title='Use a custom Theme'
      actions={<Button disabled={loading || !jsonContent} onClick={handleChangeTheme}>Change Theme</Button>}
      showTopAndBottomLines
    >
      <div className='file-and-instructions-container'>
        <div className='instructions-container'>
          <h4>Uploading a Custom Theme</h4>
          <span>- After preparing your Custom Theme, download it from the Themes tab.</span>
          <span>- Using the JSON file you got, upload it to replace the current Theme.</span>
          <span>- Your new Theme MUST include the branding color already set.</span>
          <span className='warning'>- The page will reload, make sure to save all changes before!</span>
        </div>
        <div className='theme-manager-container'>
          <label className={`theme-file-selector ${jsonContent ? 'success' : ''}`}>
            <input type='file' onChange={handleFileChange} accept='.json' />
            <span> {jsonContent ? <RoundCheckIcon /> : <FileUploadIcon />} {fileName || 'Select a JSON file'}</span>
          </label>
        </div>
      </div>
    </MainModal>
  )
}

export default ManageSurveyThemeModal
