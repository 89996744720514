import { ChangeEvent, useState } from 'react'

export const useConfirmationModal = (confirmText: string) => {
  const [textMatch, setTextMatch] = useState(false)

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value.length > 0 && e.target.value === confirmText) {
      setTextMatch(true)
    } else {
      setTextMatch(false)
    }
  }

  return { handleTextChange, textMatch }
}
