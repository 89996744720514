import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { setSurveyLocalQuestionsData } from 'store/surveysSlice'

export const useSurveyCreator = () => {
  // const { locationTracking } = useSelector((state: RootState) => state.surveys.surveySetup.data)
// This will help to include the initial Location question if required
  const dispatch: AppDispatch = useDispatch()
  const saveLocalChanges = (changes: object) => {
    dispatch(setSurveyLocalQuestionsData(changes))
  }

  return { saveLocalChanges }
}
