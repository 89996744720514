import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { retrieveSurveyList } from 'store/surveysSlice/surveysDispatchers'
import { clearSurveySetup } from 'store/surveysSlice'
import { useDispatchAction } from 'utils/common/useDispatchAction'
import { useNavigate } from 'react-router-dom'
import SurveyStatus from '../constants'

interface useSurveysListProps {
  status: SurveyStatus[keyof SurveyStatus]
  type: string
}

const { ENABLED } = SurveyStatus

export const useSurveysList = ({ status, type }: useSurveysListProps) => {
  const { loading, data } = useSelector((state: RootState) => state.surveys.surveyList)
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    dispatch(retrieveSurveyList({ type, isActive: status === ENABLED, refetch: true }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useDispatchAction(clearSurveySetup)

  return { loading, data }
}

export const useSurveyActions = ({ id, surveyType }: { id: string, surveyType: string}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

  const toggleModalOpen = () => {
    setIsModalOpen(prev => !prev)
  }

  const handleEditSurvey = () => {
    navigate(`/settings/manage-${surveyType}s/${surveyType}-setup/` + id)
  }

  return { isModalOpen, toggleModalOpen, handleEditSurvey }
}
