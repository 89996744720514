export const getBiggestOccurrence = (object: any, key: string) =>
  Object.keys(object).reduce((accumulator: number, item: any) => (object[item][key] > accumulator ? object[item][key] : accumulator), -1)
export const turnObjectIntoArrayOfObjects = (objectToTransform: object) =>
  Object.entries(objectToTransform).map((element: any) => element && Object.assign({ name: element[0] }, element[1]))
export const isObjectEmpty = (object: object) => {
  return object ? Object?.entries(object)?.length === 0 : {}
}

export const getValueNameIfValueIsObject = (valueObj?: string) => {
  let newValue
  if (valueObj) {
    try {
      newValue = JSON.parse(valueObj)
    } catch {}
  }
  if (typeof newValue === 'object' && newValue.name) {
    return newValue.name
  }

  return valueObj
}
