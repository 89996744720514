import { gql } from '@apollo/client'

export const DUMB_QUERY = gql`
  query file {
    file
  }
`

export const FILES = (files: string[]) => {
  const queries = files.reduce(
    (accumulator, currentValue, index) =>
      accumulator +
      `file${index + 1}: file(id: "${currentValue}") {
          id
          uuid
          name
          original {
            publicUrl
          }
        } `,
    ''
  )
  return gql`query files {
    ${queries}
  }`
}
export const FILE = gql`
  query ($id: GlobalID!) {
    file(id: $id) {
      id
      uuid
      name
      original {
        publicUrl
      }
    }
  }
`
