import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { deactivateSurvey } from 'store/surveysSlice/surveysDispatchers'

export const useSurveyDeactivate = (id: string) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const loading = useSelector((state: RootState) => state.surveys.deactivateSurvey.loading)

  const dispatch: AppDispatch = useDispatch()

  const handleToggle = () => {
    dispatch(deactivateSurvey(id))
  }

  const toggleConfirmationModalOpen = () => {
    setIsConfirmationModalOpen(prev => !prev)
  }

  const handleConfirmToggle = () => {
    handleToggle()
    toggleConfirmationModalOpen()
  }

  return { isConfirmationModalOpen, toggleConfirmationModalOpen, handleConfirmToggle, loading }
}
