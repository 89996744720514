import TagGroupType from 'types/tagGroup'
// import { RootState } from 'store'
import { tagField, tagFieldExtractedAndIndexedType } from 'types/tagField'
// import { useSelector } from 'react-redux'

const turnTagFieldIntoIndexedObject = (index: string, tagField: tagFieldExtractedAndIndexedType) => {
  return { [index]: tagField }
}

const extractAndIndexTagFieldsFromTagGroup = (tagGroup: TagGroupType) => {
  const indexedTagFields = { indexedByUuid: {}, indexedById: {} }

  tagGroup.tagFields.forEach((tagField: tagField) => {
    const tagFieldWithTagGroupInfo = {
      tagGroupId: tagGroup.id,
      tagGroupLabel: tagGroup.label,
      tagGroupDescription: tagGroup.description,
      tagGroupEntities: tagGroup.taggableEntities,
      tagGroupOrder: tagGroup.order,
      ...tagField
    }
    const tagFieldIndexedById = turnTagFieldIntoIndexedObject(tagField.id, tagFieldWithTagGroupInfo)
    const tagFieldIndexedByUuid = turnTagFieldIntoIndexedObject(tagField.uuid, tagFieldWithTagGroupInfo)
    indexedTagFields.indexedByUuid = {
      ...tagFieldIndexedByUuid,
      ...indexedTagFields.indexedByUuid
    }
    indexedTagFields.indexedById = {
      ...tagFieldIndexedById,
      ...indexedTagFields.indexedById
    }
  })
  return indexedTagFields
}

export const createIndexedTagFieldsList = (tagGroups: { node: TagGroupType }[]) => {
  const indexedTagFields = tagGroups.reduce(
    (acc, { node }: { node: TagGroupType }) => {
      const tagFields = extractAndIndexTagFieldsFromTagGroup(node)
      return {
        indexedById: { ...acc.indexedById, ...tagFields.indexedById },
        indexedByUuid: { ...acc.indexedByUuid, ...tagFields.indexedByUuid }
      }
    },
    { indexedById: {}, indexedByUuid: {} }
  )
  return indexedTagFields
}

export const useGetTagFieldByUuid = () => {
  // const { indexedByUuid } = useSelector((store: RootState) => store.tags.tagFields)
  const indexedByUuid = '' as any
  const getTagFieldByUuid = (uuid: string) => {
    return indexedByUuid[uuid]
  }
  return getTagFieldByUuid
}

export const useGetTagFieldById = () => {
  // const { indexedById } = useSelector((store: RootState) => store.tags.tagFields)
  const indexedById = '' as any
  const getTagFieldById = (id: string) => {
    return indexedById[id]
  }
  return getTagFieldById
}

export interface TagField {
  tagFieldName: string
  tagFieldId: string
  tagFieldUuid: string
  choices: { value: string; display_name: string }[]
  tagGroup: { name: string; id: string }
  type: string
}

export const getTagFields = (tagGroups: { node: TagGroupType }[]) => {
  return (
    tagGroups &&
    tagGroups.reduce((tagGroupAcc: TagField[], { node }: { node: TagGroupType }) => {
      return [
        ...tagGroupAcc,
        ...node.tagFields.reduce(
          (tagFieldAcc: TagField[], tagField) => [
            ...tagFieldAcc,
            {
              tagFieldName: tagField.label,
              tagFieldId: tagField.id,
              tagFieldUuid: tagField.uuid,
              choices: tagField.choices,
              type: tagField.type ?? '',
              tagGroup: {
                name: node.description,
                id: node.id
              }
            }
          ],
          []
        )
      ]
    }, [])
  )
}
