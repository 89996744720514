import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation tokenPairObtain($input: ObtainTokenPairInput!) {
    tokenPairObtain(input: $input) {
      access
      refresh
    }
  }
`

export const REFRESH = gql`
  mutation tokenRefresh($input: RefreshTokenInput!) {
    tokenRefresh(input: $input) {
      access
    }
  }
`
export const ME = gql`
  query {
  me {
    id
    uuid
    email
    isEmployee
    timeZone
  }
}
`
