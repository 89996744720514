import { Button, EyeIcon, LoadingSpinner, NavItem, SearchInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_USER_GROUPS_FEATURES, USER_GROUP_SET_FEATURES } from 'requests/Users'
import { userGroupsResponse } from 'store/accountsSlice/users/types.users'
import FeaturesSetter from 'components/FeaturesSetter'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import './ManageUserGroup.scss'
import { debounce } from 'lodash'

const ManageUserGroupsFeatures = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button stroked className='manage-user-groups-button' onClick={() => setShowModal(true)}><EyeIcon />Manage Features</Button>
      {showModal && <ManageUserGroupsModal showModal={showModal} setShowModal={setShowModal} />}
    </>
  )
}

interface ManageUserGroupsModalProps {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ManageUserGroupsModal = ({ showModal, setShowModal }: ManageUserGroupsModalProps) => {
  const { accountId, accounts } = useSelector((state: RootState) => state.accounts)
  const currentAccount = useMemo(() => accounts.data?.accounts.edges.find(({ node }) => node.id === accountId), [accountId, accounts.data?.accounts.edges])

  const [search, setSearch] = useState('')

  const { data, loading, refetch } = useQuery<userGroupsResponse>(GET_USER_GROUPS_FEATURES, { fetchPolicy: 'no-cache', variables: { sortByFeatures: true } })
  const [setUserGroupFeatures] = useMutation(USER_GROUP_SET_FEATURES)

  const handleSearch = (value: string) => {
    setSearch(value)
    handleSearchAction(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchAction = useCallback(debounce((value: string) => {
    refetch({ search: value })
  }, 450), [])

  return (
    <Modal
      showModal={showModal}
      title='Manage User Groups Features'
      actions={<Button onClick={() => setShowModal(false)}>Done</Button>}
      toggleModalOpen={() => setShowModal(false)}
      className='manage-user-groups-modal'
    >
      <div className='manage-user-groups-modal-body'>
        <div className='manage-user-groups-search-bar-container'>
          <SearchInput
            className='manage-user-groups-search-bar'
            placeholder='Search User Groups'
            onChange={(e) => handleSearch(e.target.value)}
            value={search}
            clearInput={() => handleSearch('')}
          />
        </div>

        <div className='manage-user-groups-user-groups-container'>
          {
            loading
              ? <div className='loading-spinner'><LoadingSpinner size='small' /></div>
              : data?.userGroups.edges.map(({ node }) =>
                <NavItem key={node.id} className='manage-user-groups-user-group-nav-item'>
                  <div className='manage-user-groups-user-group'>
                    <div className='manage-user-groups-user-group-information'>
                      <div className='manage-user-groups-user-group-name'>{node.name}</div>
                      {node.description && <div className='manage-user-groups-user-group-description'>{node.description}</div>}
                    </div>

                    <FeaturesSetter
                      entity='User Group'
                      features={node.features}
                      setFeaturesAction={(features) => setUserGroupFeatures({ variables: { input: { userGroup: node.id, features } } })}
                      accountFeatures={currentAccount?.node.features}
                      reportingWarning={node.hasReportPage}
                    />
                  </div>
                </NavItem>)
            }
        </div>
      </div>
    </Modal>
  )
}

export default ManageUserGroupsFeatures
