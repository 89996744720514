import { createAsyncThunk } from '@reduxjs/toolkit'
import { apollo } from 'api'
import { TOGGLE_USER_ACTIVE, USERS } from 'requests/Settings'
import { userGroupUsersResponse, userGroupsResponse, usersResponse } from './types.users'
import { GET_USER_GROUPS, USER_GROUP_ADD_GROUP, USER_GROUP_ADD_USERS, USER_GROUP_EDIT_GROUP, USER_GROUP_REMOVE_GROUP, USER_GROUP_REMOVE_USERS, USER_GROUP_USERS } from 'requests/Users'

export const retrieveUsers = createAsyncThunk<usersResponse, { queryParam: string, viewingActive: boolean, all?: boolean }>(
  'accounts/retrieveUsers',
  async ({ queryParam, viewingActive, all }, thunkApi) => {
    const response = await apollo.query({
      query: USERS,
      variables: {
        first: 80,
        search: queryParam,
        allowLogin: viewingActive ? 'allow' : 'disallow',
        all
      }
    })

    return response.data
  }
)

export const toggleUserActive = createAsyncThunk<string, string>(
  'accounts/toggleUserActive',
  async (userId: string, _) => {
    await apollo.mutate({
      mutation: TOGGLE_USER_ACTIVE,
      variables: {
        input: {
          id: userId
        }
      }
    })

    return userId
  }
)

export const getUserGroups = createAsyncThunk<userGroupsResponse, { search?: string }>(
  'accounts/getUserGroups',
  async ({ search }) => {
    const response = await apollo.query({
      query: GET_USER_GROUPS,
      variables: {
        search
      }
    })

    return response.data
  }
)

export const getUserGroupUsers = createAsyncThunk<userGroupUsersResponse, { userGroupId: string, search?: string }>(
  'accounts/userGroupUsers',
  async ({ userGroupId, search }) => {
    const response = await apollo.query({
      query: USER_GROUP_USERS,
      variables: {
        userGroup: userGroupId,
        search
      }
    })

    return response.data
  }
)

export const addUserGroup = createAsyncThunk<string, { name: string, description?: string }>(
  'accounts/addUserGroup',
  async (userGroupData) => {
    const response = await apollo.mutate({
      mutation: USER_GROUP_ADD_GROUP,
      variables: {
        input: userGroupData
      }
    })

    return response.data.userGroupAddNew.created.id
  }
)

export const editUserGroup = createAsyncThunk<string, { name: string, description?: string }>(
  'accounts/editUserGroup',
  async (userGroupData) => {
    const response = await apollo.mutate({
      mutation: USER_GROUP_EDIT_GROUP,
      variables: {
        input: userGroupData
      }
    })

    return response.data.userGroupEdit.modified.id
  }
)

export const removeUserGroup = createAsyncThunk<string, string>(
  'accounts/removeUserGroup',
  async (userGroupId) => {
    const response = await apollo.mutate({
      mutation: USER_GROUP_REMOVE_GROUP,
      variables: {
        input: {
          userGroup: userGroupId
        }
      }
    })

    return response.data.userGroupRemove.deleted.id
  }
)

export const addUsersToUserGroup = createAsyncThunk<Record<string, string[]>, { userGroupId: string, users: string[] }>(
  'accounts/addUsersToUserGroup',
  async ({ userGroupId, users }) => {
    const response = await apollo.mutate({
      mutation: USER_GROUP_ADD_USERS,
      variables: {
        input: {
          userGroup: userGroupId,
          users
        }
      }
    })

    return response.data.userGroupAddUsers.modified.data
  }
)

export const removeUsersFromUserGroup = createAsyncThunk<Record<string, string[]>, { userGroupId: string, users: string[] }>(
  'accounts/removeUsersFromUserGroup',
  async ({ userGroupId, users }) => {
    const response = await apollo.mutate({
      mutation: USER_GROUP_REMOVE_USERS,
      variables: {
        input: {
          userGroup: userGroupId,
          users
        }
      }
    })

    return response.data.userGroupRemoveUsers.deleted.data
  }
)
