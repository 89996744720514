import { Button, TextInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import { ChangeEvent, useState } from 'react'
import { user } from 'types/user'
import { validatePassword } from './functions'
import { usePasswordSetup } from './hooks'

interface ChangePasswordModalProps {
    isChangePasswordModalOpen: boolean,
    setIsChangePasswordModalOpen: (bool: boolean) => void,
    editingUser?: user
}

function ChangePasswordModal ({ isChangePasswordModalOpen, setIsChangePasswordModalOpen, editingUser }: ChangePasswordModalProps) {
  const [password, setPassword] = useState('')
  const [passwordFormat, setPasswordFormat] = useState('')

  const { changeUserPassword, loading } = usePasswordSetup({ setModalOpen: setIsChangePasswordModalOpen })

  const handleTypingPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    const validation = validatePassword(e.target.value)
    setPasswordFormat(validation)
  }

  const handleChangePasswordClick = () => {
    changeUserPassword({ email: editingUser?.email, password })
  }

  return (
    <>
      <Modal
        showModal={isChangePasswordModalOpen}
        toggleModalOpen={() => setIsChangePasswordModalOpen(false)}
        title='Manage user password'
        actions={<Button disabled={password === '' || passwordFormat !== 'success' || loading} backgroundColor='red2' onClick={handleChangePasswordClick}>Set password</Button>}
      >
        <div className='user-manager-modal-body'>
          <span>Enter a new password for {editingUser?.firstName || editingUser?.email}?</span>
          {passwordFormat !== 'success' && <span className='format-warning'>{passwordFormat}</span>}
          <TextInput
            className='reset-password-input'
            type='password'
            placeholder='New Password'
            name='password'
            autoComplete='new-password'
            value={password}
            onChange={handleTypingPassword}
            disabled={loading}
          />
        </div>
      </Modal>
    </>
  )
}

export default ChangePasswordModal
