import { RootState } from '..'
import { apollo } from 'api'
import { LOGIN, REFRESH } from 'requests/Login'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoginPayload, LoginResult, RefreshResult, LoginMutationResult, RefreshMutationResult, LoginState } from './loginTypes'

export const login = createAsyncThunk<LoginResult, LoginPayload>(
  'login/doLogin',
  async (payload: LoginPayload, thunkApi) => {
    thunkApi.dispatch(loginSlice.actions.loading())

    const data = await apollo.mutate<LoginMutationResult>({
      mutation: LOGIN,
      variables: {
        input: payload
      }
    })

    if (data.data?.tokenPairObtain?.access) {
      const { access, refresh } = data.data?.tokenPairObtain

      return { access, refresh }
    }

    throw new Error('Invalid login credentials')
  }
)

export const refresh = createAsyncThunk<RefreshResult>(
  'login/refresh',
  async (_, thunkApi) => {
    thunkApi.dispatch(loginSlice.actions.loading())

    const currentState = thunkApi.getState() as RootState
    const data = await apollo.mutate<RefreshMutationResult>({
      mutation: REFRESH,
      variables: {
        input: {
          refresh: currentState.login.refresh
        }
      }
    })

    if (data.data?.tokenRefresh?.access) {
      const { access } = data.data?.tokenRefresh

      return { access }
    }

    throw new Error('Invalid login credentials')
  }
)

const initialState: LoginState = {
  authenticated: false,
  loading: false,
  access: '',
  refresh: ''
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true
    },
    logout: (state) => {
      state.access = ''
      state.refresh = ''
      state.authenticated = false
      state.loading = false
      delete state.error
    },
    notifyError: (state, action) => {
      state.error = {
        name: action.payload,
        message: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { access, refresh } = action.payload
      state.access = access
      state.refresh = refresh
      state.authenticated = true
      state.loading = false
    })

    builder.addCase(login.rejected, (state, action) => {
      state.access = ''
      state.refresh = ''
      state.error = action.error
      state.authenticated = false
      state.loading = false
    })

    builder.addCase(refresh.fulfilled, (state, action) => {
      const { access } = action.payload
      state.access = access
      state.authenticated = true
      state.loading = false
    })

    builder.addCase(refresh.rejected, (state, action) => {
      state.access = ''
      state.refresh = ''
      state.authenticated = false
      state.loading = false
      delete state.error
    })
  }
})

export const { logout, notifyError } = loginSlice.actions

export default loginSlice.reducer
