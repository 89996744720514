import { ChangeEvent, useState } from 'react'
import { newAccountInitialState } from './ManageAccountsModal/AddNewAccountModal/utils'
import { eventTargetStringValue } from '@reply-pro/component-library/dist/components/SettingsStructureRightFilters/SettingsStructureRightFilters.types'

export const useHandleAddAccountInput = () => {
  const [formData, setFormData] = useState(newAccountInitialState)
  const [isAddNewAccountModalOpen, setIsAddNewAccountModalOpen] = useState(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string) => {
    setFormData((prevAttributes) => ({
      ...prevAttributes,
      [`${attribute}`]: e?.target?.value
    }))
  }

  return { formData, handleInputChange, setFormData, isAddNewAccountModalOpen, setIsAddNewAccountModalOpen }
}

export const useHandleSelectInput = () => {
  const [timeZoneOptions, setTimeZoneOptions] = useState<string[]>()

  const handleSearch = (e: eventTargetStringValue, options: string[]) => {
    const filtered = options.filter(
      (value) =>
        value.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setTimeZoneOptions(filtered)
  }

  return { handleSearch, timeZoneOptions }
}
