import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { AppDispatch, RootState } from 'store'

export const waitForAccounts = (thunkApi: BaseThunkAPI<RootState, null, AppDispatch>, timeout = 1000, retry = 1) => new Promise<void>((resolve, reject) => {
  const start = Date.now()
  const intervalId = setInterval(() => {
    if (Date.now() >= start + timeout) {
      clearInterval(intervalId)
      // eslint-disable-next-line prefer-promise-reject-errors
      return reject()
    }

    const state = thunkApi.getState() as RootState
    if (!state.accounts.accounts.loading) {
      clearInterval(intervalId)
      return resolve()
    }
  }, retry)
})
