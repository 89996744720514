import './settings.scss'
import SettingsRouter from 'Router/SettingsRouter'
import { useDispatchAction } from 'utils/common/useDispatchAction'
import { bootstrap } from 'store/accountsSlice/accountsDispatchers'

function Settings () {
  useDispatchAction(bootstrap)
  return (
    <div className='common-page'>
      <div className='common-primary-view'>
        <div className='primary-card full-screen-card'>
          <SettingsRouter />
        </div>
      </div>
    </div>
  )
}

export default Settings
