import { MainModal, Button, SelectInput, TextArea } from '@reply-pro/component-library'
import { MaintenanceDetails } from 'store/accountsSlice/accountsTypes'
import { capitalizeFirstLetter } from 'utils/common'
import { maintenanceStatusesArray } from 'utils/constants'

interface MaintenanceModalProps {
    isModalOpen: {
        feature: string
        type: string
    }
    closeMaintenanceModal: () => void
    setMaintenanceDetails: (details: MaintenanceDetails) => void
    turnOnMaintenance: (feature: string) => void
    maintenanceDetails: MaintenanceDetails
}

function MaintenanceModal ({ isModalOpen, closeMaintenanceModal, maintenanceDetails, turnOnMaintenance, setMaintenanceDetails }: MaintenanceModalProps) {
  return (
    <MainModal
      showModal={isModalOpen !== undefined}
      toggleModalOpen={closeMaintenanceModal}
      showTopAndBottomLines
      headerClassName='maintenance-modal-header'
      className='maintenance-modal'
      bodyClassName='maintenance-modal-body'
      title={`${maintenanceDetails.isMaintenance ? 'Update' : 'Enable'} Maintenance - ${isModalOpen.feature}`}
      actions={
        <Button onClick={() => turnOnMaintenance(isModalOpen.feature)} disabled={!maintenanceDetails.status || !maintenanceDetails.message}>
          {maintenanceDetails.isMaintenance ? 'Update' : 'Enable'}
        </Button>
          }
    >
      <div className='maintenance-inputs'>
        <div className='maintenance-input-container'>
          <strong>Status:</strong>
          <SelectInput className='maintenance-input' value={maintenanceDetails.status} onChange={(e) => setMaintenanceDetails({ ...maintenanceDetails, status: e.target.value })}>
            {maintenanceStatusesArray.map(status => (
              <option key={status} value={status}>
                {capitalizeFirstLetter(status)}
              </option>
            ))}
          </SelectInput>
        </div>
        <div className='maintenance-input-container'>
          <strong>Message:</strong>
          <TextArea className='maintenance-input' value={maintenanceDetails.message} onChange={(e) => setMaintenanceDetails({ ...maintenanceDetails, message: e.target.value })} />
        </div>
      </div>
    </MainModal>
  )
}

export default MaintenanceModal
