export type relation = 'related' | 'forward' | undefined

export interface dataLayerObject {
    event: string
    id?: string
    type?: string
    option?: string
    value?: string
    accountId?: string
    userId?: string
}

export const dataLayerPush = (dataLayerObject: dataLayerObject | any) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerObject)
}
