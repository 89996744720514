import { useQuery } from '@apollo/client'
import { SURVEY_CODES } from 'requests/Surveys'
import { surveyCodesQuery } from 'types/surveyCodes'

export const useGetSurveyCodes = (survey: string) => {
  const { data } : surveyCodesQuery = useQuery(SURVEY_CODES, {
    variables: { survey }
  })

  return { data }
}
