import { TrashCanComponent } from '@reply-pro/component-library'
import { MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { removeUserGroup } from 'store/accountsSlice/users/dispatchers.users'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import './RemoveUserGroup.scss'

interface RemoveUserGroupProps {
  id: string
  name: string
}

const RemoveUserGroup = ({ id, name }: RemoveUserGroupProps) => {
  const [showModal, setShowModal] = useState(false)

  const { loading } = useSelector((store: RootState) => store.users.removeUserGroup)
  const dispatch = useDispatch<AppDispatch>()

  const handleShowModal = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setShowModal(true)
  }

  const handleRemoveUserGroup = async () => {
    const action = await dispatch(removeUserGroup(id))
    if (action.payload) setShowModal(true)
  }

  return (
    <>
      <TrashCanComponent className='user-group-remove' onClick={handleShowModal} />
      <ConfirmationModal
        showModal={showModal}
        toggleModalOpen={() => setShowModal(false)}
        modalTitle={`Remove ${name}`}
        confirmText='DELETE'
        warningFirstLine='Are you sure you want to delete this User Group?'
        buttonText='Remove User Group'
        onConfirm={handleRemoveUserGroup}
        disabled={loading}
      />
    </>
  )
}

export default RemoveUserGroup
