export const roles = {
  ADMIN: 'admin',
  MEMBER: 'member'
}

export const initialUserToAssociate = {
  type: '',
  userId: '',
  userName: ''
}
