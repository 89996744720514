import { SettingsPageStructure, SettingsHeader, LoadingSpinner } from '@reply-pro/component-library'
import SurveyDetailsSetup from './SurveyDetailsSetup'
import './SurveySetup.scss'
import SurveyCreatorRenderComponent from './SurveyCreator'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SurveySetupButtons from './SurveySetupButtons'
import { useParams } from 'react-router-dom'
import { useSurveySetupId } from './hooks'
import { useLazyQuery } from '@apollo/client'
import { LOCATIONS } from 'requests/Accounts/locations'
import { LocationsResponse } from 'types/location'

interface ManageSurveySetupProps {
  surveyType: string
}
function ManageSurveySetup ({ surveyType } : ManageSurveySetupProps) {
  const { id: browserId } = useParams()
  const { data, id, loading } = useSelector((state: RootState) => state.surveys.surveySetup)

  const [getAllLocations, { data: allLocations, loading: loadingAllLocations }] = useLazyQuery<LocationsResponse>(LOCATIONS, { variables: { first: 1000 } })

  useSurveySetupId(browserId)
  return (
    <SettingsPageStructure
      settingsHeader={<SettingsHeader title={`${surveyType}s Setup`} />}
      asideChildren={!id &&
        <div className='survey-details-container'>
          {loading
            ? <div className='loading-setup-container'><LoadingSpinner size='small' /></div>
            : <SurveyDetailsSetup
                surveyType={surveyType}
                getAllLocations={getAllLocations}
                loadingAllLocations={loadingAllLocations}
              />}
        </div>}
      subHeaderTitles={
        {
          asideSubTitle: <div className='sub-header-title'>{surveyType} Details</div>,
          mainSubTitle: (
            <div className='title-setup-container'>
              <div className='sub-header-title'>
                {data?.surveyName ? `${surveyType}: ` + data.surveyName : `${surveyType} Setup`}
              </div><SurveySetupButtons browserId={browserId} id={id} surveyType={surveyType} />
            </div>
          )
        }
      }
    >
      <div className='survey-creator-container'>
        {loading
          ? (
            <div className='loading-setup-container'>
              <LoadingSpinner size='small' />
            </div>
            )
          : (
              data && id
                ? (
                  <SurveyCreatorRenderComponent surveyType={surveyType} allLocations={allLocations?.locations.edges.map(({ node }) => ({ text: node.name, value: node.uuid }))} />
                  )
                : (
                  <div className='survey-creator-empty-state'>Before setting up questions, Save the {surveyType} Details</div>
                  )
            )}
      </div>

    </SettingsPageStructure>
  )
}

export default ManageSurveySetup
