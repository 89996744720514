import { BinaryInput } from '@reply-pro/component-library'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { LOCATION_PERMISSIONS } from './constants'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

const { GROUPS, HIERARCHIES } = LOCATION_PERMISSIONS
interface LocationPermissionsAsideProps {
  parent: string
  children: ReactNode
}

function LocationPermissionsAside ({ children, parent }: LocationPermissionsAsideProps) {
  const navigate = useNavigate()
  const { data: hierarchiesData } = useSelector((store: RootState) => store.locations.hierarchyGroups)

  const handleBinaryChange = () => {
    if (parent === GROUPS) {
      navigate(`/settings/location-permissions/hierarchies/${hierarchiesData?.hierarchyGroups?.edges[0].node.id}`)
    } else {
      navigate('/settings/location-permissions/groups/')
    }
  }

  return (
    <div className='location-permissions-aside-container'>
      <div className='binary-input-container'>
        <BinaryInput
          className='location-permissions-aside-binary'
          leftOption={GROUPS.toUpperCase()}
          rightOption={HIERARCHIES.toUpperCase()}
          isLeftOn={parent === GROUPS}
          onLeftOn={handleBinaryChange}
        />
      </div>
      {children}
    </div>
  )
}

export default LocationPermissionsAside
