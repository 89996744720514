import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'
import 'survey-core/survey.i18n.js'
import 'survey-creator-core/survey-creator-core.i18n.js'
import 'survey-core/defaultV2.css'
import 'survey-creator-core/survey-creator-core.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setLicenseKey, Serializer } from 'survey-core'
import { useSurveyCreator } from './hooks'
import { FORM } from 'pages/Settings/ManageForms/ManageForms'
import { LoadingSpinner } from '@reply-pro/component-library'
import { openNotificationWarning } from 'store/settingsSlice'
import locationsState from 'store/accountsSlice/locations/types.locations'
import './SurveyCreator.scss'
import { DefaultFonts } from 'survey-creator-core'

interface Props {
  surveyType: string
  allLocations?: locationsState['locationsSelected']
}

const fontLink = document.createElement('link')
fontLink.rel = 'stylesheet'
fontLink.href = 'https://fonts.googleapis.com/css2?family=Google+Sans:wght@400&family=Google+Sans+Display:wght@400&family=Google+Sans+Text:wght@400&family=Product+Sans:wght@400&display=swap'
document.head.appendChild(fontLink)

function SurveyCreatorRenderComponent ({ surveyType, allLocations }: Props) {
  const { locationTracking, askContactDetails } = useSelector((state: RootState) => state.surveys.surveySetup.data)
  const { disabled } = useSelector((state: RootState) => state.surveys.surveySetup)

  const themeJSON = useSelector((state: RootState) => state.surveys.surveySetup.data?.theme)
  const json = useSelector((state: RootState) => state.surveys.surveySetup.data?.json)

  const dispatch = useDispatch()

  if (process.env.REACT_APP_SURVEY_JS_LICENSE) {
    setLicenseKey(process.env.REACT_APP_SURVEY_JS_LICENSE)
  }

  const { saveLocalChanges } = useSurveyCreator()
  const themeObject = JSON.parse(themeJSON)
  const options = {
    showLogicTab: true,
    showThemeTab: true
  }
  const creator = new SurveyCreator(options)
  if (!DefaultFonts.includes('Google Sans Display, sans-serif')) {
    DefaultFonts.push('Google Sans Display, sans-serif')
  }
  creator.onModified.add(() => {
    saveLocalChanges(creator.JSON)
  })
  // Apply theme
  if (themeObject.theme_json) {
    creator.theme = themeObject.theme_json
  }

  creator.onActiveTabChanged.add((sender, options) => {
    if (options.tabName === 'logic') {
      dispatch(openNotificationWarning('Please save your changes before using Logic. Unsaved changes may cause conditions to function improperly!'))
    }
  })

  // Initialize questions if exist
  if (json) {
    creator.JSON = JSON.parse(json)
  }

  Serializer.findProperty('file', 'storeDataAsText').defaultValue = false
  Serializer.findProperty('file', 'storeDataAsText').visible = false

  creator.onUploadFile.add(function (_, options) {
    const file = options.files[0]

    const formData = new FormData()
    formData.append('mimetype', file.type)
    formData.append('name', file.name)
    formData.append('file', file)

    fetch(`${process.env.REACT_APP_SERVICES_BASE}/files/un-auth`, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        const fileUrl = data.result.resource.url
        options.callback('success', fileUrl)
      })
  })

  // Here we insert the location question if needed
  // Initialize an empty array to store elements
  const surveyElements = []

  // Here we insert the location question if needed
  if (locationTracking || askContactDetails) {
    if (locationTracking === 'question') {
      // Add the location question to the elements array
      surveyElements.push({
        type: 'dropdown',
        name: 'location-locked-question',
        isRequired: true,
        choices: allLocations,
        title: 'Location'
      })

      // Add event handler to disallow deletion of location question
      creator.onElementAllowOperations.add(function (sender, options) {
        if ((options.obj as any).name === 'location-locked-question') {
          options.allowDelete = false
        }
      })
    }

    // Add contact details questions if needed
    if (askContactDetails || surveyType === FORM) {
      surveyElements.push(
        {
          type: 'text',
          name: 'contact-first-name',
          title: 'First Name',
          isRequired: true
        },
        {
          type: 'text',
          name: 'contact-last-name',
          title: 'Last Name'
        },
        {
          type: 'text',
          name: 'contact-email',
          title: 'Email',
          isRequired: true,
          inputType: 'email'
        },
        {
          type: 'text',
          name: 'contact-phone',
          title: 'Phone'
        }
      )
    }

    creator.JSON = {
      elements: surveyElements
    }
  }

  const elementsToHide = [
    'name'
  ]

  creator.onShowingProperty.add(function (sender, options) {
    options.canShow = elementsToHide.indexOf(options.property.name) === -1
  })

  return (
    <>
      {disabled && <div className='saving-changes-overlay'><LoadingSpinner size='small' /> Saving Changes...</div>}
      <SurveyCreatorComponent creator={creator} />
    </>
  )
}

export default SurveyCreatorRenderComponent
