import { user } from 'types/user'
import { useGetLocationPermissions } from './hooks'
import { AstronautEmptyState, LoadingSpinner } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import './UserPermissions.scss'
import UserPermissionNav from './UserPermissionNav'
import { LOCATION_PERMISSIONS } from 'pages/Settings/LocationPermissionsSettings/constants'

interface UserPermissionsProps {
    isPermissionsModalOpen: boolean
    setIsPermissionsModalOpen: (bool: boolean) => void
    userDetails?: user
}

const { GROUPS, HIERARCHIES } = LOCATION_PERMISSIONS

function UserPermissions ({ isPermissionsModalOpen, setIsPermissionsModalOpen, userDetails }: UserPermissionsProps) {
  const { locationGroupsData, locationGroupsLoading, hierarchyGroupsData, hierarchyGroupsLoading } = useGetLocationPermissions(userDetails?.id)
  const userNameOrEmail = userDetails?.firstName || userDetails?.email
  return (
    <>
      <Modal
        showModal={isPermissionsModalOpen}
        className='location-permissions-modal'
        toggleModalOpen={() => setIsPermissionsModalOpen(false)}
        title={`User Permissions - ${userNameOrEmail}`}
      >
        <div className='user-location-permissions-modal-body'>
          {locationGroupsLoading || hierarchyGroupsLoading
            ? (<LoadingSpinner size='small' />)
            : (
              <>
                {locationGroupsData?.locationGroups?.edges.map((locationGroup) =>
                  <UserPermissionNav key={locationGroup.node.id} id={locationGroup.node.id} name={locationGroup.node.name} type={GROUPS} />)}
                {hierarchyGroupsData?.hierarchyGroups?.edges.map((hierarchyGroup) =>
                  <UserPermissionNav key={hierarchyGroup.node.id} id={hierarchyGroup.node.id} name={hierarchyGroup.node.name} type={HIERARCHIES} />)}
              </>
              )}
          {locationGroupsData?.locationGroups?.edges.length === 0 && hierarchyGroupsData?.hierarchyGroups?.edges.length === 0 &&
            <div className='no-location-permissions'>
              <AstronautEmptyState className='astronaut-empty-state' />
              No Location Permissions for {userNameOrEmail}
            </div>}
        </div>
      </Modal>
    </>
  )
}

export default UserPermissions
