import { gql } from '@apollo/client'

export const TAG_CREATE = gql`
  mutation tagCreate($input: TagCreateInput!) {
    tagCreate(input: $input) {
      errors {
        message
        code
      }
      success
    }
  }
`

export const TAG_DELETE = gql`
  mutation tagRemove($input: TagRemoveInput!) {
    tagRemove(input: $input) {
      errors {
        message
        code
      }
      success
    }
  }
`

export const TAG_GROUPS = gql`
  query tagGroups($first: Int, $search: String) {
    tagGroups(first: $first, search: $search) {
      edges {
        node {
          id
          label
          order
          description
          conditions
          taggableEntities
          tagFields {
            id
            uuid
            type
            label
            order
            choices
          }
        }
      }
    }
  }
`

export const TAG_CREATE_GROUP = gql`
  mutation tagGroupCreate($input: TagGroupCreateInput!) {
    tagGroupCreate(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const TAG_CREATE_FIELD = gql`
  mutation tagFieldCreate($input: TagFieldCreateInput!) {
    tagFieldCreate(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const TAG_GROUP_SET_CONDITION = gql`
  mutation tagGroupSetConditions($input: TagGroupSetConditionsInput!) {
    tagGroupSetConditions(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const TAG_GROUP_DEACTIVATE = gql`
  mutation tagGroupDeactivate($input: TagGroupDeactivateInput!) {
    tagGroupDeactivate(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const TAG_FIELD_DEACTIVATE = gql`
  mutation tagFieldDeactivate($input: TagFieldDeactivateInput!) {
    tagFieldDeactivate(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const TAG_FIELD_SET_CHOICES = gql`
  mutation tagFieldSetChoices($input: TagFieldSetChoicesInput!) {
    tagFieldSetChoices(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`

export const TAG_FIELD_REORDER = gql`
  mutation tagFieldReorder($input: TagFieldReorderInput!) {
    tagFieldReorder(input: $input) {
      created {
        id
      }
      errors {
        code
      }
      success
    }
  }
`
