import { MouseEvent, useState } from 'react'
import { Edit } from '@reply-pro/component-library'
import UserGroupEditor from '../../UserGroupEditor'
import { useEditUserGroup } from './hooks'

interface EditUserGroupProps {
  id: string
  searchValueUserGroups?: string
  className: string
  name: string
  description?: string
}

const EditUserGroup = ({ id, name, description, searchValueUserGroups, className }: EditUserGroupProps) => {
  const [showModal, setShowModal] = useState(false)
  const { handleOnSubmit, loading, error } = useEditUserGroup({ setShowModal, searchValueUserGroups })

  const handleEditUserGroup = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()
    setShowModal(true)
  }

  return (
    <>
      <Edit className={className} onClick={handleEditUserGroup} />
      {showModal &&
        <UserGroupEditor
          showModal={showModal}
          setShowModal={setShowModal}
          id={id}
          name={name}
          description={description}
          handleOnSubmit={handleOnSubmit}
          loading={loading}
          error={error}
        />}
    </>
  )
}

export default EditUserGroup
