import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'

export const useDispatchAction = (action: any, params?:any) => {
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    dispatch(action(params && params))
  }, [action, dispatch, params])
}
