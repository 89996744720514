import { useState } from 'react'
import { RootState, AppDispatch } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { TextInput, Button, ReplyProRocketLogo } from '@reply-pro/component-library'
import { login } from 'store/loginSlice'
import './Login.scss'

function Login () {
  const dispatch: AppDispatch = useDispatch()
  const { loading, error } = useSelector((state: RootState) => state.login)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const attemptLogin = () => dispatch(login({ email, password }))

  return (
    <div className='login-page'>
      <div className='login-wrapper'>
        <div className='image-wrapper'>
          <ReplyProRocketLogo />
        </div>
        <div>
          <TextInput
            className='login-input'
            placeholder='Email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>
        <div>
          <TextInput
            className='login-input'
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !loading) attemptLogin()
            }}
            disabled={loading}
          />
        </div>
        <div className='button-wrapper'>
          <Button
            className='login-button'
            disabled={loading}
            onClick={attemptLogin}
          >
            Log In
          </Button>
        </div>

        {
          error &&
            <div className='error'>{error.message}</div>
        }
      </div>
    </div>
  )
}

export default Login
