import { store, RootState } from './store'
import { ApolloClient, InMemoryCache, createHttpLink, from, fromPromise } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { logout, refresh } from './store/loginSlice'
import { onError } from '@apollo/client/link/error'

interface RequestOptions {
  url?: string
  path?: string
  method?: string
  authenticated?: boolean
  scoped?: boolean
  data?: any
  // eslint-disable-next-line no-undef
  requestOptions?: Partial<RequestInit>
}

let tokenRefreshing = false
let pendingRequests: Function[] = []

// eslint-disable-next-line no-return-assign
const setTokenRefreshing = (value: boolean) => tokenRefreshing = value
const addPendingRequest = (pendingRequest: Function) => pendingRequests.push(pendingRequest)
const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_BASE}`
})

const authLink = setContext((_, { headers: existingHeaders }) => {
  const state: RootState = store.getState()
  const { login: { access }, accounts: { accountId } } = state

  if (_.operationName === 'tokenRefresh' || _.operationName === 'tokenPairObtain') {
    return {}
  }

  const headers = {
    ...existingHeaders,
    authorization: access ? `Bearer ${access}` : '',
    accts: accountId,
    cacct: accountId,
    'Accept-Encoding': 'gzip'
  }

  return { headers }
})

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].message.includes('The token you provided is invalid. Maybe it expired?')) {
      if (!tokenRefreshing) {
        setTokenRefreshing(true)
        return fromPromise(
          store.dispatch(refresh())
            .catch(() => {
              resolvePendingRequests()
              setTokenRefreshing(false)
            })
        ).flatMap(() => {
          resolvePendingRequests()
          setTokenRefreshing(false)

          return forward(operation)
        })
      } else {
        return fromPromise(
          new Promise((resolve) => {
            addPendingRequest(resolve)
          })
        ).flatMap(() => {
          return forward(operation)
        })
      }
    }
  }
})

export const gqlCache = new InMemoryCache()

export const apollo = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: gqlCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

export const request = async <T>(options: RequestOptions): Promise<T> => {
  const {
    url,
    path,
    method = 'GET',
    authenticated = false,
    scoped = false,
    requestOptions
  } = options

  if (url && path) {
    throw new Error('URL and Path are mutually exclusive')
  }

  if (!url && !path) {
    throw new Error('Either URL or Path are required')
  }

  const requestUrl: string = path
    ? `${process.env.REACT_APP_API_BASE}${path}`
    : url!

  // eslint-disable-next-line no-undef
  const requestHeaders: HeadersInit = new Headers(requestOptions?.headers)

  if (authenticated) {
    const state: RootState = store.getState()
    const { login: { access } } = state
    requestHeaders.set('Authorization', `Bearer ${access}`)
  }

  if (scoped) {
    const state: RootState = store.getState()
    const { accounts: { accountId } } = state
    requestHeaders.set('accts', accountId)
    requestHeaders.set('caact', accountId)
  }

  const response = await window.fetch(requestUrl, {
    ...requestOptions,
    method,
    headers: requestHeaders
  })

  if (response.status === 401) {
    store.dispatch(logout())
    throw new Error('Token is invalid')
  }

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.message || data)
  }

  return data
}
