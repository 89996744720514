import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USERS } from 'requests/Settings'
import { AppDispatch, RootState } from 'store'
import { retrieveUsers } from 'store/accountsSlice/users/dispatchers.users'
import { userQuery } from 'types/user'

interface GetUsersProps {
  all?: boolean,
  viewingActive: boolean
}

export const useGetUsers = (all?: boolean) => {
  const [queryParam, setQueryParam] = useState('')
  const { data, refetch } : userQuery = useQuery(USERS, {
    variables: { first: 10, search: queryParam, all }
  })

  return { data, queryParam, setQueryParam, refetch }
}

export const useFetchPageUserList = ({ all, viewingActive }: GetUsersProps) => {
  const [usersListSearch, setUsersListSearch] = useState('')
  const dispatch: AppDispatch = useDispatch()
  const { data: users, loading } = useSelector((state: RootState) => state.users.allUsers)

  const getUsers = () => {
    dispatch(retrieveUsers({ queryParam: usersListSearch, all, viewingActive }))
  }

  const searchUser = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    setUsersListSearch(e.target.value)
  }, 1000)

  useEffect(() => {
    getUsers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersListSearch])

  return { users, searchUser, getUsers, loading }
}

export const useFetchModalUserList = (all?: boolean) => {
  const { data: users, setQueryParam, queryParam, refetch } = useGetUsers(all)
  const searchUser = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    setQueryParam(e.target.value)
  }, 1000)

  return { users, searchUser, queryParam, refetch }
}

export const getUserAttributeToDisplay = (firstName?: string, lastName?: string, email?: string) =>
  firstName && lastName ? `${firstName} ${lastName}` : email
