import { createRef } from 'react'
import { SelectInput } from '@reply-pro/component-library'
import { useGetUsers } from 'utils/hooks/users'

interface UserSelectorProps {
  handleSelectUser: ({ target }: { target: { value: string } }) => void
  user: string
  placeholder?: string
  className?: string
}

export default function UserSelector ({ user, handleSelectUser, className, placeholder }: UserSelectorProps) {
  const { data: usersData, setQueryParam: setUsersQueryParam } = useGetUsers()

  const userInputRef = createRef()

  return (
    <SelectInput
      onChange={handleSelectUser}
      input={userInputRef}
      placeholder={placeholder}
      onInputChange={(e: { target: { value: string } }) => setUsersQueryParam(e.target.value)}
      value={user || ''}
      className={className}
    >
      {usersData?.users.edges.map(({ node: user }, index) =>
        <option
          value={user.uuid}
          className='from-container close'
          key={user.uuid + '-' + index}
        >
          {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}
        </option>
      )}
    </SelectInput>
  )
}
