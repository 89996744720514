import { useSelector } from 'react-redux'
import { RootState } from 'store'
import App from 'App'
import Login from 'pages/Login'
import Settings from 'pages/Settings'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import NotFoundPage from 'components/NotFoundPage'

function Router () {
  const { authenticated } = useSelector((state: RootState) => state.login)

  if (!authenticated) {
    return (
      <Login />
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route index element={<Navigate to='/settings' replace />} />
          <Route path='/settings/*' element={<Settings />} />
          <Route path='*' element={<NotFoundPage routeToNavigate='settings' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
