import React from 'react'
import { Button } from '@reply-pro/component-library'
import './SelectButton.scss'
import classNames from 'classnames'

interface SelectButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: string;
  active?: boolean;
  disabled?: boolean;
  id?: string;
  className?: string;
  small?: boolean;
}

function SelectButton ({
  small,
  onClick,
  children,
  active,
  disabled = false,
  id,
  className
}: SelectButtonProps) {
  return (
    <Button
      id={id && id}
      onClick={onClick}
      disabled={disabled}
      small={small}
      className={classNames(`select-button ${className}`, { active })}
    >
      <div className={`select-button-children ${className}`}>{children}</div>
    </Button>
  )
}

export default SelectButton
