import { Tag } from 'components/SurveysManager/ManageSurveySetup/PostSurveyActionsModal/AddPostSurveyActionsModal/hooks'
import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { attachFile } from 'store/filesSlice/filesDispatchers'
import { FILE_LIMITS, MEGABYTE_TO_BYTE } from 'utils/hooks/files'

export const useRemoveTag = (setTags: Dispatch<SetStateAction<Tag[]>>) => {
  const remove = (props: { entityId?: string; id: string; entityType?: string; uuid: string; value: string }) => {
    const { uuid, value } = props
    setTags((prev) => prev.filter((tag) => {
      return tag.tagField.uuid !== uuid || tag.value !== value
    }))
  }
  return { remove }
}

export const useAddTag = (setTags: Dispatch<SetStateAction<Tag[]>>) => {
  const dispatch = useDispatch()
  const add = async (props: { entityId?: string; id: string; entityType?: string; uuid: string | object; value: string; replaceAllExistingTags: boolean }) => {
    const { uuid, value, entityType, id } = props
    let file: any = null
    if (typeof value === 'object') {
      const files: File[] = Object.values(value)
      const totalUploadSize = files.reduce((acc, file) => acc + file.size, 0)
      if (totalUploadSize > FILE_LIMITS.total_size) return console.log(`Max ${FILE_LIMITS.total_size / MEGABYTE_TO_BYTE}mb total exceeded`)
      const attachmentResponse: any = await dispatch(attachFile({ file: files[0], attachTime: +new Date(), isPreventStore: true }) as any)
      if (!attachmentResponse?.payload?.result) {
        return
      }
      file = { name: files[0].name, size: files[0].size, id: attachmentResponse?.payload?.result.resource.id }
      file = JSON.stringify(file)
    }
    setTags((prev) => [...prev, { entityType, tagField: { id, uuid: uuid as string }, value: (file || value) }])
  }
  return { add }
}
