import { Modal as ModalLibrary, ModalActions, ModalBody, ModalHeader } from '@reply-pro/component-library'
import './modal.scss'
import { ReactNode } from 'react'
interface modalProps {
    showModal: boolean
    title?:string
    children?: ReactNode
    actions?: ReactNode
    toggleModalOpen?: ()=> void
    className?: string
}

export default function Modal ({ showModal, title, actions, children, toggleModalOpen, className }: modalProps) {
  return (
    <ModalLibrary className={className} shown={showModal} close={toggleModalOpen}>
      <ModalHeader className='modal-header'> {title} </ModalHeader>
      <ModalBody className='modal-body'> {children} </ModalBody>
      {actions ? <ModalActions> {actions} </ModalActions> : <div />}
    </ModalLibrary>
  )
}
