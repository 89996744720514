import { /* Edit, */ NavigationFolder, PlusAdd } from '@reply-pro/component-library'
import { reportPage } from 'store/settingsSlice/settingsTypes'
import ReportsList from './ReportsList/ReportsList'
import ElementDelete from 'components/ElementDelete'
import { removeReportSection } from 'store/settingsSlice/settingsReducers'
import CreateReportModal from './CreateReportModal/CreateReportModal'
import { useState } from 'react'

interface SectionActionsProps {
  sectionId: string
  sectionName: string
}

function SectionActions ({ sectionId, sectionName }: SectionActionsProps) {
  const [isShowCreateReportModal, setIsShowCreateReportModal] = useState(false)

  const toggleIsShowCreateReportModal = () => setIsShowCreateReportModal(prev => !prev)

  return (
    <div className='section-options'>
      <div>
        <PlusAdd onClick={toggleIsShowCreateReportModal} className='add-report-page-icon' />
        <CreateReportModal
          reportSectionId={sectionId}
          isShowCreateReportModal={isShowCreateReportModal}
          toggleIsShowCreateReportModal={toggleIsShowCreateReportModal}
        />
      </div>
      {/* <div>
        <Edit />
      </div>
      //not in the scope of this sprint. We can plan for it later */}
      <div>
        <ElementDelete
          deleteDispatcher={removeReportSection}
          id={sectionId}
          name={sectionName}
          type='section and all the pages on it'
        />
      </div>
    </div>
  )
}

interface SectionProps extends SectionActionsProps {
  reportPages: reportPage[]
}

export default function Section ({ sectionName, reportPages, sectionId }: SectionProps) {
  return (
    <NavigationFolder className='section-listing' label={sectionName} subElements={<SectionActions sectionName={sectionName} sectionId={sectionId} />}>
      <ReportsList reportPages={reportPages} sectionId={sectionId} />
    </NavigationFolder>
  )
}
