import { userGroupNode, userNode } from 'store/settingsSlice/settingsTypes'
import { user, userGroup } from 'types/user'

const turnUserIntoIndexedByIdObject = (user: user | userGroup) => {
  const { id, ...userValues } = user
  return { [id]: userValues }
}

export const createUsersIndexedById = (users: (userNode | userGroupNode)[]) => {
  const indexedTagFields = users.reduce((acc, { node }: userNode | userGroupNode) => {
    return { ...acc, ...turnUserIntoIndexedByIdObject(node) }
  }, {})
  return indexedTagFields
}
