import { NavItem, TrashCanComponent } from '@reply-pro/component-library'
import { user } from 'types/user'
import { useRemoveUserGroupUser } from './hooks'
import './UserGroupUser.scss'

interface UserGroupUserProps {
  id?: string
  user: user
}

const UserGroupUser = ({ id, user }: UserGroupUserProps) => {
  const handleRemoveUser = useRemoveUserGroupUser({ id, user })

  return (
    <NavItem
      className='user-group-user'
    >
      <div className='user-group-user-info'>
        <div className='user-group-user-name'>{user.firstName || 'UNNAMED USER'}</div>
        <div className='user-group-user-email'>{user.email}</div>
      </div>
      <TrashCanComponent className='user-group-user-remove' onClick={handleRemoveUser} />
    </NavItem>
  )
}

export default UserGroupUser
