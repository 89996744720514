import { createSlice } from '@reduxjs/toolkit'
import usersState from './types.users'
import { addUserGroup, addUsersToUserGroup, editUserGroup, getUserGroupUsers, getUserGroups, removeUserGroup, removeUsersFromUserGroup, retrieveUsers, toggleUserActive } from './dispatchers.users'
import { updateUserAllowLoginInData } from '../functions'

const initialState: usersState = {
  allUsers: {
    data: undefined,
    loading: false,
    error: undefined
  },
  userToggleLoading: false,
  userGroups: {
    data: undefined,
    loading: false,
    error: undefined
  },
  userGroupUsers: {
    data: undefined,
    loading: false,
    error: undefined
  },
  addUserGroup: {
    loading: false,
    error: undefined
  },
  editUserGroup: {
    loading: false,
    error: undefined
  },
  removeUserGroup: {
    loading: false,
    error: undefined
  },
  addUsersToUserGroup: {
    loading: false,
    error: undefined
  },
  removeUsersFromUserGroup: {
    loading: false,
    error: undefined
  }
}

export const usersSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.allUsers = initialState.allUsers
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(retrieveUsers.pending, (state) => {
      state.allUsers.loading = true
    })

    addCase(retrieveUsers.rejected, (state) => {
      state.allUsers.loading = false
    })

    addCase(retrieveUsers.fulfilled, (state, action) => {
      state.allUsers.data = action.payload
      state.allUsers.loading = false
    })

    addCase(toggleUserActive.pending, (state) => {
      state.userToggleLoading = true
    })

    addCase(toggleUserActive.rejected, (state) => {
      state.userToggleLoading = false
    })

    addCase(toggleUserActive.fulfilled, (state, action) => {
      state.allUsers.data = updateUserAllowLoginInData(state, action)
      state.userToggleLoading = false
    })

    addCase(getUserGroups.pending, (state) => {
      state.userGroups.loading = true
    })

    addCase(getUserGroups.rejected, (state) => {
      state.userGroups.error = true
      state.userGroups.loading = false
    })

    addCase(getUserGroups.fulfilled, (state, action) => {
      state.userGroups.loading = false
      state.userGroups.data = action.payload.userGroups.edges
    })

    addCase(getUserGroupUsers.pending, (state) => {
      state.userGroupUsers.loading = true
    })

    addCase(getUserGroupUsers.rejected, (state) => {
      state.userGroupUsers.error = true
      state.userGroupUsers.loading = false
    })

    addCase(getUserGroupUsers.fulfilled, (state, action) => {
      state.userGroupUsers.loading = false
      state.userGroupUsers.data = action.payload.userGroupUsers.edges
    })

    addCase(addUserGroup.pending, (state) => {
      state.addUserGroup.error = false
      state.addUserGroup.loading = true
    })

    addCase(addUserGroup.rejected, (state) => {
      state.addUserGroup.error = true
      state.addUserGroup.loading = false
    })

    addCase(addUserGroup.fulfilled, (state) => {
      state.addUserGroup.error = false
      state.addUserGroup.loading = false
    })

    addCase(editUserGroup.pending, (state) => {
      state.editUserGroup.error = false
      state.editUserGroup.loading = true
    })

    addCase(editUserGroup.rejected, (state) => {
      state.editUserGroup.error = true
      state.editUserGroup.loading = false
    })

    addCase(editUserGroup.fulfilled, (state) => {
      state.editUserGroup.error = false
      state.editUserGroup.loading = false
    })

    addCase(removeUserGroup.pending, (state) => {
      state.removeUserGroup.error = false
      state.removeUserGroup.loading = true
    })

    addCase(removeUserGroup.rejected, (state) => {
      state.removeUserGroup.error = true
      state.removeUserGroup.loading = false
    })

    addCase(removeUserGroup.fulfilled, (state, action) => {
      state.removeUserGroup.error = false
      state.removeUserGroup.loading = false

      const updatedUserGroups = (state.userGroups.data ?? []).filter(({ node }) => action.payload !== node.id)
      state.userGroups.data = [...updatedUserGroups]
    })

    addCase(addUsersToUserGroup.pending, (state) => {
      state.addUsersToUserGroup.error = false
      state.addUsersToUserGroup.loading = true
    })

    addCase(addUsersToUserGroup.rejected, (state) => {
      state.addUsersToUserGroup.error = true
      state.addUsersToUserGroup.loading = false
    })

    addCase(addUsersToUserGroup.fulfilled, (state) => {
      state.addUsersToUserGroup.error = false
      state.addUsersToUserGroup.loading = false
    })

    addCase(removeUsersFromUserGroup.pending, (state) => {
      state.removeUsersFromUserGroup.error = false
      state.removeUsersFromUserGroup.loading = true
    })

    addCase(removeUsersFromUserGroup.rejected, (state) => {
      state.removeUsersFromUserGroup.error = true
      state.removeUsersFromUserGroup.loading = false
    })

    addCase(removeUsersFromUserGroup.fulfilled, (state, action) => {
      state.removeUsersFromUserGroup.error = false
      state.removeUsersFromUserGroup.loading = false

      const updatedUserGroupUsers = (state.userGroupUsers.data ?? []).filter(({ node }) => !action.payload['removed users'].includes(node.id))
      state.userGroupUsers.data = [...updatedUserGroupUsers]
    })
  }
})

export const { clearUsers } = usersSlice.actions

export default usersSlice.reducer
