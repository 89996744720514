import { ToggleInput } from '@reply-pro/component-library'
import { useSurveyDeactivate } from './hooks'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'

export default function SurveyDeactivate ({ id, isActive, surveyName, surveyType }: { id: string, isActive: boolean, surveyName: string, surveyType: string }) {
  const { isConfirmationModalOpen, toggleConfirmationModalOpen, handleConfirmToggle, loading } = useSurveyDeactivate(id)
  return (
    <>
      <ToggleInput checked={isActive} disabled={loading || !isActive} onChange={toggleConfirmationModalOpen} />
      {isConfirmationModalOpen &&
        <ConfirmationModal
          showModal={isConfirmationModalOpen}
          toggleModalOpen={toggleConfirmationModalOpen}
          warningFirstLine={`You are about to deactivate ${surveyName}, this ${surveyType} will no longer receive responses.`}
          confirmText='DEACTIVATE'
          buttonText='DEACTIVATE'
          onConfirm={handleConfirmToggle}
          modalTitle='DEACTIVATE USER'
        />}
    </>
  )
}
