import { createSlice } from '@reduxjs/toolkit'
import { FilesState, SavedAndErrorFilesData } from './filesTypes'
import { attachFile } from './filesDispatchers'

const initialState: FilesState = {
  savedFiles: {
    loading: false,
    success: false,
    data: []
  },
  fileDragging: false,
  successMessage: null,
  errorMessage: null,
  savedAndErrorFiles: []
}

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    deleteSavedFile: (state, action) => {
      state.savedFiles.data = state.savedFiles.data.filter((file) => file.id !== action.payload)
      state.savedAndErrorFiles = state.savedAndErrorFiles.filter((file) => file.id !== action.payload)
    },
    deleteAllSavedFiles: (state, action) => {
      state.savedFiles.data = []
      state.savedAndErrorFiles = []
    },
    replaceFiles: (state, action) => {
      if (action.payload) {
        state.savedFiles.data = action.payload
        state.savedAndErrorFiles = action.payload.map((file: SavedAndErrorFilesData) => ({ error: false, ...file }))
      } else {
        state.savedFiles.data = initialState.savedFiles.data
        state.savedAndErrorFiles = initialState.savedAndErrorFiles
      }
    },
    filterErrorFiles: (state) => {
      state.savedAndErrorFiles = state.savedAndErrorFiles.filter((file) => !file.error)
    },
    resetSuccess: (state) => {
      state.savedFiles.success = initialState.savedFiles.success
    },
    setFileDragging: (state, action) => {
      state.fileDragging = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(attachFile.rejected, (state, action) => {
      const { attachTime, isPreventStore } = action.meta.arg
      if (isPreventStore) {
        return
      }
      state.savedFiles.success = false
      const fileWithErrorIndexInAllFiles = state.savedAndErrorFiles.findIndex((file) => file.attachTime === attachTime)
      state.savedAndErrorFiles[fileWithErrorIndexInAllFiles].error = true
      if (state.savedAndErrorFiles.every((file) => file.error !== undefined)) {
        state.savedFiles.loading = false
      }
    })

    builder.addCase(attachFile.pending, (state, action) => {
      const {
        file: { name, size },
        attachTime,
        isPreventStore
      } = action.meta.arg
      if (isPreventStore) {
        return
      }
      state.savedFiles.loading = true
      state.savedFiles.success = false
      state.savedAndErrorFiles = [...state.savedAndErrorFiles, { name, size, attachTime }]
    })

    builder.addCase(attachFile.fulfilled, (state, action) => {
      const {
        file: { name, size },
        attachTime,
        isPreventStore
      } = action.meta.arg
      const response: any = action.payload
      if (isPreventStore) {
        return
      }
      if (response.result.errors.length > 0) {
        const fileWithErrorIndexInAllFiles = state.savedAndErrorFiles.findIndex((file) => file.attachTime === attachTime)
        state.savedAndErrorFiles[fileWithErrorIndexInAllFiles].error = true
        state.savedFiles.success = false
      } else {
        const { id } = response.result.resource
        state.savedFiles.data = [...state.savedFiles.data, { name, size, attachTime, id }]
        const uploadedFileIndexInAllFiles = state.savedAndErrorFiles.findIndex((file) => file.attachTime === attachTime)
        state.savedAndErrorFiles[uploadedFileIndexInAllFiles].id = id
        state.savedAndErrorFiles[uploadedFileIndexInAllFiles].error = false
        state.savedFiles.success = true
      }
      if (state.savedAndErrorFiles.every((file) => file.error !== undefined)) {
        state.savedFiles.loading = false
      }
    })
  }
})

export const { deleteSavedFile, deleteAllSavedFiles, replaceFiles, setFileDragging, filterErrorFiles, resetSuccess } = filesSlice.actions
export default filesSlice.reducer
