import React from 'react'
import ReactDOM from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { OverlayContentProvider } from '@reply-pro/component-library'
import { store, persistor } from './store'
import { apollo } from './api'
import Router from './Router'
import reportWebVitals from './reportWebVitals'

import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const overlayContentRoot = document.getElementById('overlay-content-root')

root.render(
  <React.StrictMode>
    <OverlayContentProvider root={overlayContentRoot as HTMLDivElement}>
      <ApolloProvider client={apollo}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </OverlayContentProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
