import { SelectInput, ThreeDotsV, TrashCan } from '@reply-pro/component-library'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { removeReportCollection } from 'store/settingsSlice/settingsReducers'
import { eventTargetValue } from 'types/common'

interface CollectionOptionsProps {
  collectionId: string
}

export default function CollectionOptions ({ collectionId }: CollectionOptionsProps) {
  const dispatch = useDispatch<AppDispatch>()
  const onOptionSelected = (e: eventTargetValue) => {
    if (e.target.value === 'delete') {
      dispatch(removeReportCollection(collectionId))
    }
  }
  return (
    <div className='collection-options'>
      <SelectInput
        className='context-menu'
        icon={<ThreeDotsV className='kebab' />}
        small
        onChange={onOptionSelected}
        valueColClassName='popover'
      >
        <option value='delete'>
          <TrashCan /> Delete collection
        </option>
      </SelectInput>
    </div>
  )
}
