import { debounce } from 'lodash'
import { useState, ChangeEvent, useCallback, useEffect, Dispatch, SetStateAction } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'store'
import { getUserGroupUsers, getUserGroups } from 'store/accountsSlice/users/dispatchers.users'
import { userGroup } from 'types/user'

export const useGetUserGroups = (selectedUserGroup: userGroup | undefined, setSelectedUserGroup: Dispatch<SetStateAction<userGroup | undefined>>) => {
  const { data, loading } = useSelector((store: RootState) => store.users.userGroups)

  const [searchValue, setSearchValue] = useState('')

  const dispatch = useDispatch<AppDispatch>()

  const searchUserGroups = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value !== '') {
      setSearchValue(value)
      searchUserGroupsAction(value)
    } else {
      clearSearch()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchUserGroupsAction = useCallback(debounce((search: string) => {
    dispatch(getUserGroups({ search }))
  }, 1500), [dispatch])

  const clearSearch = () => {
    setSearchValue('')
    dispatch(getUserGroups({}))
  }

  useEffect(() => {
    dispatch(getUserGroups({}))
  }, [dispatch])

  useEffect(() => {
    if (data) {
      if (!selectedUserGroup) {
        setSelectedUserGroup(data[0]?.node)
      } else {
        if (data?.every(({ node }) => node.name !== selectedUserGroup?.name || node.description !== selectedUserGroup?.description)) {
          setSelectedUserGroup(data?.find(({ node }) => node.id === selectedUserGroup?.id)?.node)
        }
      }
    }
  }, [data, selectedUserGroup, setSelectedUserGroup])

  return { data, searchValue, loading, searchUserGroups, clearSearch }
}

export const useGetUserGroupUser = (userGroupId?: string) => {
  const { data, loading } = useSelector((store: RootState) => store.users.userGroupUsers)

  const [searchValue, setSearchValue] = useState('')

  const dispatch = useDispatch<AppDispatch>()

  const searchUsers = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value !== '') {
      setSearchValue(value)
      searchUserGroupUsersAction(value)
    } else {
      clearSearch()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchUserGroupUsersAction = useCallback(debounce((search: string) => {
    if (!userGroupId) return
    dispatch(getUserGroupUsers({ userGroupId, search }))
  }, 1500), [userGroupId, dispatch])

  const clearSearch = () => {
    if (!userGroupId) return
    setSearchValue('')
    dispatch(getUserGroupUsers({ userGroupId }))
  }

  useEffect(() => {
    if (userGroupId) {
      dispatch(getUserGroupUsers({ userGroupId }))
    }
  }, [dispatch, userGroupId])

  return { data, searchValue, loading, searchUsers, clearSearch }
}
