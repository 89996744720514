import classNames from 'classnames'
import ManageReportsCollections from './ManageReportsCollections'
import CollectionDetail from './CollectionDetail'
import ManageReportsContextRouter from './ManageReportsContextRouter'
import './manageReports.scss'

export default function ManageReports () {
  return (
    <div className='manage-reports-container'>
      <ManageReportsContextRouter />
      <div className={classNames('manage-reports')}>
        <ManageReportsCollections />
      </div>
      <div className='collection-detail'>
        <CollectionDetail />
      </div>
    </div>
  )
}
