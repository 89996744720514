import { RootState } from '..'
import { apollo } from 'api'
import { gql } from '@apollo/client'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserMetadata, MetadataQueryResult, MetadataMutationInput, MetadataMutationResult, MetadataState } from './metadataTypes'

const query = gql`
  query userAppPreference {
    userAppMetadata {
      preferences
      user {
        id
        email
        timeZone
        uuid
      }
    }
  }
`

const mutation = gql`
  mutation userAppMetadataUpdate($input: UserAppMetadataUpdateInput!) {
    userAppMetadataUpdate(input: $input) {
      userAppMetadata {
        preferences
      }
    }
  }
`

export const refresh = createAsyncThunk<UserMetadata>(
  'metadata/refresh',
  async (_, thunkApi) => {
    const currentState = thunkApi.getState() as RootState

    if (currentState.metadata.out_of_sync) {
      await thunkApi.dispatch(update(currentState.metadata))
    }

    const data = await apollo.query<MetadataQueryResult>({ query })

    if (data.data?.userAppMetadata) {
      return data.data?.userAppMetadata
    }

    throw new Error('Error while fetching user metadata')
  }
)

export const update = createAsyncThunk<UserMetadata, MetadataMutationInput>(
  'metadata/update',
  async (payload: MetadataMutationInput, thunkApi) => {
    const currentState = thunkApi.getState() as RootState

    // merge in existing preferences
    payload.preferences = {
      ...currentState.metadata.preferences,
      ...payload.preferences
    }

    const data = await apollo.mutate<MetadataMutationResult>({
      mutation,
      variables: {
        input: payload
      }
    })

    if (data.data?.userAppMetadataUpdate?.userAppMetadata) {
      return data.data?.userAppMetadataUpdate.userAppMetadata
    }

    throw new Error('Error while updating user metadata')
  }
)

const initialState: MetadataState = {
  preferences: {
    theme: 'light',
    default_account: '',
    inbox_saved_view_labels: {},
    tag_field_colors: {},
    colors: {}
  },
  user: {
    id: '',
    email: '',
    timeZone: '',
    uuid: ''
  },
  out_of_sync: false
}

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refresh.rejected, (state) => {
      state.out_of_sync = true
    })

    builder.addCase(refresh.fulfilled, (state, action) => {
      const { preferences, user } = action.payload
      state.preferences = preferences
      state.user = user
      state.out_of_sync = false
    })

    builder.addCase(update.fulfilled, (state, action) => {
      const { preferences } = action.payload
      state.preferences = preferences
      state.out_of_sync = false
    })
  }
})

export default metadataSlice.reducer
