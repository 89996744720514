import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SELF_ADD_TO_ALL_ACCOUNTS } from 'requests/Settings'
import { AppDispatch } from 'store'
import { retrieveAccounts, setAccountFeatures } from 'store/accountsSlice/accountsDispatchers'
import { Feature } from 'types/account'

type InitialDataProps = ({ id: string, name?: string, searchApiKey?: string, notificationsDeactivated?: boolean, features?: string[] })

interface UseHandleConfirmationProps {
  initialData: InitialDataProps
  handleConfirm: ((inputValue: InitialDataProps) => void)
}

export const useHandleConfirmation = ({ initialData, handleConfirm } : UseHandleConfirmationProps) => {
  const [data, setData] = useState(initialData)
  const [showModal, setShowModal] = useState(false)

  const onConfirm = () => {
    handleConfirm(data)
    closeModal()
  }

  const openModal = (newData: InitialDataProps) => {
    setData(newData)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setData(initialData)
  }

  return { onConfirm, openModal, closeModal, showModal }
}

export const useHandleSetAccountFeatures = (setShowAccountSetFeaturesModal?: React.Dispatch<React.SetStateAction<boolean>>) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleSetAccountFeatures = async ({ accountId, features }: { accountId: string, features: Feature[] }) => {
    return await dispatch(setAccountFeatures({ accountId, features }))
  }

  const handleDone = () => {
    dispatch(retrieveAccounts())
    setShowAccountSetFeaturesModal?.(false)
  }

  return { handleSetAccountFeatures, handleDone }
}

export const useHandleAddAllAccounts = () => {
  const [clicked, setClicked] = useState(false)

  const [selfAddAllAccounts, { data, loading }] = useMutation(SELF_ADD_TO_ALL_ACCOUNTS)
  const dispatch = useDispatch<AppDispatch>()

  const handleAddAllAccounts = () => {
    selfAddAllAccounts()
    setClicked(true)
  }

  useEffect(() => {
    if (data?.userSelfAssociateToAllAccounts.success) {
      dispatch(retrieveAccounts())
    }
  }, [data?.userSelfAssociateToAllAccounts.success, dispatch])

  return { handleAddAllAccounts, clicked, loadingAddAllAccounts: loading }
}
