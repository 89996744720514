import { dataLayerPush } from './gtm'

export function accountSetFromModalTrackingEvent (accountId: string) {
  dataLayerPush({ event: 'accountSetFromModal', accountId })
}

export function accountSetFromDropdownTrackingEvent (accountId: string) {
  dataLayerPush({ event: 'accountSetFromDropdown', accountId })
}

export function accountSessionTrackingEvent (accountId: string) {
  dataLayerPush({ event: 'accountSession', accountId })
}

export function addAccountTrackingEvent (value: string) {
  dataLayerPush({ event: 'addAccount', value })
}
