import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { getMetabaseFilterValues, getMetabaseLockedFilters } from 'requests/Settings'
import { LockedFilter } from 'types/metabase'

export const useGetLockedParams = (baseUrl: string, dashboardId: string) => {
  const [dashboardFilters, setDashboardFilters] = useState<LockedFilter[] | undefined>(undefined)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [dashboardDoesNotExist, setDashboardDoesNotExist] = useState(false)

  const getLockedParams = useCallback(async () => {
    setLoadingFilters(true)

    try {
      const lockedFilters = await getMetabaseLockedFilters(baseUrl, dashboardId)
      setDashboardFilters(lockedFilters)
    } catch {
      setDashboardDoesNotExist(true)
    } finally {
      setLoadingFilters(false)
    }
  }, [baseUrl, dashboardId])

  useEffect(() => {
    getLockedParams()
  }, [baseUrl, dashboardId, getLockedParams])

  return { dashboardFilters, loadingFilters, dashboardDoesNotExist, getLockedParams }
}

interface SelectedFilters {
  dashboardFilters?: LockedFilter[]
  selectedFilters?: Record<string, string[]>
  setSelectedValues: Dispatch<SetStateAction<string[]>>
}

export const useGetFilterValues = (baseUrl: string, dashboardId: string, { dashboardFilters, selectedFilters, setSelectedValues }: SelectedFilters) => {
  const [filterValues, setFilterValues] = useState<string[] | undefined>(undefined)
  const [loadingFilterValues, setLoadingFilterValues] = useState(false)

  const getFilterValues = useCallback(async (value: string, otherSelectedFilters?: Record<string, string[]>) => {
    if (dashboardFilters?.find(filter => filter.id === value)?.sectionId !== 'date') {
      setLoadingFilterValues(true)
      setSelectedValues(selectedFilters ? selectedFilters[value] ?? [] : [])

      const filterValues = await getMetabaseFilterValues(baseUrl, dashboardId, value, otherSelectedFilters)
      setFilterValues(filterValues.values.filter(([filterValue]) => filterValue).map(([filterValue]) => filterValue) as string[])

      setLoadingFilterValues(false)
    }
  }, [baseUrl, dashboardFilters, dashboardId, selectedFilters, setSelectedValues])

  return { filterValues, loadingFilterValues, getFilterValues }
}
