import { Button, TextArea, TextInput, ToggleInput } from '@reply-pro/component-library'
import { CHANNEL_TYPES } from 'utils/constants'
import RightModal from 'components/Modal/RightModal'
import './AutoRespondAttributes.scss'
import AttributesChannelSelector from '../AttributesChannelSelector'
import AttributesUserSelector from '../AttributesUserSelector'

const { SMS, EMAIL } = CHANNEL_TYPES
const channels = [
  { label: 'Email', value: EMAIL },
  { label: 'SMS', value: SMS }
]

interface AutoRespondModalProps {
  isAutoRespondModalOpen: boolean
  toggleAutoResponseModalOpen?: () => void
  onAttributeChange: (attribute: string, value: any) => void
  attributes: { [key: string]: any }
}

export default function AutoRespondAttributes ({ toggleAutoResponseModalOpen, isAutoRespondModalOpen, onAttributeChange, attributes }: AutoRespondModalProps) {
  const changeActionType = (value: string) => {
    onAttributeChange('channel', undefined)
    onAttributeChange('action_type', value)
    onAttributeChange('subject', undefined)
  }

  return (
    <RightModal
      showModal={isAutoRespondModalOpen}
      toggleModalOpen={toggleAutoResponseModalOpen}
      title='Auto Respond attributes'
      className='auto-respond-attributes'
    >
      <div className='auto-respond-attributes-container'>

        <div className='channel-selector'>
          <span>Channel Type</span>
          <div className='channel-selector-container'>
            {channels.map(({ label, value }) =>
              <Button
                key={value}
                className={`select-button ${attributes?.action_type === value && 'active'}`}
                onClick={() => changeActionType(value)}
              >
                {label}
              </Button>
            )}
          </div>
        </div>
        <AttributesChannelSelector channelType={attributes.action_type} onAttributeChange={onAttributeChange} value={attributes?.channel} />
        <div className='metadata-container'>
          <span>User Sender *</span>
          <AttributesUserSelector
            value={attributes?.user || ''}
            onAttributeChange={onAttributeChange}
          />
        </div>
        {
          attributes?.action_type === EMAIL &&
            <div className='metadata-container'>
              <span>Subject *</span>
              <TextInput
                value={attributes?.subject || ''}
                onChange={(e) => onAttributeChange('subject', e.target.value)}
                placeholder='Subject is required!'
                className='composer composer-subject'
              />
            </div>
        }
        <ToggleInput
          label='Mark Interaction as Open'
          checked={attributes?.is_mark_as_open}
          onChange={() => onAttributeChange('is_mark_as_open', !attributes?.is_mark_as_open)}
          readOnly
        />
        <div className='metadata-container'>
          <span>Content *</span>
          <TextArea
            value={attributes?.content || ''}
            onChange={(e) => onAttributeChange('content', e.target.value)}
            placeholder='Type here...'
            className='composer-text-area'
          />
        </div>
      </div>
      <Button
        onClick={toggleAutoResponseModalOpen}
        className='location-tagging-done'
        disabled={!attributes?.user || !attributes?.content || !attributes?.channel || (attributes?.action_type === 'email' && !attributes.subject)}
      >
        Done
      </Button>
    </RightModal>
  )
}
