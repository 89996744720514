import { Route, Routes, useParams } from 'react-router-dom'
import { setCurrentReportCollection } from 'store/settingsSlice/settingsReducers'
import { useDispatchAction } from 'utils/common/useDispatchAction'

interface SetCurrentCollectionProps {
  _param: string
}

function SetCurrentCollection (props: SetCurrentCollectionProps) {
  const params = useParams()
  useDispatchAction(setCurrentReportCollection, params[props._param])

  return null
}

export default function ManageReportsContextRouter () {
  return (
    <Routes>
      <Route path='/collection/:collection_id' element={<SetCurrentCollection _param='collection_id' />} />
      <Route
        path='/' element={
          <>
            <SetCurrentCollection _param='' />
          </>
          }
      />
    </Routes>
  )
}
