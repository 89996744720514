import { DocumentNode, useLazyQuery } from '@apollo/client'
import { FileData } from 'components/FilesAttachments/types.files'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DUMB_QUERY, FILES, FILE } from 'requests/files'
import { RootState } from 'store'
import { deleteSavedFile, filterErrorFiles, resetSuccess } from 'store/filesSlice'
import { attachFile } from 'store/filesSlice/filesDispatchers'
import { FilesData } from 'store/filesSlice/filesTypes'

export const FILE_LIMITS = {
  total_files_quantity: 10,
  total_size: 20971520 // 20mb
}

export const MEGABYTE_TO_BYTE = 1048576

export const useFileAttach = () => {
  const {
    savedFiles: { data: savedFilesData, loading: savedFilesDataLoading/*, success: savedFilesDataSuccess */ }/*
*/ // savedAndErrorFiles
  } = useSelector((store: RootState) => store.files)
  const dispatch = useDispatch()
  const savedAndErrorFiles = ' as any'
  const savedFilesDataSuccess = ''

  const attachFiles = useCallback(
    (files: File[]) => {
      const fileDataToCompareSizeLimit = savedFilesDataLoading ? savedAndErrorFiles : savedFilesData
      const totalUploadSize = files.reduce((acc, file) => acc + file.size, 0)
      const totalFilesSize = totalUploadSize + (fileDataToCompareSizeLimit as FilesData[]).reduce((acc, { size }) => acc + size, 0)
      if (totalFilesSize > FILE_LIMITS.total_size) return console.log(`Max ${FILE_LIMITS.total_size / MEGABYTE_TO_BYTE}mb total exceeded`)

      files.forEach((file, index) => {
        dispatch(attachFile({ file, attachTime: +new Date() + index }) as any)
      })
    },
    [dispatch, savedAndErrorFiles, savedFilesData, savedFilesDataLoading]
  )

  const handleRemove = (id: string) => {
    dispatch(deleteSavedFile(id))
  }

  const handleOnCollapse = () => {
    dispatch(filterErrorFiles())
  }

  useEffect(() => {
    if (!savedFilesDataLoading && savedFilesDataSuccess) {
      console.log('All your files have been attached correctly')
      dispatch(resetSuccess())
    }
  }, [dispatch, savedFilesDataLoading, savedFilesDataSuccess])

  return { attachFiles, savedFilesData, savedFilesDataLoading, savedAndErrorFiles, handleRemove, handleOnCollapse }
}

export const useGetFiles = (files: string[], sendQuery: boolean) => {
  const [query, setQuery] = useState<DocumentNode | null>(null)
  const [loadFiles, { data }] = useLazyQuery(query || DUMB_QUERY)

  useEffect(() => {
    if (sendQuery && files && Array.isArray(files) && files.length) {
      const filesQuery = FILES(files)
      setQuery(filesQuery)
    }
  }, [files, sendQuery])

  useEffect(() => {
    if (query) {
      loadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  return data
}

export const useGetFilesName = (files: string[] = []) => {
  const filesData: { [key: string]: FileData } = useGetFiles(files, files.length > 0)
  return filesData && Object.values(filesData).map((file: FileData) => ({ id: file?.id, name: file?.name }))
}

export const useOpenFile = () => {
  const [getFile, { data }] = useLazyQuery(FILE)

  const onFileGet = (id: string) => {
    getFile({ variables: { id } })
  }

  useEffect(() => {
    if (data) {
      window.open(data.file.original.publicUrl)
    }
  }, [data])

  return onFileGet
}
