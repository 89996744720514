import { WhiteLabel } from 'types/account'

export const parseWhiteLabelEmailData = (whiteLabel: WhiteLabel['whiteLabel']) => {
  return {
    topLogo: whiteLabel?.logos.email_top_logo,
    bottomLogo: whiteLabel?.logos.email_bottom_logo,
    buttonColor: whiteLabel?.colors.email_button_color,
    linkColor: whiteLabel?.colors.email_link_color,
    emailSignature: whiteLabel?.paragraphs.email_signature,
    footer: whiteLabel?.paragraphs.email_footer,
    novuIdentifier: whiteLabel?.novuIdentifier
  }
}

export const parseWhiteLabelInAppData = (whiteLabel: WhiteLabel['whiteLabel']) => {
  return {
    logo: whiteLabel?.logos.app_logo,
    loginLogo: whiteLabel?.logos.app_login_logo,
    brandColor: whiteLabel?.colors.app_brand_color,
    loginBrandColor: whiteLabel?.colors.app_login_brand_color,
    domain: whiteLabel?.domain
  }
}
