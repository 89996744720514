/* eslint-disable react/jsx-indent */
import { DistributionIcon, ExternalLink, LoadingSpinner, QRCodeIcon, RoundCheckIcon, SearchInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import './SurveyDistributionModal.scss'
import { useGetSurveyCodes } from './hooks'
import { Dispatch, SetStateAction, useState } from 'react'
import QrCodeModal from './QrCodeModal'

interface SurveyDistributionModalProps {
  id: string
  showModal: boolean
  toggleModalOpen: () => void
  surveyType: string
}
export const copyToClipboard = (text: string, setText: Dispatch<SetStateAction<string>>) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    setText(text)
    return navigator.clipboard.writeText(text)
  }
  throw new Error('The Clipboard API is not available.')
}

function SurveyDistributionModal ({ id, showModal, toggleModalOpen, surveyType }: SurveyDistributionModalProps) {
  const SURVEY_URL = `${process.env.REACT_APP_ENGAGE}/${surveyType.toLowerCase()}s/`
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data } = useGetSurveyCodes(id)
  const [text, setText] = useState('')
  const [qrInfo, setQrInfo] = useState({ title: '', url: '' })

  const toggleQrModalOpen = () => {
    setIsModalOpen(prev => !prev)
  }

  const handleQrVariables = ({ title, url }: {title: string, url: string}) => {
    setQrInfo({ title, url })
    toggleQrModalOpen()
  }
  return (
    <>
      <Modal
        showModal={showModal}
        toggleModalOpen={toggleModalOpen}
        className='distribution-modal'
        title={`${surveyType} Distribution`}
      >
        {data && data.surveyCodes
          ? <div className='distribution-modal-body'>
            {data.surveyCodes.edges.length > 6 &&
            <SearchInput />}
            <div className='distribution-container'>
              {data.surveyCodes.edges.map((surveyCode) => (
                <div className='location-code' key={surveyCode.node.code}>
                  <span className='location-name'>{surveyCode?.node?.location?.name || surveyCode.node.survey?.internalName}</span>
                  <div className='survey-code-actions'>
                    <a className='redirect-survey-link' href={`${SURVEY_URL}${surveyCode.node.code}`} target='_blank' rel='noreferrer'><ExternalLink className='redirect-to-web-icon' /></a>
                    {
                      text.includes(surveyCode.node.code) && text.includes('https')
                        ? <RoundCheckIcon className='round-check-icon copy' onClick={() => copyToClipboard('', setText)} />
                        : <DistributionIcon className='round-check-icon' onClick={() => copyToClipboard(`${SURVEY_URL}${surveyCode.node.code}`, setText)} />
                    }
                    <QRCodeIcon onClick={() => handleQrVariables({ title: `${surveyCode?.node?.location?.name || surveyCode.node.survey?.internalName}`, url: `${SURVEY_URL}${surveyCode.node.code}` })} />
                    {surveyCode.node.code === text
                      ? <span onClick={() => copyToClipboard('', setText)} className='code copy'>Copied!</span>
                      : <span onClick={() => copyToClipboard(surveyCode.node.code, setText)} className='code'>{surveyCode.node.code}</span>}
                  </div>
                </div>
              ))}
            </div>
            </div>
          : <div className='loading-spinner'><LoadingSpinner size='small' /></div>}
      </Modal>
      <QrCodeModal showModal={isModalOpen} toggleQrModalOpen={toggleQrModalOpen} title={qrInfo.title} url={qrInfo.url} />
    </>
  )
}

export default SurveyDistributionModal
