import { Button, TextInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import { useConfirmationModal } from './hooks'
import './ConfirmationModal.scss'

interface ConfirmationModalProps {
    showModal: boolean
    toggleModalOpen?: () => void
    onConfirm: () => void
    confirmText: string
    buttonText: string
    warningFirstLine?: string
    modalTitle: string
    disabled?: boolean
}

function ConfirmationModal ({ showModal, toggleModalOpen, confirmText, buttonText, warningFirstLine, modalTitle, onConfirm, disabled }: ConfirmationModalProps) {
  const { handleTextChange, textMatch } = useConfirmationModal(confirmText)
  return (
    <Modal
      showModal={showModal}
      toggleModalOpen={toggleModalOpen}
      className='confirmation-modal'
      actions={
        <Button
          disabled={!textMatch || disabled}
          backgroundColor={textMatch ? 'error' : 'lightestGray'}
          onClick={onConfirm}
        >
          {buttonText}
        </Button>
      }
      title={modalTitle}
    >
      <div className='confirmation-modal-body'>
        <p>{warningFirstLine}<br />
          Type <strong>'{confirmText}'</strong> to confirm this action.
        </p>
        <TextInput error={!textMatch} placeholder={confirmText} onChange={handleTextChange} />
      </div>
    </Modal>
  )
}

export default ConfirmationModal
