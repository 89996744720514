import { ExternalLink, ReplyProRocketLogo } from '@reply-pro/component-library'
import { Outlet, useNavigate } from 'react-router-dom'
import './AppNav.scss'

function AppNav () {
  const navigate = useNavigate()
  return (
    <aside className='app-nav'>
      <div className='logo-container'>
        <ReplyProRocketLogo className='logo' onClick={() => navigate('/settings')} />
      </div>
      <div className='context-nav'>
        <Outlet />
      </div>
      <a className='redirect-to-web' href={process.env.REACT_APP_WEB} target='_blank' rel='noreferrer'>
        <ExternalLink className='redirect-to-web-icon' /> User App
      </a>
      {/* <NavigationBar className='navigation-bar'>
        <NavigationLink
          to='/settings'
          component={NavLink}
          icon={<Settings />}
        />
      </NavigationBar> */}
      <div className='navigation-bar' />
    </aside>
  )
}

export default AppNav
