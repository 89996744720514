import { Button, Tagging } from '@reply-pro/component-library'
import './interactionTagsModal.scss'
import TagsManagerModal from 'components/TagsManagerModal'
import { ENTITIES } from '../constants'
import { useTagGroupsFilteredByEntities } from 'utils/hooks/tagGroups'
import { useOpenFile } from 'utils/hooks/files'
import { checkIfChoiceIsInConditions, checkIfTagFieldHasConditions } from 'utils/tagging/taggingConditions'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAddTag, useRemoveTag } from 'utils/tagging/useHandleTagging'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { Tag } from '../hooks'

interface InteractionTagsModalProps {
  showModalOpened: boolean
  toggleTaggingModalOpen?: () => void
  tags: Tag[]
  setTags: Dispatch<SetStateAction<Tag[]>>
}
const { INTERACTION } = ENTITIES

export default function InteractionTagsModal ({
  showModalOpened,
  toggleTaggingModalOpen,
  tags,
  setTags
}: InteractionTagsModalProps) {
  const entityId = useSelector((store: RootState) => store.surveys.surveySetup.id)
  const { filteredTagGroup, loading: loadingTag, error } = useTagGroupsFilteredByEntities([INTERACTION])
  const { add } = useAddTag(setTags)
  const { remove } = useRemoveTag(setTags)
  const onFileGet = useOpenFile()

  useEffect(() => {
    if (showModalOpened) {
      setTags([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalOpened])
  return (
    <TagsManagerModal
      title='Interaction Tags'
      showModal={showModalOpened}
      toggleModalOpen={toggleTaggingModalOpen}
    >
      <section className='tags-editor'>
        <Tagging
          entityId={entityId}
          disabled={false}
          removeTag={remove}
          addTag={add}
          tags={tags || []}
          error={error}
          isNavFolderExpanded={false}
          entities={[]}
          useConditions
          filteredTagGroup={filteredTagGroup}
          loading={loadingTag}
          checkIfTagFieldHasConditions={checkIfTagFieldHasConditions}
          checkIfChoiceIsInConditions={checkIfChoiceIsInConditions}
          openFile={onFileGet}
          showTopLine
          onSearch={() => ''}
        />
      </section>
      <Button
        onClick={toggleTaggingModalOpen}
        className='location-tagging-done'
        disabled={false}
      >
        Done
      </Button>
    </TagsManagerModal>
  )
}
