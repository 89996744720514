import { createAsyncThunk } from '@reduxjs/toolkit'
import { apollo } from 'api'
import { REPORT_COLLECTION, REPORT_COLLECTIONS, REPORT_COLLECTION_ADD, REPORT_COLLECTION_REMOVE, REPORT_PAGE_ADD, REPORT_PAGE_REMOVE, REPORT_PAGE_SET_USERS, REPORT_PAGE_UPDATE, REPORT_SECTION_ADD, REPORT_SECTION_REMOVE } from 'requests/Settings'
import { RootState } from 'store'
import { reportPageAddPayload, reportPageEditPayload, reportUsersEditPayload } from './settingsTypes'

export const retrieveReportCollectionsList = createAsyncThunk(
  'settings/retrieveReportCollectionsList',
  async (_, thunkApi) => {
    const response = await apollo.query({ query: REPORT_COLLECTIONS })
    return response.data
  }
)

export const retrieveReportCollectionDetail = createAsyncThunk(
  'settings/retrieveReportCollectionDetail',
  async (id:string) => {
    const response = await apollo.query({
      query: REPORT_COLLECTION,
      variables: {
        id
      }
    })
    return response.data
  }
)

export const setCurrentReportCollection = createAsyncThunk(
  'settings/setCurrentReportCollection',
  async (payload: string, thunkApi) => {
    await thunkApi.dispatch(retrieveReportCollectionDetail(payload))
  }
)

export const addReportCollection = createAsyncThunk(
  'settings/addReportCollection',
  async (name: string, thunkApi) => {
    await apollo.mutate({
      mutation: REPORT_COLLECTION_ADD,
      variables: {
        input: {
          name,
          order: 0
        }
      }
    })
    await thunkApi.dispatch(retrieveReportCollectionsList())
  }
)

export const addReportPage = createAsyncThunk(
  'settings/addReportPage',
  async ({ reportSectionId, link, remoteId, name, isForAllUsers, authorized, parameters }: reportPageAddPayload, thunkApi) => {
    const currentState = thunkApi.getState() as RootState

    let users: string[] = []
    let userGroups: string[] = []
    if (!isForAllUsers) {
      users = Object.keys(currentState.settings.reportPageUsers)
      userGroups = Object.keys(currentState.settings.reportPageUserGroups)
    }

    await apollo.mutate({
      mutation: REPORT_PAGE_ADD,
      variables: {
        input: {
          reportSection: reportSectionId,
          name,
          users,
          userGroups,
          link,
          remoteId,
          order: 0,
          authorized,
          parameters: JSON.stringify(parameters)
        }
      }
    })

    const reportCollectionId = currentState.settings.reportCollectionDetail?.id
    if (reportCollectionId) {
      await thunkApi.dispatch(retrieveReportCollectionDetail(reportCollectionId))
    }
  }
)

export const addReportSection = createAsyncThunk(
  'settings/addReportSection',
  async (name: string, thunkApi) => {
    const currentState = thunkApi.getState() as RootState
    const reportCollectionId = currentState.settings.reportCollectionDetail?.id
    if (!reportCollectionId) {
      throw new Error('Internal server error')
    }
    await apollo.mutate({
      mutation: REPORT_SECTION_ADD,
      variables: {
        input: {
          name,
          order: 0,
          reportCollection: reportCollectionId
        }
      }
    })
    await thunkApi.dispatch(retrieveReportCollectionDetail(reportCollectionId))
  }
)

export const removeReportCollection = createAsyncThunk(
  'settings/removeReportCollection',
  async (id: string, thunkApi) => {
    await apollo.mutate({
      mutation: REPORT_COLLECTION_REMOVE,
      variables: {
        input: {
          reportCollection: id
        }
      }
    })
    await thunkApi.dispatch(retrieveReportCollectionsList())
  }
)

export const removeReportSection = createAsyncThunk(
  'settings/removeReportSection',
  async (id: string, thunkApi) => {
    await apollo.mutate({
      mutation: REPORT_SECTION_REMOVE,
      variables: {
        input: {
          reportSection: id
        }
      }
    })
    const currentState = thunkApi.getState() as RootState
    const reportCollectionId = currentState.settings.reportCollectionDetail?.id
    if (reportCollectionId) {
      await thunkApi.dispatch(retrieveReportCollectionDetail(reportCollectionId))
    }
  }
)

export const removeReportPage = createAsyncThunk(
  'settings/removeReportPage',
  async (id: string, thunkApi) => {
    await apollo.mutate({
      mutation: REPORT_PAGE_REMOVE,
      variables: {
        input: {
          reportPage: id
        }
      }
    })
    const currentState = thunkApi.getState() as RootState
    const reportCollectionId = currentState.settings.reportCollectionDetail?.id
    if (reportCollectionId) {
      await thunkApi.dispatch(retrieveReportCollectionDetail(reportCollectionId))
    }
  }
)

export const editReportPageUsers = createAsyncThunk(
  'settings/editReportPageUsers',
  async ({ reportPageId, isForAllUsers }: reportUsersEditPayload, thunkApi) => {
    let users: string[] = []
    if (!isForAllUsers) {
      const currentState = thunkApi.getState() as RootState
      users = Object.keys(currentState.settings.reportPageUsers)
    }
    await apollo.mutate({
      mutation: REPORT_PAGE_SET_USERS,
      variables: {
        input: {
          reportPage: reportPageId,
          users
        }
      }
    })
    const currentState = thunkApi.getState() as RootState
    const reportCollectionId = currentState.settings.reportCollectionDetail?.id
    if (reportCollectionId) {
      await thunkApi.dispatch(retrieveReportCollectionDetail(reportCollectionId))
    }
  }
)

export const editReportPage = createAsyncThunk(
  'settings/editReportPage',
  async ({ reportPageId, isForAllUsers, name, link, remoteId, authorized, parameters }: reportPageEditPayload, thunkApi) => {
    const currentState = thunkApi.getState() as RootState

    let users: string[] = []
    let userGroups: string[] = []
    if (!isForAllUsers) {
      const currentState = thunkApi.getState() as RootState
      users = Object.keys(currentState.settings.reportPageUsers)
      userGroups = Object.keys(currentState.settings.reportPageUserGroups)
    }

    const response = await apollo.mutate({
      mutation: REPORT_PAGE_UPDATE,
      variables: {
        input: {
          reportPage: reportPageId,
          name,
          users,
          userGroups,
          link,
          remoteId,
          order: 0,
          authorized,
          parameters: JSON.stringify(parameters)
        }
      }
    })

    if (response.data.reportPageUpdate.success) {
      const reportCollectionId = currentState.settings.reportCollectionDetail?.id
      if (reportCollectionId) {
        await thunkApi.dispatch(retrieveReportCollectionDetail(reportCollectionId))
      }
    }
  })
