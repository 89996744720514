import { Button, LoadingSpinner, X } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import './PostSurveyActionsModal.scss'
import AddPostSurveyActionsModal from './AddPostSurveyActionsModal'
import { usePostSurveyActions, useRemovePostSurveyAction } from './hooks'
import { PostSurveyActionsModalProps, SurveyPostActionEdge } from './PostSurveyActionsModal.types'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'

function PostSurveyActionsModal ({ showModal, toggleModalOpen, id, surveyType }: PostSurveyActionsModalProps) {
  const { isAddActionModalOpen, toggleAddActionModal, data, loading, getQuestion, getAnswer, getPostActionName, refetch } = usePostSurveyActions(id)
  const { handleRemovePostSurveyAction, openConfirmationModal, closeConfirmationModal, isConfirmationModalOpen, loading: isRemoving } = useRemovePostSurveyAction(refetch)
  return (
    <>
      <Modal
        className='post-survey-actions-modal'
        title={`Post ${surveyType} Actions`}
        showModal={showModal}
        toggleModalOpen={toggleModalOpen}
        actions={<Button onClick={toggleAddActionModal}>Add New Action</Button>}
      >
        <div className='post-survey-actions-container'>
          {loading
            ? (<div className='loading-container'><LoadingSpinner size='small' /></div>)
            : (!loading && data && data?.surveyPostActions?.edges?.length > 0
                ? data.surveyPostActions?.edges.map((postAction: SurveyPostActionEdge) => (
                  <div key={postAction.node.id} className='post-survey-action'>
                    <div className='post-actions-details'>
                      <span className='question'>{getQuestion(postAction.node.rule) || 'The person has submitted the last question'}</span>
                      <span className='answer'>
                        {getQuestion(postAction.node.rule) !== null ? 'Answer: ' + (getAnswer(postAction.node.rule) || '') : ''}
                      </span>
                      <span className='post-action'>
                        {getPostActionName(postAction.node.postAction)}
                      </span>
                    </div>
                    <div className='action-remove-container'><X onClick={() => openConfirmationModal(postAction.node.id)} /></div>
                  </div>
                ))
                : <div className='no-post-survey-actions'>There are no Post {surveyType} Actions yet.</div>)}
        </div>
      </Modal>
      {isAddActionModalOpen && <AddPostSurveyActionsModal showModal={isAddActionModalOpen} toggleModalOpen={toggleAddActionModal} id={id} refetch={refetch} />}
      {isConfirmationModalOpen &&
        <ConfirmationModal
          confirmText='REMOVE'
          warningFirstLine='You are about to remove a Post Survey Action'
          onConfirm={handleRemovePostSurveyAction}
          showModal={isConfirmationModalOpen}
          toggleModalOpen={closeConfirmationModal}
          buttonText={isRemoving ? 'Removing' : 'Remove'}
          modalTitle='Remove Post Survey Action'
          disabled={isRemoving}
        />}
    </>
  )
}

export default PostSurveyActionsModal
