import { LoadingSpinner, NavItem, SettingsPageStructure, SettingsStructureRightFilters } from '@reply-pro/component-library'
import './SurveyList.scss'
import SurveyActions from './SurveyActions'
import { SurveyNode } from 'store/surveysSlice/surveyTypes'
import SurveysManagerHeader from '../SurveysManagerHeader'
import { Dispatch, FunctionComponent, SVGProps, SetStateAction, useEffect } from 'react'
import SurveyStatus from '../constants'

const { ENABLED } = SurveyStatus

interface ManageSurveysListProps {
  surveyType: string
  status: SurveyStatus[keyof SurveyStatus]
  url: string
  title: string
  icon: FunctionComponent<SVGProps<SVGSVGElement> & {
      title?: string | undefined;
  }>
  buttonTextContent: string
  searchPlaceholder: string
  setStatus: Dispatch<SetStateAction<SurveyStatus[keyof SurveyStatus]>>
  data?: {edges: SurveyNode[]}
  loading?: boolean
}

function ManageSurveysList ({ surveyType, status, url, title, icon, buttonTextContent, data, loading, setStatus, searchPlaceholder }: ManageSurveysListProps) {
  useEffect(() => {
    setStatus(status)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingsPageStructure
      settingsHeader={<SurveysManagerHeader url={url} title={title} icon={icon} buttonTextContent={buttonTextContent} />}
      subHeaderTitles={{
        mainSubTitle:
  <SettingsStructureRightFilters
    searchPlaceholder={searchPlaceholder}
  />
      }}
    >
      <div className='surveys-list'>
        {loading
          ? <div className='surveys-container-center'><LoadingSpinner size='small' /></div>
          : (data && data.edges.length > 0
              ? (
                  data.edges.map((survey, index) => (
                    <NavItem className='survey-container' key={index + '-' + survey.node.id}>
                      <div className='survey'>
                        <div className='name-and-info-container'>
                          <div className='name'>{survey.node.internalName}</div>
                        </div>
                        <div className='survey-actions'>
                          <SurveyActions id={survey.node.id} isActive={survey.node.isActive} surveyType={surveyType} surveyName={survey.node.internalName} />
                        </div>
                      </div>
                    </NavItem>
                  ))
                )
              : (
                <div className='surveys-container-center'>No {status === ENABLED ? 'active' : 'inactive'} {surveyType}s.</div>
                )
            )}
      </div>
    </SettingsPageStructure>
  )
}

export default ManageSurveysList
