import { useFetchModalUserList } from 'components/UserEditor/UserSelector/hooks'
import './addUsersModal.scss'
import { Dispatch, SetStateAction, createRef } from 'react'
import { Button, MultiSelectInput, SelectInput, X } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import { initialUserToAssociate, roles } from './utils'
import { ASSOCIATE_USER_TO_ACCOUNT } from 'requests/Settings'
import { useMutation } from '@apollo/client'
interface AddUsersModalProps {
  formData: {
    type: string
    userId: string
    userName: string
  }[]
  handleInputChange: (e: any) => void
  setUsersAdded: Dispatch<SetStateAction<{
    type: string;
    userId: string;
    userName: string;
  }[]>>
  setIsModalOpen: (value: SetStateAction<boolean>) => void
  isModalOpen: boolean
  refetch: () => void
}
export default function AddUsersModal ({ refetch, isModalOpen, setIsModalOpen, formData, handleInputChange, setUsersAdded }: AddUsersModalProps) {
  const { users, searchUser } = useFetchModalUserList(true)
  const [addUserRole] = useMutation(ASSOCIATE_USER_TO_ACCOUNT)

  const handleSelectRoleButtonClick = () => {
    formData.forEach((user) => user.type && addUserRole({ variables: { input: { user: user?.userId, type: user?.type?.toLowerCase() } } }))
    setUsersAdded([initialUserToAssociate])
    setIsModalOpen(false)
    refetch()
  }

  const handleInputRoleChange = (e: any, index: number) => {
    const updatedFormData = [...formData]
    updatedFormData[index].type = e.target.value
    setUsersAdded(updatedFormData as any)
  }

  const handleDeleteUser = (index: number) => {
    const updatedFormData = [...formData]
    updatedFormData[index] = initialUserToAssociate
    setUsersAdded(updatedFormData)
  }

  const input = createRef()
  return (
    <Modal
      actions={
        <Button
          onClick={handleSelectRoleButtonClick}
          disabled={(formData.some((user) => ((!user?.type && user.userId) || (user?.userName && !user.userId))))}
        >Done
        </Button>
      }
      title='ADD USERS'
      toggleModalOpen={() => setIsModalOpen(false)}
      showModal={isModalOpen}
      className='associate-user-whit-account'
    >
      <div className='add-users-form-container'>
        <div className='add-users-container input-container'>
          <MultiSelectInput
            className='add-user-select-input'
            placeholder='Select User'
            onInputChange={searchUser}
            onAdd={(e) => handleInputChange(e)}
            input={input}
          >
            {users?.users.edges.map((user, index) =>
              <option
                value={[user.node.id, user.node.firstName || user.node.email.split('@')[0].split('.')[0]]}
                key={index + '-' + user.node.id}
              >
                <div className='single-user-select'>
                  <span>
                    {user.node.firstName ? user.node.firstName : ''}
                  </span>
                  <span>
                    {user.node.lastName} {user.node.email}
                  </span>
                </div>
              </option>)}
            {/* eslint-disable-next-line react/jsx-closing-tag-location */}
          </MultiSelectInput>
        </div>
        <div className='change-role-container input-container'>
          <div className='user-container'>
            {formData && formData.map((user, index) =>
              user.userName &&
                <div className='user-wrap' key={user.userId}>
                  <div className='user'>
                    <div className='user-name'>{user.userName}</div>
                    <SelectInput
                      className='change-role'
                      value={`Role: ${user.type}`}
                      onChange={(e) => handleInputRoleChange(e, index)}
                    >
                      {Object.values(roles).map((option, index) => <option value={option} key={index + 'option'}>{option}</option>)}
                      {/* eslint-disable-next-line react/jsx-closing-tag-location */}
                    </SelectInput>
                  </div>
                  <X className='delete-button' onClick={() => handleDeleteUser(index)} />
                </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
