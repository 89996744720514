import { SelectInput } from '@reply-pro/component-library'
import { createRef } from 'react'
import { useGetChannels } from 'utils/hooks/channels'
import { AttributeChangeType } from '../constants'

interface AttributesChannelSelectorProps extends AttributeChangeType {
    channelType?: string
    value: any
}

function AttributesChannelSelector ({ channelType, value, onAttributeChange }: AttributesChannelSelectorProps) {
  const { data: channelsData, setQueryParam: setChannelsQueryParam } = useGetChannels(channelType || '')
  const channelInputRef = createRef()
  const handleSelectChannel = ({ target }: { target: { value: string } }) => {
    onAttributeChange('channel', target.value)
  }
  return (
    <div className='metadata-container'>
      <span>Select Channel *</span>
      <SelectInput
        onChange={handleSelectChannel}
        input={channelInputRef}
        onInputChange={(e: { target: { value: string } }) => setChannelsQueryParam(e.target.value)}
        value={value}
        className='selector'
      >
        {
            channelsData?.channels.edges.map(({ node: channel }: any) =>
              <option
                value={channel.uuid}
                className='from-container close'
                key={channel.uuid}
              >
                {channel.attributes.name} {channel.attributes.email}
              </option>
            )
          }
      </SelectInput>
    </div>
  )
}

export default AttributesChannelSelector
