import { useMutation } from '@apollo/client'
import { useState, ChangeEvent } from 'react'
import { SURVEY_EDIT } from 'requests/Surveys'

export const useSurveyThemeManager = (id: string) => {
  const [changeTheme, { loading }] = useMutation(SURVEY_EDIT)

  const [jsonContent, setJsonContent] = useState<object | null>(null)
  const [fileName, setFileName] = useState<string | null>(null)

  const handleFileUpload = (file: File) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      try {
        const content = event.target?.result as string
        const parsedContent = JSON.parse(content)
        setJsonContent(parsedContent)
      } catch (error) {
        alert('Error reading or parsing file')
      }
    }

    reader.readAsText(file)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]
      setFileName(file.name)
      if (file.type !== 'application/json') {
        alert('Uploaded file is not a JSON file')
        return
      }
      handleFileUpload(file)
    }
  }

  const handleChangeTheme = async () => {
    const themeData = JSON.stringify({ id: 'custom', theme_json: jsonContent })
    await changeTheme({ variables: { input: { id, theme: themeData } } })
    window.location.reload()
  }

  return { fileName, jsonContent, handleFileChange, handleChangeTheme, loading }
}
