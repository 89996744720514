import { ChangeEvent, useState } from 'react'
import { initialUserToAssociate } from './AddUsersModal/utils'
import { newUserInitialState } from './AddNewUserModal/utils'

export const useHandleAddUserInput = () => {
  const [formData, setFormData] = useState(newUserInitialState)
  const [isAddNewUserModalOpen, setIsAddNewUserModalOpen] = useState(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string) => {
    setFormData((prevAttributes) => ({
      ...prevAttributes,
      [`${attribute}`]: e.target.value
    }))
  }

  return { formData, handleInputChange, setFormData, isAddNewUserModalOpen, setIsAddNewUserModalOpen }
}

export const useHandleSelectedUsers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [usersAdded, setUsersAdded] = useState([initialUserToAssociate])

  const handleUsersSelected = (e: any) => {
    setUsersAdded((prevData) => [...prevData, { type: '', userId: e[0], userName: e[1] }])
  }

  return { usersAdded, handleUsersSelected, setUsersAdded, isModalOpen, setIsModalOpen }
}
