import _ from 'lodash'
import { isObjectEmpty } from './../common/objectHandlingFunctions'

interface checkTagFieldHasConditionsParams {
  conditions?: any
  parentTagFieldUuid: string
  tagFieldUuid: string
}
interface conditionFunctionsParams {
  conditions?: any
  choice?: string
  parentTagFieldUuid: string
  tagFieldUuid: string
  parentTagFieldChoice: string
}

const addChoiceConditions = ({ conditions, choice, tagFieldUuid, parentTagFieldUuid, parentTagFieldChoice }: conditionFunctionsParams) => {
  const newDependencies = _.cloneDeep(conditions)

  if (isObjectEmpty(newDependencies) || !newDependencies[tagFieldUuid]) {
    return {
      ...newDependencies,
      [tagFieldUuid]: {
        dependency: {
          [parentTagFieldUuid]: {
            [parentTagFieldChoice]: [choice]
          }
        }
      }
    }
  }
  if (!newDependencies[tagFieldUuid].dependency) {
    newDependencies[tagFieldUuid].dependency = { [parentTagFieldUuid]: { [parentTagFieldChoice]: [choice] } }
    return newDependencies
  }

  const { dependency } = newDependencies[tagFieldUuid]

  if (!dependency[parentTagFieldUuid]) {
    newDependencies[tagFieldUuid].dependency[parentTagFieldUuid] = { [parentTagFieldChoice]: [choice] }
    return newDependencies
  }
  if (!dependency[parentTagFieldUuid][parentTagFieldChoice]) {
    newDependencies[tagFieldUuid].dependency[parentTagFieldUuid][parentTagFieldChoice] = [choice]
    return newDependencies
  }

  const currentNewDependency = dependency[parentTagFieldUuid][parentTagFieldChoice]
  dependency[parentTagFieldUuid][parentTagFieldChoice] = !currentNewDependency.includes(choice) ? [...currentNewDependency, choice] : currentNewDependency

  return newDependencies
}

const removeChoiceFromConditions = ({ conditions, parentTagFieldUuid, parentTagFieldChoice, tagFieldUuid, choice }: conditionFunctionsParams) => {
  const newDependencies = _.cloneDeep(conditions)
  if (isObjectEmpty(newDependencies)) {
    return newDependencies
  }

  const { dependency } = newDependencies[tagFieldUuid]
  const currentNewDependency = dependency[parentTagFieldUuid][parentTagFieldChoice]
  dependency[parentTagFieldUuid][parentTagFieldChoice] = currentNewDependency.filter((currentChoice: string) => currentChoice !== choice)

  return newDependencies
}

const checkIfTagFieldHasConditions = ({ conditions, parentTagFieldUuid, tagFieldUuid }: checkTagFieldHasConditionsParams) => {
  if (
    !conditions ||
    isObjectEmpty(conditions) ||
    !conditions[tagFieldUuid] ||
    !conditions[tagFieldUuid].dependency ||
    !conditions[tagFieldUuid].dependency[parentTagFieldUuid] ||
    !conditions[tagFieldUuid].dependency[parentTagFieldUuid] ||
    isObjectEmpty(conditions[tagFieldUuid].dependency[parentTagFieldUuid])
  ) {
    return false
  }
  return true
}

const checkIfChoiceIsInConditions = ({ conditions, parentTagFieldUuid, tagFieldUuid, choice, parentTagFieldChoice }: conditionFunctionsParams) => {
  if (!checkIfTagFieldHasConditions({ conditions, parentTagFieldUuid, tagFieldUuid })) {
    throw new Error('tagField has no conditions')
  }
  if (!conditions[tagFieldUuid]?.dependency[parentTagFieldUuid][parentTagFieldChoice]) {
    return true
  }
  return conditions[tagFieldUuid].dependency[parentTagFieldUuid][parentTagFieldChoice].includes(choice)
}

export { addChoiceConditions, removeChoiceFromConditions, checkIfChoiceIsInConditions, checkIfTagFieldHasConditions }
