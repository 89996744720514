import { RoundCheckIcon } from '@reply-pro/component-library'
import { useRef } from 'react'
import Tooltip from 'components/Tooltip/Tooltip'
import { user, userGroup } from 'types/user'
import classNames from 'classnames'

interface UserOptionProps {
  selectedUsers?: user[]
  userId: string
  firstName?: string
  lastName?: string
  email?: string
}

export function UserOption ({ selectedUsers, userId, firstName, lastName, email }: UserOptionProps) {
  const selectedOption = useRef(null)

  return (
    <div className='user-option'>
      <Tooltip anchor={selectedOption}>
        User added already
      </Tooltip>
      <strong className='user-name'>{`${firstName} ${lastName}`.trim() === '' ? 'UNNAMED USER' : `${firstName} ${lastName}`}</strong>
      <span className='user-attribute'>{email}</span>
      <div
        ref={selectedOption}
        className={classNames('user-group-check-icon', { show: selectedUsers?.some(user => user.id === userId) })}
      >
        <RoundCheckIcon className='round-icon' />
      </div>
    </div>
  )
}

interface UserGroupOptionProps {
  selectedUserGroups?: userGroup[]
  userGroupId?: string
  name?: string
  description?: string
}

export function UserGroupOption ({ selectedUserGroups, userGroupId, name, description }: UserGroupOptionProps) {
  const selectedOption = useRef(null)

  return (
    <div className='user-option'>
      <Tooltip anchor={selectedOption}>
        User Group added already
      </Tooltip>
      <strong className='user-name'>{name}</strong>
      <p className='user-group-description'>{description?.trim() ? description : '- No description for this User Group -'}</p>
      <div
        ref={selectedOption}
        className={classNames('user-group-check-icon', { show: selectedUserGroups?.some(userGroup => userGroup.id === userGroupId) })}
      >
        <RoundCheckIcon className='round-icon' />
      </div>
    </div>
  )
}
