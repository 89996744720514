import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { setFeatureMaintenance } from 'store/accountsSlice/accountsDispatchers'
import { MaintenanceDetails } from 'store/accountsSlice/accountsTypes'
import { capitalizeFirstLetter } from 'utils/common'

export const useMaintenance = () => {
  const [isModalOpen, setIsModalOpen] = useState<{feature: string, type: string} | undefined>(undefined)
  const featureMaintenance = useSelector((state: RootState) => state.accounts.featureMaintenance)
  const [maintenanceDetails, setMaintenanceDetails] = useState<MaintenanceDetails | undefined>(undefined)

  const dispatch: AppDispatch = useDispatch()

  const openMaintenanceModal = (feature: string, type: string) => {
    setIsModalOpen({ feature, type })
    const details = featureMaintenance[feature as keyof typeof featureMaintenance]
    if (details && typeof details === 'object') {
      setMaintenanceDetails(details)
    }
  }

  const closeMaintenanceModal = () => {
    setIsModalOpen(undefined)
    setMaintenanceDetails(undefined)
  }

  const turnOnMaintenance = async (feature: string) => {
    await dispatch(setFeatureMaintenance({ maintenance: true, feature: capitalizeFirstLetter(feature), message: maintenanceDetails?.message, status: maintenanceDetails?.status || 'ongoing' }))
    closeMaintenanceModal()
  }

  const turnOffMaintenance = (feature: string) => {
    dispatch(setFeatureMaintenance({ maintenance: false, feature: capitalizeFirstLetter(feature) }))
  }

  const getIsMaintenance = (details: boolean | MaintenanceDetails) => (details as MaintenanceDetails).isMaintenance === true

  const featuresForMaintenanceArray = Object.keys(featureMaintenance).filter(key => key !== 'loading')

  return { featureMaintenance, featuresForMaintenanceArray, isModalOpen, openMaintenanceModal, closeMaintenanceModal, maintenanceDetails, getIsMaintenance, turnOnMaintenance, setMaintenanceDetails, turnOffMaintenance }
}
