import { useState, useCallback } from 'react'
import { user } from 'types/user'

const useUserStates = () => {
  const [editingUser, setEditingUser] = useState<user>()
  const [modals, setModals] = useState({
    isPasswordManagerOpen: false,
    isPermissionsModalOpen: false
  })

  const handlePasswordManager = useCallback((user: user) => {
    setEditingUser(user)
    setModals((prevModals) => ({
      ...prevModals,
      isPasswordManagerOpen: true
    }))
  }, [])

  const handleUserPermissions = useCallback((user: user) => {
    setEditingUser(user)
    setModals((prevModals) => ({
      ...prevModals,
      isPermissionsModalOpen: true
    }))
  }, [])

  return {
    editingUser,
    modals,
    setModals,
    handlePasswordManager,
    handleUserPermissions
  }
}

export default useUserStates
