import { Button } from '@reply-pro/component-library'
import { formConfig, newAccountInitialState } from './utils'
import { ChangeEvent, SetStateAction, Dispatch, useEffect, useState, useMemo } from 'react'
import Modal from 'components/Modal'
import { formDataType, useHandleAddAccount } from './hooks'
import FormComponents from './FormComponents'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import FeaturesSetter from 'components/FeaturesSetter'
import { useHandleSetAccountFeatures } from '../hooks'
import './addNewAccountModal.scss'

interface AddUserModalProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  handleInputChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string, defaultValue?: string) => void
  isAddNewAccountModalOpen: boolean
  setFormData: Dispatch<SetStateAction<formDataType>>
  setIsAddNewAccountModalOpen: (value: SetStateAction<boolean>) => void
  formData: formDataType
  setAccountSelected: Dispatch<SetStateAction<string | undefined>>
}

export default function AddNewAccountModal ({ setAccountSelected, setIsModalOpen, setIsAddNewAccountModalOpen, formData, handleInputChange, isAddNewAccountModalOpen, setFormData }: AddUserModalProps) {
  const defaultTimeZone = useSelector((state: RootState) => state.metadata.user.timeZone)
  const { accountId, accounts } = useSelector((state: RootState) => state.accounts)

  const currentAccount = useMemo(() => accounts.data?.accounts.edges.find(({ node }) => node.id === accountId), [accountId, accounts.data?.accounts.edges])

  const [showAccountSetFeaturesModal, setShowAccountSetFeaturesModal] = useState(false)

  const { handleAddAccount, loading } = useHandleAddAccount({ setIsAddNewAccountModalOpen, setAccountSelected, setFormData, formData, setIsModalOpen, setShowAccountSetFeaturesModal })
  const { handleSetAccountFeatures, handleDone } = useHandleSetAccountFeatures(setShowAccountSetFeaturesModal)

  useEffect(() => {
    setFormData({ ...newAccountInitialState, timeZone: defaultTimeZone || '' })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal
        actions={
          <Button
            onClick={handleAddAccount}
            disabled={loading || Object.values(formData).some((value) => {
              return (
                ((typeof value === 'string' && value.length < 3) || (Array.isArray(value) && value.length === 0)))
            }
            )} small
          >Add New Account
          </Button>
        }
        title='ADD NEW ACCOUNT'
        toggleModalOpen={() => setIsAddNewAccountModalOpen(false)}
        showModal={isAddNewAccountModalOpen}
      >
        <div className='add-user-form'>
          {Object.keys(formConfig).map((fieldName) => {
            return (
              <FormComponents key={fieldName} handleInputChange={handleInputChange} formData={formData} fieldName={fieldName} />
            )
          })}
        </div>
      </Modal>

      {showAccountSetFeaturesModal &&
        <Modal
          actions={<Button onClick={handleDone}>Done</Button>}
          title={`Set ${currentAccount?.node.name} Features`}
          toggleModalOpen={() => setShowAccountSetFeaturesModal(false)}
          showModal={showAccountSetFeaturesModal}
          className='account-set-features'
        >
          <p>The following Features are available for this Account.<br />Use the buttons below to enable or disable them as needed.</p>
          <FeaturesSetter entity='Account' features={currentAccount?.node.features} setFeaturesAction={(features) => handleSetAccountFeatures({ accountId, features })} />
          <p>Don't worry, you can always manage these settings later in the 'Manage Accounts' section.</p>
        </Modal>}
    </>
  )
}
