import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import './NotFoundPage.scss'

const NotFoundPage = ({ routeToNavigate }:{routeToNavigate: string}) => {
  const [counter, setCounter] = useState(5)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCounter((currentCount) => currentCount - 1)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [counter])

  if (counter === 0) {
    return <Navigate to={`/${routeToNavigate}/`} />
  }
  return (
    <div className='not-found'>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <p>You will be redirected to <span>{routeToNavigate}</span> in <span>{counter}</span> seconds.</p>
    </div>
  )
}

export default NotFoundPage
