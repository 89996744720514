(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("survey-creator-core"));
	else if(typeof define === 'function' && define.amd)
		define("SurveyCreatorLocales", ["survey-creator-core"], factory);
	else if(typeof exports === 'object')
		exports["SurveyCreatorLocales"] = factory(require("survey-creator-core"));
	else
		root["SurveyCreatorLocales"] = factory(root["SurveyCreatorCore"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_survey_creator_core__) {
return 