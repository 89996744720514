import { Badge, ToggleInput, X } from '@reply-pro/component-library'
import UserSelector from './UserSelector'
import { useClearAllSelectedUsers } from './hooks'
import { user, userGroup } from 'types/user'
import './userEditor.scss'

interface UserEditorProps {
  isForAllUsers: boolean
  setIsForAllUsers: (setter: (prev: boolean) => boolean) => void
  selectedUsers: user[]
  addUserAction: (user: user) => void
  removeUserAction: (id: string) => void
  selectedUserGroups: userGroup[]
  addUserGroupAction: (userGroup: userGroup) => void
  removeUserGroupAction: (id: string) => void
}

export default function UserEditor ({ isForAllUsers, setIsForAllUsers, selectedUsers, addUserAction, removeUserAction, selectedUserGroups, addUserGroupAction, removeUserGroupAction }: UserEditorProps) {
  const clearAllSelectedUsers = useClearAllSelectedUsers()

  return (
    <>
      <div className='users-header'>
        <div className='title'>
          <h3>Permissions</h3>
          <div className='count'>
            <Badge backgroundColor='mainLight'>{isForAllUsers ? 'All' : Object.keys(selectedUsers).length + Object.keys(selectedUserGroups).length}</Badge>
            {!isForAllUsers && Object.keys(selectedUsers).length > 0 &&
              <div className='clear' onClick={clearAllSelectedUsers}>
                <X /> Clear
              </div>}
          </div>
        </div>
        <ToggleInput
          checked={isForAllUsers}
          label='All Users'
          onChange={() => setIsForAllUsers(prev => !prev)}
        />
      </div>
      <p>Add users that you’d like to have access to this report.</p>
      <UserSelector
        isForAllUsers={isForAllUsers}
        selectedUsers={selectedUsers}
        addUserAction={addUserAction}
        removeUserAction={removeUserAction}
        selectedUserGroups={selectedUserGroups}
        addUserGroupAction={addUserGroupAction}
        removeUserGroupAction={removeUserGroupAction}
      />
    </>
  )
}
