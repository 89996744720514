import { SettingsPageStructure, SettingsStructureRightFilters } from '@reply-pro/component-library'
import { useFetchPageUserList } from 'components/UserEditor/UserSelector/hooks'
import './manageUsers.scss'
import AddNewUserModal from './AddNewUserModal'

import { useHandleAddUserInput, useHandleSelectedUsers } from './hooks'
import AddUsersModal from './AddUsersModal/AddUsersModal'
import UserList from './User'
import ManageUsersHeader from './ManageUsersHeader'

export default function ManageUsers ({ viewingActive }: { viewingActive: boolean }) {
  const { searchUser, users, loading, getUsers } = useFetchPageUserList({ viewingActive })
  const { formData, handleInputChange, isAddNewUserModalOpen, setIsAddNewUserModalOpen } = useHandleAddUserInput()
  const { usersAdded, handleUsersSelected, setUsersAdded, isModalOpen, setIsModalOpen } = useHandleSelectedUsers()

  return (
    <>
      <SettingsPageStructure
        settingsHeader={<ManageUsersHeader setIsAddNewUserModalOpen={setIsAddNewUserModalOpen} setIsModalOpen={setIsModalOpen} />}
        subHeaderTitles={{
          mainSubTitle:
            // eslint-disable-next-line react/jsx-indent
            <SettingsStructureRightFilters
              searchPlaceholder='Search Users'
              updateQuery={searchUser}
            />
        }}
      >
        <UserList users={users} loading={loading} viewingActive={viewingActive} />
      </SettingsPageStructure>
      {isAddNewUserModalOpen &&
        <AddNewUserModal
          formData={formData}
          handleInputChange={handleInputChange}
          isAddNewUserModalOpen={isAddNewUserModalOpen}
          setIsAddNewUserModalOpen={setIsAddNewUserModalOpen}
          refetch={getUsers}
        />}
      {isModalOpen &&
        <AddUsersModal
          formData={usersAdded}
          handleInputChange={handleUsersSelected}
          isModalOpen={isModalOpen}
          setUsersAdded={setUsersAdded}
          setIsModalOpen={setIsModalOpen}
          refetch={getUsers}
        />}
    </>
  )
}
