/* eslint-disable operator-linebreak */
import { useRef } from 'react'
import { X } from '@reply-pro/component-library'
import { user, userGroup } from 'types/user'
import './selectedUser.scss'

  interface SelectedUsersProps {
    isForAllUsers: boolean
    users?: user[]
    removeUserAction: (id: string) => void
    userGroups?: userGroup[]
    removeUserGroupAction?: (id: string) => void
  }

export default function SelectedUsers ({ users, isForAllUsers, removeUserAction, userGroups, removeUserGroupAction }: SelectedUsersProps) {
  const noUsersSelected = useRef(null)

  return (
    <div className='selected-users'>
      {(!users || users.length === 0) && (!userGroups || userGroups.length === 0) && !isForAllUsers ?
        <div ref={noUsersSelected} className='no-items-found'>No users selected yet. Use the search bar above</div> :
        <div className='user-list'>
          {!isForAllUsers && userGroups && userGroups.map((userGroup) =>
            <div className='user-user-group' key={userGroup.id}>
              <span><strong>{userGroup.name}</strong></span>
              <span>User Group</span>
              <X onClick={() => removeUserGroupAction?.(userGroup.id)} />
            </div>)}
          {!isForAllUsers && users && users.map((user) =>
            <div className='user-user-group' key={user.id}>
              <span><strong>{`${user.firstName} ${user.lastName}`.trim() === '' ? 'UNNAMED USER' : `${user.firstName} ${user.lastName}`}</strong></span>
              <span>{user.email}</span>
              <X onClick={() => removeUserAction(user.id)} />
            </div>)}
        </div>}
    </div>
  )
}
