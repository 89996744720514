import { createAsyncThunk } from '@reduxjs/toolkit'
import { apollo } from 'api'
import { HIERARCHY_GROUPS, LOCATIONS, LOCATION_GROUPS } from 'requests/Accounts/locations'

import { HierarchyGroupsData, LocationGroupsData, SurveyLocations } from 'types/location'
import { setSelectedLocations } from '.'

export const retrieveLocationGroups = createAsyncThunk<LocationGroupsData, string>('accounts/retrieveLocationGroups', async (searchParam, thunkApi) => {
  const response = await apollo.query({
    query: LOCATION_GROUPS,
    variables: { first: 40, search: searchParam }
  })

  return response.data
})

export const retrieveHierarchyGroups = createAsyncThunk<HierarchyGroupsData, string>('accounts/retrieveHierarchyGroups', async (searchParam, thunkApi) => {
  const response = await apollo.query({
    query: HIERARCHY_GROUPS,
    variables: { first: 100, search: searchParam }
  })

  return response.data
})

interface RetrieveLocationsArgs {
  searchParam?: string
  first?: number
  isAll?: boolean
}

export const retrieveLocations = createAsyncThunk<SurveyLocations, RetrieveLocationsArgs>('accounts/retrieveLocations', async ({ searchParam, first, isAll }, thunkApi) => {
  const response = await apollo.query({
    query: LOCATIONS,
    variables: { first: isAll ? undefined : first, search: searchParam || '' }
  })
  if (isAll) {
    const allLocations = response.data?.locations.edges.map((location: {node:{name: string, uuid: string}}) => ({ text: location.node.name, value: location?.node.uuid || '' }))
    thunkApi.dispatch(setSelectedLocations(allLocations))
  }
  return response.data
})
