import { SurveyIcon } from '@reply-pro/component-library'
import SurveysManager from 'components/SurveysManager'
import { useSurveysList } from 'components/SurveysManager/ManageSurveysList/hooks'
import SurveyStatus from 'components/SurveysManager/constants'
import { useState } from 'react'
export const SURVEY = 'Survey'
export const SURVEYS = `${SURVEY}s`
function ManageSurveys () {
  const [status, setStatus] = useState<SurveyStatus[keyof SurveyStatus]>('enabled')
  const { data, loading } = useSurveysList({ status, type: SURVEY.toLowerCase() })
  return (
    <SurveysManager
      surveyType={SURVEY}
      url='/settings/manage-surveys/survey-setup/'
      title={`Manage ${SURVEYS}`}
      icon={SurveyIcon}
      buttonTextContent={`Add New ${SURVEY}`}
      data={data && data.surveys}
      loading={loading}
      setStatus={setStatus}
      searchPlaceholder={`Search ${SURVEYS}`}
    />
  )
}

export default ManageSurveys
