import { logout } from 'store/loginSlice'
import { ReactComponent as SvgLogout } from 'assets/logout.svg'
import Modal from 'components/Modal'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { Button, NavigationLink } from '@reply-pro/component-library'
import { useState } from 'react'
import './logout.scss'

function Logout () {
  const [isLogoutWarningOpen, setIsLogoutWarningOpen] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  return (
    <>
      <div className='logout-nav-container'>
        <NavigationLink
          className='logout-nav'
          to='logout'
          href='#!'
          onClick={() => setIsLogoutWarningOpen(true)}
          icon={<SvgLogout />}
        >
          Log Out
        </NavigationLink>
      </div>
      {isLogoutWarningOpen &&
        <Modal
          showModal={isLogoutWarningOpen}
          toggleModalOpen={() => setIsLogoutWarningOpen(false)}
          actions={
            <div className='logout-actions'>
              <Button backgroundColor='transparent' className='confirm-logout-btn' onClick={() => dispatch(logout())}>Yes, leave</Button>
              <Button onClick={() => setIsLogoutWarningOpen(false)}>No, I'll stay</Button>
            </div>
}
          title='Logout'
        >
          <p>Do you want to logout from your account?</p>
        </Modal>}
    </>
  )
}

export default Logout
