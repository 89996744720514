import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'components/Modal'
import { Button, TextInput } from '@reply-pro/component-library'
import { camelCaseToCapitalizedWords } from 'utils/common/camelCaseToCapitalizedWords'
import { isValidHexColorCharacter, isValidHexColor } from 'utils/common/isValidHexColor'
import { isValidHttpUri, isValidHttpsUri } from 'utils/common/isValidHttpsUri'
import { parseWhiteLabelInAppData } from 'utils/whiteLabel/parseWhiteLabelData'
import { LazyQueryExecFunction, OperationVariables, useMutation } from '@apollo/client'
import { WhiteLabel } from 'types/account'
import { getInAppPayload, getRequest } from '../../getRequest'
import './EditInAppBrandingModal.scss'
import { isStaging } from 'utils/common/envDetection'

interface EditInAppBrandingModalProps {
  initialValue?: string
  showEditInAppBrandingModal: boolean
  setShowEditInAppBrandingModal: Dispatch<SetStateAction<boolean>>
  inAppBrandingKey: keyof ReturnType<typeof parseWhiteLabelInAppData>
  getWhiteLabelData: LazyQueryExecFunction<WhiteLabel, OperationVariables>
}

const EditInAppBrandingModal = ({ initialValue, showEditInAppBrandingModal, setShowEditInAppBrandingModal, inAppBrandingKey, getWhiteLabelData }: EditInAppBrandingModalProps) => {
  const [value, setValue] = useState(initialValue ?? '')

  const [addInAppBrandingAttribute, { data, loading, error }] = useMutation(getRequest(inAppBrandingKey))

  const handleSave = async () => {
    await addInAppBrandingAttribute({ variables: { input: getInAppPayload(inAppBrandingKey, value) } })
    getWhiteLabelData()
  }

  useEffect(() => {
    if (data) {
      setShowEditInAppBrandingModal(false)
    }
  }, [data, setShowEditInAppBrandingModal])

  return (
    <Modal
      showModal={showEditInAppBrandingModal}
      toggleModalOpen={() => setShowEditInAppBrandingModal(prev => !prev)}
      title={camelCaseToCapitalizedWords(inAppBrandingKey)}
      actions={
        <div className='edit-in-app-branding-modal-action-container'>
          <Button className='edit-in-app-branding-modal-action' disabled={isInputDisabled(inAppBrandingKey, value) || loading} onClick={handleSave}>Save</Button>
          {error && <p className='edit-in-app-branding-modal-error-message'>There was an error while trying to save the attribute. Please try again.</p>}
        </div>
      }
      className='edit-in-app-branding-modal'
    >
      {getEmailKeyBrandingComponent(inAppBrandingKey, value, setValue)}
    </Modal>
  )
}

const getEmailKeyBrandingComponent = (inAppBrandingKey: keyof ReturnType<typeof parseWhiteLabelInAppData>, value: string, setValue: Dispatch<SetStateAction<string>>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const handleHexColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if ((isValidHexColorCharacter(value[value.length - 1]) || value === '') && value.length <= 7) {
      setValue(value)
    }
  }

  if (inAppBrandingKey === 'logo' || inAppBrandingKey === 'loginLogo') {
    return (
      <div className='in-app-brand-container-rows'>
        <h3>Set Logo</h3>
        <TextInput placeholder='File URL' onChange={handleInputChange} value={value} />
        <p className='helper-text'>Recommended: SVG file in landscape aspect ratio (Ideal size: 180px * 48px)</p>
      </div>
    )
  }

  if (inAppBrandingKey === 'brandColor' || inAppBrandingKey === 'loginBrandColor') {
    return (
      <div className='in-app-brand-container-columns'>
        <div className='in-app-brand-container-rows'>
          <h3>Set the brand color</h3>
          <TextInput placeholder='Ex. #C20000' onChange={handleHexColorChange} value={value} />
          <p className='helper-text'>Tip: Use dark colors, white elements will be upfront.</p>
        </div>
        <div className='in-app-brand-container-center'>
          <Button backgroundColor={value && isValidHexColor(value) ? value : undefined}>Button</Button>
        </div>
      </div>
    )
  }

  if (inAppBrandingKey === 'domain') {
    return (
      <div className='in-app-brand-container-columns'>
        <div className='in-app-brand-container-rows'>
          <h3>Set the domain</h3>
          <TextInput placeholder='Ex. https://hub.replypro.io' onChange={handleInputChange} value={value} />
          <p className='helper-text'>Remember to use "https://"</p>
        </div>
      </div>
    )
  }
}

const isInputDisabled = (inAppBrandingKey: keyof ReturnType<typeof parseWhiteLabelInAppData>, value: string) => {
  if (value === '') {
    return false
  }

  if (['logo'].includes(inAppBrandingKey)) {
    return !isValidHttpsUri(value)
  }

  if (['domain'].includes(inAppBrandingKey)) {
    return isStaging() ? !isValidHttpUri(value) && !isValidHttpsUri(value) : !isValidHttpsUri(value)
  }

  if (['brandColor, loginBrandColor'].includes(inAppBrandingKey)) {
    return !isValidHexColor(value)
  }

  return false
}

export default EditInAppBrandingModal
