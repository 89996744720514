import { TagGroupsEdge } from 'types/tagGroup'

export const filterTagGroupByGroup = (tagGroupsEdges: TagGroupsEdge[], entities: string[]) =>
  tagGroupsEdges.filter((group) => {
    return group.node.taggableEntities.some((entity: string) => entities.includes(entity))
  })

export const filterTagGroup = (tagGroupsEdges: TagGroupsEdge[], entities: string[], otherFilters?: (group: TagGroupsEdge) => boolean) =>
  tagGroupsEdges.filter((group: any) => {
    return group.node.taggableEntities.some((entity: string) => entities.includes(entity)) && otherFilters?.(group)
  })
