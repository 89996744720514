import { Button, SelectInput, TextInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import './AddPostSurveyActionsModal.scss'
import { useAddPostSurveyActions, useHandleAutoResponseModal, useHandleInteractionModal, usePostActionRules } from './hooks'
import { AVAILABLE_ACTIONS, POST_ACTIONS, TRIGGER_TYPES } from './constants'
import InteractionTagsModal from './InteractionTagsModal/InteractionTagsModal'
import AutoRespondAttributes from './AutoRespondAttributes'
import { AddPostSurveyActionsModalProps } from '../PostSurveyActionsModal.types'
import WorkflowSelector from './WorkflowSelector'
import PurchaseAndTicketAttributes from './PurchaseAndTicketAttributes/PurchaseAndTicketAttributes'
function AddPostSurveyActionsModal ({ showModal, toggleModalOpen, id, refetch }: AddPostSurveyActionsModalProps) {
  const { questions, trigger, triggerType, handleSelectedQuestionChange, handleSelectedAnswerChange, handleSelectedTriggerTypeChange, answerChoices } = useAddPostSurveyActions()
  const { selectedAction, setSelectedAction, addPostSurveyAction, loading, changeAttributes, attributes, tags, setTags } = usePostActionRules({ trigger, id, toggleModalOpen, triggerType, refetch })
  const { toggleTaggingModalOpen, showModalOpened } = useHandleInteractionModal()
  const { toggleAutoResponseModalOpen, isAutoRespondModalOpen } = useHandleAutoResponseModal()

  const { AUTO_REPLY, TAG, CREATE_ACTIVITY, MATCH_PURCHASE, CREATE_CHARTICKET } = POST_ACTIONS

  const createActivityMissingAttributes = selectedAction === CREATE_ACTIVITY && (!attributes.workflow || !attributes.due || !attributes.user)
  const matchPurchaseMissingAttributes = selectedAction === MATCH_PURCHASE && !attributes.channel
  const createCharticketMissingAttributes = selectedAction === CREATE_CHARTICKET && !attributes.user

  const disabledAddButton = !triggerType || !selectedAction || createActivityMissingAttributes || matchPurchaseMissingAttributes || createCharticketMissingAttributes

  return (
    <>
      <Modal
        className='add-post-survey-actions-modal'
        title='Add New Action'
        showModal={showModal}
        toggleModalOpen={toggleModalOpen}
        actions={<Button onClick={addPostSurveyAction} disabled={loading || disabledAddButton}>Add</Button>}
      >
        <div className='add-post-survey-actions-container'>
          Trigger
          <div className='trigger-selectors'>
            <SelectInput
              className='input'
              placeholder='What needs to happen?'
              value={triggerType || ''}
              onChange={(e) => handleSelectedTriggerTypeChange(e.target.value)}
            >
              {Object.values(TRIGGER_TYPES).map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </SelectInput>
          </div>
          {triggerType === TRIGGER_TYPES.QUESTION &&
            <div className='trigger-selectors'>
              <SelectInput
                className='input trigger'
                placeholder='Question'
                value={trigger.question.name || ''}
                onChange={(e) => handleSelectedQuestionChange(e.target.value)}
              >
                {questions.map((question, index) => (
                  <option key={index} value={question.name}>
                    {question.title}
                  </option>
                ))}
              </SelectInput>

              {!answerChoices || answerChoices.length === 0
                ? (
                  <TextInput
                    className='input trigger'
                    placeholder='Answer'
                    value={trigger.answer || ''}
                    onChange={(e) => handleSelectedAnswerChange(e.target.value)}
                    disabled={!trigger.question.name}
                  />
                  )
                : (
                  <SelectInput
                    className='input trigger'
                    placeholder='Answer'
                    value={trigger.answer || ''}
                    onChange={(e) => handleSelectedAnswerChange(e.target.value)}
                    disabled={!trigger.question.name}
                  >
                    {answerChoices.map((choice, index) => (
                      <option key={index} value={choice.value}>
                        {choice.text || choice.value}
                      </option>
                    ))}
                  </SelectInput>
                  )}
            </div>}
          Actions
          <div className='action-manager-content'>
            <SelectInput
              className='input'
              placeholder='Action'
              value={selectedAction}
              disabled={!trigger.answer && triggerType !== TRIGGER_TYPES.SUBMIT}
              onChange={(e) => setSelectedAction(e.target.value)}
            >
              {AVAILABLE_ACTIONS.map((action, index) => (
                <option key={index} value={action}>
                  {action}
                </option>))}
            </SelectInput>
            {selectedAction === TAG &&
              <Button
                className='secondary-action-button'
                stroked
                onClick={toggleTaggingModalOpen}
                disabled={false}
              >
                Manage Tags
              </Button>}
            {
              selectedAction === AUTO_REPLY &&
                <Button
                  className='secondary-action-button'
                  stroked
                  onClick={toggleAutoResponseModalOpen}
                  disabled={false}
                >
                  Manage Auto Response
                </Button>
            }
          </div>
          {
            selectedAction === CREATE_ACTIVITY &&
              <WorkflowSelector
                onAttributeChange={changeAttributes}
                attributes={attributes}
              />
          }
          {
            selectedAction === AUTO_REPLY &&
              <AutoRespondAttributes
                toggleAutoResponseModalOpen={toggleAutoResponseModalOpen}
                isAutoRespondModalOpen={isAutoRespondModalOpen}
                onAttributeChange={changeAttributes}
                attributes={attributes}
              />
          }
          {
            (selectedAction === MATCH_PURCHASE || selectedAction === CREATE_CHARTICKET) &&
              <PurchaseAndTicketAttributes
                selectedAction={selectedAction}
                onAttributeChange={changeAttributes}
                attributes={attributes}
              />
          }
        </div>
      </Modal>
      <InteractionTagsModal showModalOpened={showModalOpened} toggleTaggingModalOpen={toggleTaggingModalOpen} tags={tags} setTags={setTags} />
    </>
  )
}

export default AddPostSurveyActionsModal
