import { createSlice } from '@reduxjs/toolkit'
import { SurveysState } from './surveyTypes'
import { addSurvey, deactivateSurvey, retrieveSurvey, retrieveSurveyList, syncSurveyQuestions } from './surveysDispatchers'
import { SURVEY_THEMES } from 'components/SurveysManager/ManageSurveySetup/Themes/themes_constants'

const initialState: SurveysState = {
  surveyList: {
    data: undefined,
    loading: false
  },
  surveySetup: {
    data: undefined,
    id: undefined,
    loading: false,
    disabled: false,
    error: false
  },
  surveyQuestions: {
    localData: undefined,
    pendingSyncChanges: false,
    isSavingChanges: false,
    lastSynced: undefined
  },
  deactivateSurvey: {
    loading: false
  }
}

export const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    clearSurveySetup: (state) => {
      state.surveySetup = initialState.surveySetup
      state.surveyQuestions = initialState.surveyQuestions
    },
    setSurveyLocalQuestionsData: (state, action) => {
      state.surveyQuestions.localData = action.payload
      state.surveyQuestions.pendingSyncChanges = true
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addSurvey.pending, (state) => {
      state.surveySetup.data = undefined
      state.surveySetup.loading = true
    })
    builder.addCase(addSurvey.fulfilled, (state, action) => {
      state.surveySetup.data = action.meta.arg
      state.surveySetup.id = action.payload.surveyAdd.created.id
      state.surveySetup.loading = false
    })
    builder.addCase(deactivateSurvey.pending, (state) => {
      state.deactivateSurvey.loading = true
    })
    builder.addCase(deactivateSurvey.fulfilled, (state, action) => {
      state.deactivateSurvey.loading = false
      const deactivatedSurvey = state.surveyList?.data?.surveys.edges.find(({ node }) => node.id === action.meta.arg)
      if (deactivatedSurvey) {
        deactivatedSurvey.node.isActive = false
      }
    })
    builder.addCase(syncSurveyQuestions.pending, (state) => {
      state.surveySetup.disabled = true
      state.surveyQuestions.isSavingChanges = true
    })
    builder.addCase(syncSurveyQuestions.rejected, (state) => {
      state.surveyQuestions.isSavingChanges = false
      state.surveySetup.disabled = false
    })
    builder.addCase(syncSurveyQuestions.fulfilled, (state, action) => {
      state.surveySetup.data.json = JSON.stringify(action.payload.surveyEdit.modified?.data.definition)
      state.surveyQuestions.isSavingChanges = false
      state.surveyQuestions.pendingSyncChanges = false
      state.surveyQuestions.lastSynced = new Date().toISOString()
      state.surveySetup.disabled = false
    })
    builder.addCase(retrieveSurveyList.pending, (state) => {
      state.surveyList.loading = true
      state.surveyList.data = undefined
    })
    builder.addCase(retrieveSurveyList.fulfilled, (state, action) => {
      state.surveyList.data = action.payload
      state.surveyList.loading = false
    })
    builder.addCase(retrieveSurvey.pending, (state) => {
      state.surveySetup.loading = true
      state.surveySetup.data = undefined
      state.surveyQuestions = initialState.surveyQuestions
    })
    builder.addCase(retrieveSurvey.fulfilled, (state, action) => {
      const surveyToEdit = {
        surveyName: action.payload.survey.internalName,
        theme: action.payload.survey.theme || JSON.stringify(SURVEY_THEMES[0]),
        json: action.payload.survey.definition
      }
      state.surveySetup.id = action.payload.survey.id
      state.surveySetup.data = surveyToEdit
      state.surveyQuestions.localData = JSON.parse(action.payload.survey.definition)
      state.surveySetup.loading = false
    })
  }
})

export const { clearSurveySetup, setSurveyLocalQuestionsData } = surveysSlice.actions

export default surveysSlice.reducer
