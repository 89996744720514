import { Check, InfoIcon, Notification, Warning } from '@reply-pro/component-library'
import './Notifications.scss'
import { useNotifications } from './useNotifications'

export default function Notifications () {
  const { successMessage, errorMessage, dismissNotifications, loadingMessage, warningMessage } = useNotifications()

  return (
    <>
      {successMessage
        ? (
          <Notification
            className='notification'
            backgroundColor='white'
            color='dark'
            contents={successMessage}
            type='success'
            icon={Check}
            dismiss={dismissNotifications}
            autoDismissMs={4000}
            button={{ label: 'Dismiss', onClick: dismissNotifications, backgroundColor: 'dark', color: 'white' }}
            dismissBarColor='dark'
          />
          )
        : errorMessage
          ? (
            <Notification
              className='notification'
              contents={errorMessage}
              type='error'
              icon={Warning}
              backgroundColor='white'
              color='dark'
              borderWidth='1px'
              borderColor='red'
              dismiss={dismissNotifications}
              autoDismissMs={4000}
              button={{ label: 'Dismiss', onClick: dismissNotifications, backgroundColor: 'dark', color: 'white' }}
              dismissBarColor='dark'
            />
            )
          : warningMessage
            ? (
              <Notification
                className='notification'
                contents={warningMessage}
                type='default'
                icon={InfoIcon}
                backgroundColor='white'
                color='dark'
                borderWidth='1px'
                borderColor='lighterGray'
                iconBackgroundColor='red2'
                button={{ label: 'Understood', onClick: dismissNotifications, backgroundColor: 'red2', color: 'white' }}
                dismissBarColor='dark'
              />
              )
            : (
                loadingMessage && (
                  <Notification
                    className='notification'
                    backgroundColor='white'
                    color='dark'
                    contents={loadingMessage || 'Loading...'}
                    type='loading'
                    dismissBarColor='dark'
                  />
                )
              )}
    </>
  )
}
