import { CHANNEL_TYPES } from 'utils/constants'
import AttributesChannelSelector from '../AttributesChannelSelector'
import { AttributeChangeType, POST_ACTIONS } from '../constants'
import AttributesUserSelector from '../AttributesUserSelector'
import './PurchaseAndTicketAttributes.scss'

interface PurchaseAndTicketAttributesProps extends AttributeChangeType {
    selectedAction: string
    attributes: { [key: string]: any }
}

const { MATCH_PURCHASE, CREATE_CHARTICKET } = POST_ACTIONS
const { EMAIL } = CHANNEL_TYPES

function PurchaseAndTicketAttributes ({ selectedAction, attributes, onAttributeChange }: PurchaseAndTicketAttributesProps) {
  return (
    <div className='purchase-and-ticket-container'>
      {selectedAction === MATCH_PURCHASE && <AttributesChannelSelector channelType={EMAIL} value={attributes.channel} onAttributeChange={onAttributeChange} />}
      {selectedAction === CREATE_CHARTICKET &&
        <div className='metadata-container'>
          <span>Select User *</span>
          <AttributesUserSelector value={attributes.user} onAttributeChange={onAttributeChange} />
        </div>}
    </div>
  )
}

export default PurchaseAndTicketAttributes
