import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Button, TextArea, TextInput } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import './UserGroupEditor.scss'

interface UserGroupEditorBase {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  loading: boolean
  error?: boolean
}

interface UserGroupEditorAdd extends UserGroupEditorBase {
  id?: never
  handleOnSubmit: ({ name, description }: { name: string; description?: string }) => Promise<void>
}

interface UserGroupEditorEdit extends UserGroupEditorBase {
  id: string
  handleOnSubmit: ({ userGroup, name, description }: { userGroup: string; name: string; description?: string }) => Promise<void>
  name: string
  description?: string
}

type UserGroupEditorProps = UserGroupEditorAdd | UserGroupEditorEdit

const isAddUserGroup = (props: UserGroupEditorProps): props is UserGroupEditorAdd => {
  return props.id === undefined
}

const UserGroupEditor = (props: UserGroupEditorProps) => {
  const [userGroupData, setUserGroupData] = useState<{ name: string; description?: string }>(isAddUserGroup(props) ? { name: '' } : { name: props.name, description: props.description })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserGroupData({ ...userGroupData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    if (isAddUserGroup(props)) {
      await props.handleOnSubmit(userGroupData)
    } else {
      await props.handleOnSubmit({ userGroup: props.id, ...userGroupData })
    }
  }

  return (
    <Modal
      showModal={props.showModal}
      title={!props.id ? 'Add User Group' : 'Edit User Group'}
      actions={
        <div className='add-user-group-actions'>
          <Button onClick={handleSubmit} disabled={props.loading}>
            {!props.id ? 'Add User Group' : 'Update User Group'}
          </Button>
          {props.error && <p className='error-message'>There has been an error, please try again.</p>}
        </div>
      }
      toggleModalOpen={() => props.setShowModal(false)}
      className='user-group-modal'
    >
      <div className='user-group-modal-body'>
        <div className='user-group-input-group'>
          <h4>
            Name<span>*</span>
          </h4>
          <TextInput
            className='user-group-input'
            required
            name='name'
            onChange={handleInputChange}
            value={userGroupData.name}
            maxLength={80}
          />
        </div>
        <div className='user-group-input-group'>
          <h4>Description</h4>
          <TextArea
            className='user-group-input'
            name='description'
            onChange={handleInputChange}
            value={userGroupData.description}
            maxLength={80}
          />
          <p className='helper-text'>Max 80 characters</p>
        </div>
      </div>
    </Modal>
  )
}

export default UserGroupEditor
