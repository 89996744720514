import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import loginWithoutPersist from './loginSlice'
import surveysWithoutPersist from './surveysSlice'
import settingsWithoutPersist from './settingsSlice'
import accountsWithoutPersist from './accountsSlice'
import filesWithoutPersist from './filesSlice'
import formWithoutPersist from './formSlice'
import metadataWithoutPersist from './metadataSlice'
import locationsWithoutPersist from './accountsSlice/locations'
import usersWithoutPersist from './accountsSlice/users'
import tagsWithoutPersist from './tags/tags'

const login = persistReducer({ key: 'login', storage, blacklist: ['error'] }, loginWithoutPersist)
const surveys = persistReducer({ key: 'surveys', storage, blacklist: ['surveySetup, surveyList, surveyQuestions'] }, surveysWithoutPersist)
const settings = persistReducer({ key: 'settings', storage }, settingsWithoutPersist)
const locations = persistReducer(
  { key: 'locations', storage, blacklist: ['locations', 'locationGroups'] },
  locationsWithoutPersist
)
const users = persistReducer(
  { key: 'users', storage, blacklist: ['allUsers', 'userToggleLoading', 'userGroups', 'userGroupUsers'] },
  usersWithoutPersist
)
const files = persistReducer({ key: 'settings', storage, blacklist: ['savedFiles', 'fileDragging'] }, filesWithoutPersist)
const form = persistReducer({ key: 'form', storage }, formWithoutPersist)
const accounts = persistReducer({ key: 'accounts', storage, whitelist: ['accountId'] }, accountsWithoutPersist)
const metadata = persistReducer({ key: 'metadata', storage, blacklist: ['out_of_sync'] }, metadataWithoutPersist)
const tags = persistReducer({ key: 'tags', storage }, tagsWithoutPersist)

export const store = configureStore({
  reducer: {
    login,
    metadata,
    accounts,
    settings,
    locations,
    users,
    files,
    form,
    tags,
    surveys
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
