import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { toggleUserActive } from 'store/accountsSlice/users/dispatchers.users'

export const useUserToggleActive = (id: string) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const loading = useSelector((state: RootState) => state.users.userToggleLoading)

  const dispatch: AppDispatch = useDispatch()

  const handleToggle = () => {
    dispatch(toggleUserActive(id))
  }

  const toggleConfirmationModalOpen = () => {
    setIsConfirmationModalOpen(prev => !prev)
  }

  const handleConfirmToggle = () => {
    handleToggle()
    toggleConfirmationModalOpen()
  }

  return { handleToggle, isConfirmationModalOpen, toggleConfirmationModalOpen, handleConfirmToggle, loading }
}
