import { apollo } from 'api'
import {
  TAG_CREATE_GROUP,
  TAG_GROUPS,
  TAG_CREATE_FIELD,
  TAG_GROUP_SET_CONDITION,
  TAG_FIELD_DEACTIVATE,
  TAG_FIELD_SET_CHOICES,
  TAG_GROUP_DEACTIVATE,
  TAG_CREATE,
  TAG_DELETE
} from 'requests/Tags'
import { TagCreateMutationResult, TagCreatePayload, removeTagPayload } from '../types.tags'
import { TagGroupsResponse, TagGroupsRetrievePayload } from 'types/tagGroup'
import { createTagFieldInput, createTagGroupInput, setGroupConditionsInput } from 'store/tags/types.tags'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const retrieveTagGroups = createAsyncThunk('tags/retrieveTagGroups', async ({ query }: TagGroupsRetrievePayload, thunkApi) => {
  const response = await apollo.query<TagGroupsResponse>({
    query: TAG_GROUPS,
    variables: {
      search: query
    }
  })
  return response.data
})

export const createNewTagGroup = createAsyncThunk('tags/createNewTagGroup', ({ description, entities }: createTagGroupInput, thunkApi) => {
  apollo
    .mutate({
      mutation: TAG_CREATE_GROUP,
      variables: {
        input: { description, taggableEntities: entities }
      }
    })
    .then((res) => {
      thunkApi.dispatch(retrieveTagGroups({}))
      if (!res.data) {
        throw new Error('Internal server error')
      }
    })
})

export const createNewTagField = createAsyncThunk('tags/createNewTagField', async ({ group, label, type, choices, order }: createTagFieldInput, thunkApi) => {
  apollo
    .mutate({
      mutation: TAG_CREATE_FIELD,
      variables: {
        input: { group, label, type, choices, order }
      }
    })
    .then((res) => {
      thunkApi.dispatch(retrieveTagGroups({}))
      if (!res.data) {
        throw new Error('Internal server error')
      }
    })
})

export const setGroupConditions = createAsyncThunk('tags/setGroupConditions', ({ tagGroup, conditions }: setGroupConditionsInput, thunkApi) => {
  apollo
    .mutate({
      mutation: TAG_GROUP_SET_CONDITION,
      variables: {
        input: { tagGroup, conditions }
      }
    })
    .then((res) => {
      thunkApi.dispatch(retrieveTagGroups({}))
      if (!res.data) {
        throw new Error('Internal server error')
      }
    })
})

export const tagGroupSoftDelete = createAsyncThunk('tags/tagGroupSoftDelete', async (id: string, thunkApi) => {
  apollo
    .mutate({
      mutation: TAG_GROUP_DEACTIVATE,
      variables: { input: { tagGroup: id } }
    })
    .then((res) => {
      if (!res.data) {
        throw new Error('Internal server error')
      }
      thunkApi.dispatch(retrieveTagGroups({}))
    })
})

export const tagFieldSoftDelete = createAsyncThunk('tags/tagFieldSoftDelete', async (id: string, thunkApi) => {
  apollo
    .mutate({
      mutation: TAG_FIELD_DEACTIVATE,
      variables: { input: { tagField: id } }
    })
    .then((res) => {
      if (!res.data) {
        throw new Error('Internal server error')
      }
      thunkApi.dispatch(retrieveTagGroups({}))
    })
})

export const tagFieldSetChoices = createAsyncThunk(
  'tags/tagFieldSetChoices',
  async ({ id, choices }: { id: string; choices: { value: string; display_name: string }[] }, thunkApi) => {
    apollo
      .mutate({
        mutation: TAG_FIELD_SET_CHOICES,
        variables: { input: { tagField: id, choices } }
      })
      .then((res) => {
        if (!res.data) {
          throw new Error('Internal server error')
        }
        thunkApi.dispatch(retrieveTagGroups({}))
      })
  }
)

export const tagCreate = createAsyncThunk('tags/tagCreate', async ({ entityId, entityType, tagField, value, replaceAllExistingTags, tags }: TagCreatePayload, thunkApi) => {
  const alreadyTaggingEntity = tags?.find((tag) => tag.tagField.id === tagField && tag.value === value)

  if (alreadyTaggingEntity) throw new Error('Tagging twice is not allowed')

  const response = apollo.mutate<TagCreateMutationResult>({
    mutation: TAG_CREATE,
    variables: {
      input: {
        entity: entityId,
        entityType,
        tagField,
        value,
        replaceAllExistingTags
      }
    }
  })

  return { value, response }
})

export const tagDelete = ({ entityId, entityType, tagField, value }: removeTagPayload) =>
  apollo.mutate({
    mutation: TAG_DELETE,
    variables: {
      input: {
        entity: entityId,
        entityType,
        tagField,
        value
      }
    }
  })
