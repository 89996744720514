import ManageSurveySetup from 'components/SurveysManager/ManageSurveySetup'
import { SURVEY } from '../ManageSurveys'

function SurveySetup () {
  return (
    <ManageSurveySetup surveyType={SURVEY} />
  )
}

export default SurveySetup
