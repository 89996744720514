import { Tab, Tabs } from '@reply-pro/component-library'
import EmailWhiteLabel from './EmailWhiteLabel'
import InAppWhiteLabel from './InAppWhiteLabel'

function AccountDetails () {
  return (
    <div className='full-screen-card-tabs-settings'>
      <div className='tabs-settings-container'>
        <Tabs
          tabHeaderWidth='30rem'
          tabHeaderTopMargin='1.5rem'
          smallTab
        >
          <div className='tabs-container'>
            <Tab
              key='email-tab'
              label='EMAIL SETUP'
            >
              <EmailWhiteLabel />
            </Tab>
            <Tab
              key='in-app-tab'
              label='IN-APP SETUP'
            >
              <InAppWhiteLabel />
            </Tab>
          </div>
        </Tabs>
      </div>
    </div>
  )
}

export default AccountDetails
