import { SetStateAction, Dispatch, ChangeEvent } from 'react'
import { ArrowRight, Button, NavItem, SearchInput, Clipboard, ReloadIcon, LoadingSpinner } from '@reply-pro/component-library'
import { useHandleAddAllAccounts, useHandleConfirmation, useHandleSetAccountFeatures } from './hooks'
import ModalWithFilterButtons from 'components/ModalWithFilterButtons'
import { retrieveAccounts, setAccount } from 'store/accountsSlice/accountsDispatchers'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { copyToClipboard } from 'utils/common'
import { accountSetFromModalTrackingEvent } from 'utils/dataTracking/accountsTracking'
import ToggleAccountNotifications from './AccountNotifications/ToggleAccountNotifications'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import { useToggleAccountNotifications } from './AccountNotifications/hooks'
import FeaturesSetter from 'components/FeaturesSetter'
import { debounce } from 'lodash'
import './manageAccountsModal.scss'
import classNames from 'classnames'

type ManageAccountModalProps = {
  setAccountSelected: Dispatch<SetStateAction<string | undefined>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setIsAddNewAccountModalOpen: Dispatch<SetStateAction<boolean>>
}
export default function ManageAccountsModal ({ setAccountSelected, setIsModalOpen, isModalOpen, setIsAddNewAccountModalOpen }: ManageAccountModalProps) {
  const { data, loading } = useSelector((state: RootState) => state.accounts.accounts)

  const { handleAddAllAccounts, clicked, loadingAddAllAccounts } = useHandleAddAllAccounts()
  const { handleToggleNotifications } = useToggleAccountNotifications()
  const { handleSetAccountFeatures } = useHandleSetAccountFeatures()

  const {
    onConfirm: onNotificationConfirm,
    openModal: openNotificationsModal,
    closeModal: closeNotificationsModal,
    showModal: showNotificationModal
  } = useHandleConfirmation({
    initialData: { id: '' },
    handleConfirm: handleToggleNotifications
  })

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleSelectedAccount = (id: string, name: string) => {
    accountSetFromModalTrackingEvent(id)
    dispatch(setAccount(id))
    setIsModalOpen(false)
    setAccountSelected(name)
    navigate('/settings/')
  }

  const filter = debounce((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(retrieveAccounts(e.target.value))
  }, 1000)

  return (
    <>
      <ModalWithFilterButtons
        title='MANAGE ALL ACCOUNTS'
        toggleModalOpen={() => setIsModalOpen(false)}
        isShowRelationModal={isModalOpen}
        className='manage-accounts-modal'
        rightTopElement={
          <div className='right-top-elements'>
            <Button stroked onClick={() => handleAddAllAccounts()} disabled={clicked}><ReloadIcon className={classNames('reload-icon', { rotate: loadingAddAllAccounts })} /></Button>
            <Button onClick={() => setIsAddNewAccountModalOpen(true)}>Add New Account</Button>
          </div>
    }
        otherFilters={
          <SearchInput
            onChange={filter}
            placeholder='Search Account'
          />
      }
      >
        <>
          {loading
            ? <div className='manage-accounts-loading-spinner-container'><LoadingSpinner size='small' /></div>
            : data?.accounts?.edges?.map(({ node }, index) =>
              <NavItem className='account-nav-item' key={node.id + '-' + index}>
                <div className='account-wrapper'>
                  <div className='account-details'>
                    <div className='account-name'>{node?.name?.toUpperCase()}</div>
                    <div className='account-id-and-buttons'>
                      <div className='account-id-container'>
                        <div className='account-id'>Account ID: {node.id}</div>
                        <div className='clipboard'><Clipboard onClick={() => copyToClipboard(node.id)} /></div>
                      </div>
                      <div className='api-key-container'>
                        <div className='api-key'>Search API Key: {node.searchApiKey}</div>
                        <div className='clipboard'><Clipboard onClick={() => copyToClipboard(node.id)} /></div>
                      </div>
                    </div>
                  </div>

                  <div className='account-actions'>
                    <div className='features-container'>
                      <FeaturesSetter
                        entity='Account'
                        features={node.features}
                        setFeaturesAction={(features) => handleSetAccountFeatures({ accountId: node.id, features })}
                        userGroupWarning={node.hasUserGroupWithFeatures}
                      />
                      <ToggleAccountNotifications toggleModalOpen={openNotificationsModal} id={node.id} notificationsDeactivated={node.notificationsDeactivated} />
                      <div className='round-buttons'><Button className='select-account-button' onClick={() => handleSelectedAccount(node.id, node.name!)} stroked small><ArrowRight className='button-icon' /></Button></div>
                    </div>
                  </div>
                </div>
              </NavItem>)}
        </>
      </ModalWithFilterButtons>
      {showNotificationModal && <ConfirmationModal showModal={showNotificationModal} warningFirstLine='You are about to enable or disable Notifications for this account.' onConfirm={onNotificationConfirm} confirmText='TOGGLE' buttonText='Confirm' modalTitle='enable/disable notifications' toggleModalOpen={closeNotificationsModal} />}
    </>
  )
}
