import { DistributionIcon, Edit } from '@reply-pro/component-library'
import SurveyDistributionModal from '../SurveyDistributionModal'
import { useSurveyActions } from './hooks'
import SurveyDeactivate from './SurveyDeactivate'

function SurveyActions ({ id, isActive, surveyType, surveyName }: { id: string, isActive: boolean, surveyType: string, surveyName: string }) {
  const { isModalOpen, toggleModalOpen, handleEditSurvey } = useSurveyActions({ id, surveyType: surveyType.toLowerCase() })

  return (
    <>
      <div className='survey-list-actions-container'>
        <DistributionIcon className='survey-action' onClick={toggleModalOpen} />
        <Edit onClick={handleEditSurvey} className='survey-action' />
        {/* <TrashCanComponent className='survey-action' /> */}
        {/* Commented until we have a mutation to remove Survey */}
        <SurveyDeactivate id={id} isActive={isActive} surveyName={surveyName} surveyType={surveyType} />
      </div>
      {isModalOpen && <SurveyDistributionModal surveyType={surveyType} id={id} showModal={isModalOpen} toggleModalOpen={toggleModalOpen} />}
    </>
  )
}

export default SurveyActions
