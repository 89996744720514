import { ReactNode } from 'react'
import Modal from '../Modal'
import './rightModal.scss'
interface modalProps {
    showModal: boolean
    title?: string
    children?: ReactNode
    actions?: ReactNode
    toggleModalOpen?: () => void
    className?: string
}

export default function RightModal ({ showModal, title, toggleModalOpen, className, children, actions }: modalProps) {
  return (
    <Modal
      className={`${className} right-modal`}
      showModal={showModal}
      toggleModalOpen={toggleModalOpen}
      title={title}
      actions={actions}
    >
      {children}
    </Modal>
  )
}
