/* eslint-disable operator-linebreak */
import { SearchInput } from '@reply-pro/component-library'
import { useGetUsers } from 'utils/hooks/users'
import SelectedUsers from './SelectedUsers/SelectedUsers'
import { useEffect, useRef, useState } from 'react'
import Tooltip from 'components/Tooltip/Tooltip'
import { user, userGroup } from 'types/user'
import './userSelector.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { getUserGroups } from 'store/accountsSlice/users/dispatchers.users'
import { UserGroupOption, UserOption } from './UserOption'

interface UserSelectorProps {
  isForAllUsers: boolean
  selectedUsers?: user[]
  addUserAction: (user: user) => void
  removeUserAction: (id: string) => void
  selectedUserGroups?: userGroup[]
  addUserGroupAction: (userGroup: userGroup) => void
  removeUserGroupAction: (id: string) => void
}

export default function UserSelector ({ isForAllUsers, selectedUsers, addUserAction, removeUserAction, selectedUserGroups, addUserGroupAction, removeUserGroupAction }: UserSelectorProps) {
  const { data: userGroupsData } = useSelector((store: RootState) => store.users.userGroups)
  const [selectValue, setSelectValue] = useState('users')
  const [userGroupsQueryParam, setUserGroupsQueryParam] = useState('')

  const search = useRef(null)
  const dispatch = useDispatch<AppDispatch>()

  const { data: usersData, setQueryParam: setUsersQueryParam, queryParam: usersQueryParam } = useGetUsers()

  const onSelectOption = (id: string) => {
    if (selectValue === 'users') {
      const user = usersData?.users?.edges.find(({ node }) => node.id === id)
      if (user) {
        addUserAction(user.node)
      }
      return
    }

    const userGroup = userGroupsData?.find(({ node }) => node.id === id)
    if (userGroup) {
      addUserGroupAction(userGroup.node)
    }
  }

  const handleSelect = (value: string) => {
    if (value === 'users') {
      setUsersQueryParam(userGroupsQueryParam)
    } else {
      setUserGroupsQueryParam(usersQueryParam)
    }

    setSelectValue(value)
  }

  useEffect(() => {
    dispatch(getUserGroups({ search: userGroupsQueryParam }))
  }, [dispatch, userGroupsQueryParam])

  return (
    <div className='user-selector'>
      <div ref={search}>
        <SearchInput
          disabled={isForAllUsers}
          className='contact-search'
          onChange={(e) => selectValue === 'users' ? setUsersQueryParam(e.target.value) : setUserGroupsQueryParam(e.target.value)}
          clearInput={() => selectValue === 'users' ? setUsersQueryParam('') : setUserGroupsQueryParam('')}
          onOptionSelect={(e) => onSelectOption(e.target.value)}
          placeholder='Search Users'
          value={selectValue === 'users' ? usersQueryParam : userGroupsQueryParam}
          autoComplete='off'
          select={{
            options:
                [
                  { label: 'Users', value: 'users' },
                  { label: 'User Groups', value: 'user-groups' }
                ],
            onChange: (e) => handleSelect(e.target.value),
            value: selectValue
          }}
        >
          {selectValue === 'users' ?
            usersData?.users?.edges.map(({ node }) =>
              <option value={node.id} key={node.id}>
                <UserOption
                  selectedUsers={selectedUsers}
                  userId={node.id}
                  firstName={node.firstName}
                  lastName={node.lastName}
                  email={node.email}
                />
              </option>) :
            userGroupsData?.map(({ node }) =>
              <option value={node.id} key={node.id}>
                <UserGroupOption
                  selectedUserGroups={selectedUserGroups}
                  userGroupId={node.id}
                  name={node.name}
                  description={node.description}
                />
              </option>)}
        </SearchInput>
      </div>
      <SelectedUsers
        users={selectedUsers}
        isForAllUsers={isForAllUsers}
        removeUserAction={removeUserAction}
        userGroups={selectedUserGroups}
        removeUserGroupAction={removeUserGroupAction}
      />
      {isForAllUsers &&
        <Tooltip anchor={search}>
          Selecting all users by default. Use the 'All Users' toggle to choose specific users.
        </Tooltip>}
    </div>
  )
}
