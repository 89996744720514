import { useDispatch } from 'react-redux'
import { Button, TrashCan } from '@reply-pro/component-library'
import Modal from 'components/Modal'
import { AppDispatch } from 'store'
import { useState } from 'react'
import './index.scss'

interface ElementDeleteProps {
    deleteDispatcher: (id:string)=> any
    id: string,
    name?: string,
    type?: string
}

export default function ElementDelete ({ deleteDispatcher, id, name, type }:ElementDeleteProps) {
  const [openModal, setOpenModal] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const softDelete = () => {
    dispatch(deleteDispatcher(id))
    setOpenModal(prevState => !prevState)
  }
  const handleModal = () => {
    setOpenModal(prevState => !prevState)
  }

  return (
    <>
      <TrashCan className='trash-icon' onClick={handleModal} />
      {openModal &&
        <Modal
          showModal={openModal}
          toggleModalOpen={handleModal}
          actions={<Button backgroundColor='red' onClick={softDelete}>Remove</Button>}
          title={`Are you sure you want to remove this ${type}?`}
        >
          <div className='confirmation-modal'>
            <p>You will not be able to access this {type}, after confirming this action: <br />
              <strong>{name}</strong>
            </p>
          </div>
        </Modal>}
    </>
  )
}
