import Modal from 'components/Modal'
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react'
import { Button, TextArea, TextInput } from '@reply-pro/component-library'
import { camelCaseToCapitalizedWords } from 'utils/common/camelCaseToCapitalizedWords'
import { isValidHexColorCharacter, isValidHexColor } from 'utils/common/isValidHexColor'
import { isValidHttpsUri } from 'utils/common/isValidHttpsUri'
import { useAddEmailBrandingAttribute } from './hooks'
import { parseWhiteLabelEmailData } from 'utils/whiteLabel/parseWhiteLabelData'
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import { WhiteLabel } from 'types/account'
import './EditEmailBrandingModal.scss'

interface EditEmailBrandingModalProps {
  initialValue?: string
  showEditEmailBrandingModal: boolean
  setShowEditEmailBrandingModal: Dispatch<SetStateAction<boolean>>
  emailBrandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData>
  getWhiteLabelData: LazyQueryExecFunction<WhiteLabel, OperationVariables>
}

const EditEmailBrandingModal = ({ initialValue, showEditEmailBrandingModal, setShowEditEmailBrandingModal, emailBrandingKey, getWhiteLabelData }: EditEmailBrandingModalProps) => {
  const { value, setValue, data, loading, error, handleSave } = useAddEmailBrandingAttribute({ initialValue, emailBrandingKey, getWhiteLabelData })

  useEffect(() => {
    if (data) {
      setShowEditEmailBrandingModal(false)
    }
  }, [data, setShowEditEmailBrandingModal])

  return (
    <Modal
      showModal={showEditEmailBrandingModal}
      toggleModalOpen={() => setShowEditEmailBrandingModal(prev => !prev)}
      title={camelCaseToCapitalizedWords(emailBrandingKey)}
      actions={
        <div className='edit-email-branding-modal-action-container'>
          <Button className='edit-email-branding-modal-action' disabled={isInputDisabled(emailBrandingKey, value) || loading} onClick={handleSave}>Save</Button>
          {error && <p className='edit-email-branding-modal-error-message'>There was an error while trying to save the attribute. Please try again.</p>}
        </div>
      }
      className='edit-email-branding-modal'
    >
      {getEmailKeyBrandingComponent(emailBrandingKey, value, setValue)}
    </Modal>
  )
}

const getEmailKeyBrandingComponent = (emailBrandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData>, value: string, setValue: Dispatch<SetStateAction<string>>) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const handleHexColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if ((isValidHexColorCharacter(value[value.length - 1]) || value === '') && value.length <= 7) {
      setValue(value)
    }
  }

  if (emailBrandingKey === 'topLogo') {
    return (
      <div className='email-brand-container-rows'>
        <h3>Set Logo</h3>
        <TextInput placeholder='File URL' onChange={handleInputChange} value={value} />
        <p className='helper-text'>Required: PNG file in landscape aspect ratio (Ideal resolution: 180px * 48px)</p>
      </div>
    )
  }

  if (emailBrandingKey === 'bottomLogo') {
    return (
      <div className='email-brand-container-rows'>
        <h3>Set Logo</h3>
        <TextInput placeholder='File URL' onChange={handleInputChange} value={value} />
        <p className='helper-text'>Required: PNG file in landscape aspect ratio (Ideal resolution: 95px * 24px using a light gray color scale)</p>
      </div>
    )
  }

  if (emailBrandingKey === 'buttonColor') {
    return (
      <div className='email-brand-container-columns'>
        <div className='email-brand-container-rows'>
          <h3>Set buttons color</h3>
          <TextInput placeholder='Ex. #C20000' onChange={handleHexColorChange} value={value} />
          <p className='helper-text'>Tip: Use dark colors, white elements will be upfront.</p>
        </div>
        <div className='email-brand-container-center'>
          <Button backgroundColor={value && isValidHexColor(value) ? value : undefined}>Button</Button>
        </div>
      </div>
    )
  }

  if (emailBrandingKey === 'linkColor') {
    return (
      <div className='email-brand-container-columns'>
        <div className='email-brand-container-rows'>
          <h3>Set links color</h3>
          <TextInput placeholder='Ex. #C20000' onChange={handleHexColorChange} value={value} />
          <p className='helper-text'>Tip: Use dark colors, white elements will be upfront.</p>
        </div>
        <div className='email-brand-container-center'>
          <p className='email-brand-link-text'>...please ignore this email or <span style={{ color: value && isValidHexColor(value) ? value : undefined }}>contact us</span> for assistance</p>
        </div>
      </div>
    )
  }

  if (emailBrandingKey === 'emailSignature') {
    return (
      <div className='email-brand-container-columns'>
        <div className='email-brand-container-rows'>
          <h3>Set signature</h3>
          <TextArea onChange={handleInputChange} value={value} />
        </div>
        <div className='email-brand-container-center'>
          <p className='email-brand-signature-text'>{value || 'Warm regards,\nReply Pro'}</p>
        </div>
      </div>
    )
  }

  if (emailBrandingKey === 'footer') {
    return (
      <div className='email-brand-container-columns'>
        <div className='email-brand-container-rows'>
          <h3>Set footer</h3>
          <TextArea onChange={handleInputChange} value={value} />
        </div>
        <div className='email-brand-container-center'>
          <p className='email-brand-signature-text'>{value || 'Reply Pro | © 2023 Basedin, Inc.'}</p>
        </div>
      </div>
    )
  }

  if (emailBrandingKey === 'novuIdentifier') {
    return (
      <div className='email-brand-container-rows'>
        <h3>Set identifier</h3>
        <TextInput onChange={handleInputChange} />
        <p className='helper-text'>Use this field to change the email address sender from an existing <a className='novu-identifier-help-text' href='https://web.novu.co/workflows' target='_blank' rel='noreferrer'>provider on Novu</a>.</p>
      </div>
    )
  }
}

const isInputDisabled = (emailBrandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData>, value: string) => {
  if (value === '') {
    return false
  }

  if (['topLogo', 'bottomLogo'].includes(emailBrandingKey)) {
    return !isValidHttpsUri(value)
  }

  if (['buttonColor', 'linkColor'].includes(emailBrandingKey)) {
    return !isValidHexColor(value)
  }

  return false
}

export default EditEmailBrandingModal
