import { ACCOUNT_ADD_COLOR, ACCOUNT_ADD_DOMAIN, ACCOUNT_ADD_LOGO, ACCOUNT_ADD_NOVU_IDENTIFIER, ACCOUNT_ADD_PARAGRAPH } from 'requests/Accounts'
import { DocumentNode } from 'graphql'
import camelCaseToSnakeCase from 'utils/common/camelCase-to-snake_case'
import { parseWhiteLabelEmailData, parseWhiteLabelInAppData } from 'utils/whiteLabel/parseWhiteLabelData'

export const getRequest = (brandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData> | keyof ReturnType<typeof parseWhiteLabelInAppData>): DocumentNode => {
  if (['topLogo', 'bottomLogo', 'logo', 'loginLogo'].includes(brandingKey)) {
    return ACCOUNT_ADD_LOGO
  }

  if (['buttonColor', 'linkColor', 'brandColor', 'loginBrandColor'].includes(brandingKey)) {
    return ACCOUNT_ADD_COLOR
  }

  if (['emailSignature', 'footer'].includes(brandingKey)) {
    return ACCOUNT_ADD_PARAGRAPH
  }

  if (['domain'].includes(brandingKey)) {
    return ACCOUNT_ADD_DOMAIN
  }

  // Only option left would be Novu Identifier
  return ACCOUNT_ADD_NOVU_IDENTIFIER
}

export const getEmailPayload = (brandingKey: keyof ReturnType<typeof parseWhiteLabelEmailData>, value: string) => {
  if (['topLogo', 'bottomLogo'].includes(brandingKey)) {
    return {
      name: brandingKey.startsWith('email') ? camelCaseToSnakeCase(brandingKey) : `email_${camelCaseToSnakeCase(brandingKey)}`,
      url: value
    }
  }

  if (['buttonColor', 'linkColor'].includes(brandingKey)) {
    return {
      colorKey: brandingKey.startsWith('email') ? camelCaseToSnakeCase(brandingKey) : `email_${camelCaseToSnakeCase(brandingKey)}`,
      colorValue: value
    }
  }

  if (['emailSignature', 'footer'].includes(brandingKey)) {
    return {
      name: brandingKey.startsWith('email') ? camelCaseToSnakeCase(brandingKey) : `email_${camelCaseToSnakeCase(brandingKey)}`,
      content: value
    }
  }

  // Only option left would be Novu Identifier
  return { novuIdentifier: value }
}

export const getInAppPayload = (brandingKey: keyof ReturnType<typeof parseWhiteLabelInAppData>, value: string) => {
  if (['logo', 'loginLogo'].includes(brandingKey)) {
    return {
      name: brandingKey.startsWith('app') ? camelCaseToSnakeCase(brandingKey) : `app_${camelCaseToSnakeCase(brandingKey)}`,
      url: value
    }
  }

  if (['brandColor', 'loginBrandColor'].includes(brandingKey)) {
    return {
      colorKey: brandingKey.startsWith('app') ? camelCaseToSnakeCase(brandingKey) : `app_${camelCaseToSnakeCase(brandingKey)}`,
      colorValue: value
    }
  }

  // Only option left would be Domain
  return {
    domain: value
  }
}
