import { Button, SelectInput, TextInput } from '@reply-pro/component-library'
import './addNewUserModal.scss'
import { formConfig } from './utils'
import { ChangeEvent, SetStateAction } from 'react'
import Modal from 'components/Modal'
import { isValidEmail } from 'utils/common/contactValidator'
import { useHandleAddUser } from './hooks'
import { roles } from '../AddUsersModal/utils'
interface AddUserModalProps {
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    type: string;
}
handleInputChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string | undefined) => void
isAddNewUserModalOpen: boolean
setIsAddNewUserModalOpen: (value: SetStateAction<boolean>) => void
refetch: () => void
}
export default function AddNewUserModal ({ setIsAddNewUserModalOpen, formData, handleInputChange, isAddNewUserModalOpen, refetch } : AddUserModalProps) {
  const { handleAddUserButtonClick, loading } = useHandleAddUser({ setIsAddNewUserModalOpen, formData, refetch, handleInputChange })
  return (
    <Modal
      actions={
        <Button
          onClick={handleAddUserButtonClick}
          disabled={loading || Object.entries(formData).some(([key, value]) => (key !== 'lastName' && value.length < 1) || (key === 'email' && !isValidEmail(value)))}
        >Add New User
        </Button>
      }
      title='ADD NEW USER'
      toggleModalOpen={() => setIsAddNewUserModalOpen(false)}
      showModal={isAddNewUserModalOpen}
    >
      <div className='add-user-form'>
        {Object.keys(formConfig).map((fieldName) => {
          const { label, placeholder, mandatory, type, title } = formConfig[fieldName as keyof typeof formConfig]
          return (
            <div className={`${fieldName}-container input-container`} key={fieldName}>
              <div className='title'>
                {title}{mandatory && !label ? <span className='mandatory-sign'>*</span> : ''}
              </div>
              {type === 'select'
                ? (
                  <SelectInput
                    className='select-input'
                    placeholder={placeholder}
                    value={formData[fieldName as keyof typeof formConfig]}
                    onChange={(e) => handleInputChange(e as any, fieldName)}
                    label={label && `${label}${mandatory ? '*' : ''}`}
                  >
                    {Object.values(roles).map((option, index) => <option value={option} key={index + 'option'}>{option}</option>)}
                  </SelectInput>
                  )
                : (
                  <TextInput
                    className={fieldName}
                    placeholder={placeholder}
                    value={formData[fieldName as keyof typeof formConfig]}
                    onChange={(e) => handleInputChange(e, fieldName)}
                    label={label && `${label}${mandatory ? '*' : ''}`}
                    type={type}
                  />
                  )}
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
