import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LOCATION_GROUP, LOCATION_GROUP_EXPANDED } from 'requests/Accounts/locations'
import { LocationGroup } from 'types/location'
import { findAndAppendChild } from '../utils'

export function useGetLocationGroup ({ id }: { id?: string }) {
  const [childrenId, setChildrenId] = useState<string>('')
  const [locationGroup, setLocationGroup] = useState<LocationGroup>()
  const navigate = useNavigate()

  const { data, loading } = useQuery(LOCATION_GROUP, {
    variables: { id }
  })

  const [getChildren, { data: childrenData }] = useLazyQuery(LOCATION_GROUP_EXPANDED, {
    variables: { id: childrenId }
  })

  const onExpand = (id?: string) => {
    if (id) {
      setChildrenId(id)
      getChildren()
    }
  }

  const onClick = (id?: string) => {
    navigate(`/settings/location-permissions/groups/${id}`)
  }

  useEffect(() => {
    setChildrenId('')
    setLocationGroup(data?.locationGroup)
  }, [data])

  useEffect(() => {
    if (childrenId && childrenData) {
      setLocationGroup((prevData) => findAndAppendChild(prevData, childrenId, childrenData.locationGroup) as LocationGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenId, childrenData])

  return { locationGroup, loading, onClick, onExpand }
}
