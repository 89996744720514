import { SettingsHeader, AccountIcon } from '@reply-pro/component-library'

function AccountDetailsHeader () {
  return (
    <SettingsHeader
      title='Account Details'
      subtitle='Account details and branding options'
      icon={<AccountIcon />}
    />
  )
}

export default AccountDetailsHeader
