import { Route, Routes } from 'react-router-dom'
import LocationGroupsSettings from 'pages/Settings/LocationPermissionsSettings/Groups/LocationGroupsSettings'
import LocationPermissionsSettings from 'pages/Settings/LocationPermissionsSettings'
import HierarchiesSettings from 'pages/Settings/LocationPermissionsSettings/HierarchiesSettings'
import { useFetchLocationPermissions } from './hooks'

function LocationPermissionsRouter () {
  useFetchLocationPermissions()
  return (
    <Routes>
      <>
        <Route
          path='/groups/:id'
          element={<LocationGroupsSettings />}
        />
        <Route
          path='/hierarchies/:id'
          element={<HierarchiesSettings />}
        />
        <Route
          path='/*'
          element={<LocationPermissionsSettings />}
        />
      </>
    </Routes>
  )
}

export default LocationPermissionsRouter
