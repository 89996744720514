import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { RETRIEVE_CHANNELS } from 'requests/Settings'
import { channelQuery } from 'types/channel'

export const useGetChannels = (type: string) => {
  const [queryParam, setQueryParam] = useState('')
  const { data } : channelQuery = useQuery(RETRIEVE_CHANNELS, {
    variables: { first: 10, search: queryParam, type }
  })

  return { data, queryParam, setQueryParam }
}
