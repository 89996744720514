import { Button, CodingIcon, NavItem, PlayIcon, SettingsHeader, SettingsPageStructure } from '@reply-pro/component-library'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useMemo, useState } from 'react'
import EndpointModal from './EndpointModal'
import { data } from './data/webhooks-data'
import { createWebhooksRepository } from './data/webhooksRepository'
import { Endpoint } from './data/Webhooks.types'
import AstronautEmptyState from 'components/AstronautEmptyState'
import './Webhooks.scss'

const webhooksRepository = createWebhooksRepository(data)

const Webhooks = () => {
  const { accountId } = useSelector((state: RootState) => state.accounts)

  const accountEndpoints = useMemo(() => webhooksRepository.getAccountEndpoints(accountId), [accountId])

  const [showModal, setShowModal] = useState(false)
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint | undefined>(undefined)

  const handleClickWebhook = (selectedId: string) => {
    setSelectedEndpoint(accountEndpoints?.find(({ id }) => selectedId === id))
    setShowModal(true)
  }

  return (
    <>
      {showModal && <EndpointModal showModal={showModal} setShowModal={setShowModal} endpoint={selectedEndpoint} />}

      <SettingsPageStructure
        settingsHeader={
          <SettingsHeader
            title='Webhooks'
            icon={<CodingIcon />}
          />
        }
      >
        {accountEndpoints
          ? accountEndpoints.map(({ id, name, description }) => (
            <div key={id} className='client-webhooks-wrapper'>
              <NavItem>
                <div className='client-webhooks'>
                  <span className='client-webhooks-details'>
                    <div className='client-webhooks-details-name'>{name}</div>
                    <div className='client-webhooks-details-description'>{description}</div>
                  </span>
                  <Button stroked onClick={() => handleClickWebhook(id)} className='client-webhooks-details-button'><PlayIcon /> Run</Button>
                </div>
              </NavItem>
            </div>
          ))
          : <AstronautEmptyState copy="This account doesn't have Webhooks yet" />}
      </SettingsPageStructure>
    </>
  )
}

export default Webhooks
