import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { toggleAccountNotifications } from 'store/accountsSlice/accountsDispatchers'

export const useToggleAccountNotifications = () => {
  const dispatch: AppDispatch = useDispatch()

  const handleToggleNotifications = ({ id } : {id: string}) => {
    dispatch(toggleAccountNotifications(id))
  }

  return { handleToggleNotifications }
}
