import { Edit, NavItem, NavigationFolder, SettingsPageStructure } from '@reply-pro/component-library'
import AccountDetailsHeader from '../AccountDetailsHeader'
import { camelCaseToCapitalizedWords } from 'utils/common/camelCaseToCapitalizedWords'
import ComponentsShowroom from './ComponentsShowroom/ComponentsShowroom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useLazyQuery } from '@apollo/client'
import { WHITE_LABEL } from 'requests/Accounts'
import { useEffect, useMemo, useState } from 'react'
import { parseWhiteLabelInAppData } from 'utils/whiteLabel/parseWhiteLabelData'
import { WhiteLabel } from 'types/account'
import EditInAppBrandingModal from './EditInAppBrandingModal'
import './InAppWhiteLabel.scss'

const InAppWhiteLabel = () => {
  const { accountId } = useSelector((store: RootState) => store.accounts)

  const [getWhiteLabelData, { data }] = useLazyQuery<WhiteLabel>(WHITE_LABEL, { variables: { account: accountId } })
  const whiteLabelData = useMemo(() => data ? parseWhiteLabelInAppData(data.whiteLabel) : null, [data])

  const [showEditInAppBrandingModal, setShowEditInAppBrandingModal] = useState(false)
  const [inAppBrandingKey, setInAppBrandingKey] = useState<keyof ReturnType<typeof parseWhiteLabelInAppData> | null>(null)

  const handleShowInAppBrandingModal = (brandingKey: keyof ReturnType<typeof parseWhiteLabelInAppData>) => {
    setShowEditInAppBrandingModal(true)
    setInAppBrandingKey(brandingKey)
  }

  useEffect(() => {
    if (!data && accountId) {
      getWhiteLabelData()
    }
  }, [accountId, data, getWhiteLabelData])

  return (
    <>
      {showEditInAppBrandingModal && inAppBrandingKey &&
        <EditInAppBrandingModal
          initialValue={whiteLabelData ? whiteLabelData[inAppBrandingKey] : undefined}
          showEditInAppBrandingModal={showEditInAppBrandingModal}
          setShowEditInAppBrandingModal={setShowEditInAppBrandingModal}
          inAppBrandingKey={inAppBrandingKey}
          getWhiteLabelData={getWhiteLabelData}
        />}
      <SettingsPageStructure settingsHeader={<AccountDetailsHeader />}>
        <div className='in-app-page-container'>
          <NavigationFolder
            label='About this Account'
            large
            expanded
            labelRegular
            labelColor='gray'
            className='in-app-branding-nav-folder'
          >
            <div className='in-app-branding-options'>
              {whiteLabelData && (Object.keys(whiteLabelData) as Array<keyof typeof whiteLabelData>).map((key) => (
                <NavItem key={key} className='in-app-branding-option' onClick={() => handleShowInAppBrandingModal(key)}>
                  <div className='in-app-branding-container'>
                    <div className='in-app-branding-details'>
                      <div className='label'>{camelCaseToCapitalizedWords(key)}</div>
                      <div className='value'>{whiteLabelData[key] || 'Not provided'}</div>
                    </div>
                    <div className='in-app-branding-actions'>
                      <Edit className='edit' />
                    </div>
                  </div>
                </NavItem>
              ))}
            </div>
          </NavigationFolder>
          <ComponentsShowroom whiteLabelData={whiteLabelData} />
        </div>
      </SettingsPageStructure>
    </>
  )
}

export default InAppWhiteLabel
