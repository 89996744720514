import { gql } from '@apollo/client'

export const ACTIVITY_WORKFLOWS = gql`
  query activityWorkflows($offset: Int, $before: String, $after: String, $first: Int, $last: Int, $id: GlobalID) {
    activityWorkflows(offset: $offset, before: $before, after: $after, first: $first, last: $last, id: $id, type: "Task", defaultForType: "custom") {
      edges {
        node {
          id
          createdAt
          title
          description
          uuid
        }
      }
    }
  }
`
