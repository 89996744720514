import { useMutation } from '@apollo/client'
import { ChangeEvent, SetStateAction } from 'react'
import { ADD_NEW_USER_TO_ACCOUNT } from 'requests/Settings'
type useHandleAddUserProps = {
setIsAddNewUserModalOpen: (value: SetStateAction<boolean>) => void
formData: {
  firstName: string;
  lastName: string;
  email: string;
  type: string;
}
handleInputChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, attribute?: string | undefined) => void
refetch: () => void
}
export const useHandleAddUser = ({ setIsAddNewUserModalOpen, formData, refetch, handleInputChange } : useHandleAddUserProps) => {
  const [addUser, { loading }] = useMutation(ADD_NEW_USER_TO_ACCOUNT)

  const clearForm = () => {
    Object.keys(formData).forEach((key) => {
      const mockEvent = { target: { value: '' } } as ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
      handleInputChange(mockEvent, key)
    })
  }

  const handleAddUserButtonClick = async () => {
    try {
      const { data: userData } = await addUser({ variables: { input: formData } })
      if (userData?.userAddNewToAccount?.created?.id) {
        setIsAddNewUserModalOpen(false)
        refetch()
        clearForm()
      }
    } catch (error) {
      console.error('Error adding user:', error)
    }
  }

  return { handleAddUserButtonClick, loading }
}
