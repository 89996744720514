import { Routes, Route, Navigate } from 'react-router-dom'
import './settingsHeader.scss'

export default function SettingsHeader () {
  return (
    <Routes>
      <Route path='/manage-reports/*' />
      <Route
        path='/'
        element={<Navigate to='/settings/manage-reports' replace />}
      />
      <Route path='*' element={<div />} />
    </Routes>
  )
}
