import UserSelector from 'components/UserSelector'
import { AttributeChangeType } from '../constants'

interface AttributesUserSelectorProps extends AttributeChangeType {
    value: string
}

function AttributesUserSelector ({ value, onAttributeChange }: AttributesUserSelectorProps) {
  const handleSelectUser = ({ target }: { target: { value: string } }) => {
    onAttributeChange('user', target.value)
  }
  return (
    <UserSelector
      className='selector'
      user={value || ''}
      handleSelectUser={handleSelectUser}
    />
  )
}

export default AttributesUserSelector
