import { useQuery } from '@apollo/client'
import { HIERARCHY_GROUPS_BY_USER, LOCATION_GROUPS_BY_USER } from 'requests/Accounts/locations'
import { HierarchyGroupsData, LocationGroupsData } from 'types/location'

export const useGetLocationPermissions = (id?: string) => {
  const { data: locationGroupsData, loading: locationGroupsLoading } = useQuery<LocationGroupsData>(LOCATION_GROUPS_BY_USER, {
    variables: { user: id }
  })
  const { data: hierarchyGroupsData, loading: hierarchyGroupsLoading } = useQuery<HierarchyGroupsData>(HIERARCHY_GROUPS_BY_USER, {
    variables: { user: id }
  })

  return { locationGroupsData, locationGroupsLoading, hierarchyGroupsData, hierarchyGroupsLoading }
}
