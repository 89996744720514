import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { TagGroupsEdge } from 'types/tagGroup'
import { filterTagGroupByGroup } from 'utils/tagging/TagGroups'

const sortTagGroup = (tagGroup: TagGroupsEdge[], alphabeticSort = false) =>
  [...tagGroup].sort((groupA: TagGroupsEdge, groupB: TagGroupsEdge) => {
    if (!alphabeticSort) {
      return groupA.node.order - groupB.node.order
    }
    if (groupA.node.description.toLocaleLowerCase() < groupB.node.description.toLocaleLowerCase()) {
      return -1
    }
    if (groupA.node.description.toLocaleLowerCase() > groupB.node.description.toLocaleLowerCase()) {
      return 1
    }
    return 0
  })

export const useTagGroupsFilteredByEntities = (entities: string[], alphabeticSort = false) => {
  const { data, loading, error } = useSelector((store: RootState) => store.tags.tagGroups)
  let filteredTagGroup = data && filterTagGroupByGroup(data!.tagGroups.edges, entities)
  filteredTagGroup = filteredTagGroup && sortTagGroup(filteredTagGroup, alphabeticSort)

  return { filteredTagGroup, loading, error }
}
