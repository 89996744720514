import { Tab, Tabs } from '@reply-pro/component-library'
import ManageUsers from './ManageUsers'

function UsersTabs () {
  return (
    <div className='full-screen-card-tabs-settings'>
      <div className='tabs-settings-container'>
        <Tabs
          tabHeaderWidth='30rem'
          tabHeaderTopMargin='1.5rem'
          smallTab
        >
          <div className='tabs-container'>
            <Tab
              key='enabled-tab'
              label='ACTIVE USERS'
            >
              <ManageUsers
                viewingActive
              />
            </Tab>
            <Tab
              key='disabled-tab'
              label='INACTIVE USERS'
            >
              <ManageUsers
                viewingActive={false}
              />
            </Tab>
          </div>
        </Tabs>
      </div>
    </div>
  )
}

export default UsersTabs
