export const jsonLogicOperators = {
  EQUALS: '===',
  NOT_EQUALS: '!=='
}

export const getAnswerFormat = (answer?: string | number) => {
  if (answer === 'true' || answer === 'false') {
    return answer
  }
  if (!isNaN(Number(answer))) {
    return Number(answer)
  }
  return `"${answer}"`
}
