import { AstronautEmptyState, ListOfElementsToNavigate, SettingsPageStructure } from '@reply-pro/component-library'
import LocationPermissionsHeader from '../LocationPermissionsHeader'
import LocationPermissionsAside from '../LocationPermissionsAside'
import { RootState } from 'store'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { LOCATION_PERMISSIONS } from '../constants'
import HierarchyGroupViewer from './HierarchyGroupViewer'
import { useSelector } from 'react-redux'

const { HIERARCHIES } = LOCATION_PERMISSIONS

function HierarchiesSettings () {
  const { data, loading } = useSelector((store: RootState) => store.locations.hierarchyGroups)
  const params = useParams()
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <SettingsPageStructure
      settingsHeader={<LocationPermissionsHeader />}
      asideChildren={
        <LocationPermissionsAside parent={HIERARCHIES}>
          <>
            <ListOfElementsToNavigate
              loadingList={loading}
              NavLink={NavLink}
              noElementsToShow={
                <div className='no-location-permissions-container'>
                  <div className='astronaut-image'>
                    <AstronautEmptyState />
                  </div>
                  <div className='text'>
                    <span>You have no Location Groups!</span>
                  </div>
                </div>
              }
              listOfElements={
                data &&
                data.hierarchyGroups.edges.map((hierarchyGroupEdge, index: number) => {
                  const { id, name } = hierarchyGroupEdge.node
                  return {
                    id,
                    name,
                    url: `location-permissions/hierarchies/${id}`,
                    index,
                    label: hierarchyGroupEdge?.node?.label?.name
                  }
                })
              }
              params={params}
              navigate={navigate}
            />
          </>
        </LocationPermissionsAside>
      }
    >
      <HierarchyGroupViewer
        viewingHierarchyGroup={id}
        noHierarchyGroups={data?.hierarchyGroups?.edges?.length === 0}
      />
    </SettingsPageStructure>
  )
}

export default HierarchiesSettings
