import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, SearchInput, LoadingSpinner, NavItem } from '@reply-pro/component-library'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { RootState } from 'store'
import { retrieveReportCollectionsList, addReportCollection } from 'store/settingsSlice/settingsReducers'
import { useDispatchAction } from 'utils/common/useDispatchAction'
import './manageReportsCollections.scss'
import AddNewCollectionModal from '../AddNewModal'
import CollectionOptions from './CollectionOptions'

function ManageReportsCollections () {
  const [isAddNewCollectionModalOpen, setIsAddNewCollectionModalOpen] = useState(false)
  const { data } = useSelector((state: RootState) => state.settings.reportCollections)
  const { pathname } = useLocation()

  const toggleIsAddNewCollectionModalOpen = () => {
    setIsAddNewCollectionModalOpen((prev) => !prev)
  }

  useDispatchAction(retrieveReportCollectionsList)
  return (
    <div className='manage-reports-wrapper'>
      <div className='report-collections-actions'>
        <Button className='add-collection-button' onClick={toggleIsAddNewCollectionModalOpen}>Add New Collection</Button>
        <SearchInput><option value='none'>none</option><option value='2'>none</option></SearchInput>
        <AddNewCollectionModal
          toggleIsAddNewModalOpen={toggleIsAddNewCollectionModalOpen}
          isAddNewModalOpen={isAddNewCollectionModalOpen}
          dispatchAddNew={addReportCollection}
          inputLabel='name of collection'
          title='NEW COLLECTION'
        >
          <h3>Collection Name</h3>
          <div>Collections organize your sectioned reports.</div>
        </AddNewCollectionModal>
      </div>
      <div className='report-collections-list'>
        {!data
          ? <div className='central-loading-spinner'><LoadingSpinner /></div>
          : data?.reportCollections?.edges.length > 0
            ? data?.reportCollections?.edges.map(({ node: collection }) =>
              <div key={collection.id} className='collection-nav-item'>
                <NavItem
                  component={NavLink}
                  to={`/settings/manage-reports/collection/${collection.id}`}
                  className={classNames({ active: pathname === `/settings/manage-reports/collection/${collection.id}` })}
                >
                  <div className='collection-name'>
                    {collection.name.toUpperCase()}
                  </div>
                </NavItem>
                <CollectionOptions collectionId={collection.id} />
              </div>
            )
            : <span className='no-items-found'>No collections for current account</span>}
      </div>
    </div>
  )
}

export default ManageReportsCollections
