import { createSlice } from '@reduxjs/toolkit'
import { tagsState } from '../types.tags'
import { createNewTagField, createNewTagGroup, retrieveTagGroups, setGroupConditions } from './dispatchers.tags'
import { addChoiceConditions, removeChoiceFromConditions } from 'utils/tagging/taggingConditions'
import { createIndexedTagFieldsList } from 'utils/tagging/tagFields'

const initialState: tagsState = {
  tagGroups: {
    data: undefined,
    loading: true,
    error: undefined
  },
  tagFields: {
    indexedById: undefined,
    indexedByUuid: undefined
  },
  creatingTagGroup: {
    loading: false,
    success: false,
    error: false
  },
  creatingTagField: {
    loading: false,
    success: false,
    error: false
  },
  settingGroupCondition: {
    loading: false,
    success: false,
    error: false
  },
  conditions: {}
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    addChoiceConditionsToGroup: (state, action) => {
      state.conditions = addChoiceConditions({ ...action.payload, conditions: state.conditions })
    },
    removeChoiceConditionsFromGroup: (state, action) => {
      state.conditions = removeChoiceFromConditions({ ...action.payload, conditions: state.conditions })
    },
    initializeConditions: (state, action) => {
      state.conditions = action.payload
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(retrieveTagGroups.pending, ({ tagGroups }) => {
      tagGroups.loading = true
    })
    addCase(retrieveTagGroups.fulfilled, (state, action) => {
      state.tagGroups.loading = false
      state.tagGroups.error = undefined
      state.tagGroups.data = action.payload
      const indexedTagFields = createIndexedTagFieldsList(action.payload.tagGroups.edges)
      state.tagFields = indexedTagFields
    })

    addCase(retrieveTagGroups.rejected, (state, action) => {
      state.tagGroups.loading = false
      state.tagGroups.error = action.error
      state.tagGroups.data = undefined
    })
    addCase(createNewTagGroup.pending, (state) => {
      state.creatingTagGroup.loading = true
      if (state.creatingTagGroup.success) {
        state.creatingTagGroup.success = false
      }
    })

    addCase(createNewTagGroup.fulfilled, (state, action) => {
      state.creatingTagGroup.loading = false
      state.creatingTagGroup.success = true
    })

    addCase(createNewTagGroup.rejected, (state, action) => {
      state.creatingTagGroup.loading = false
      state.creatingTagGroup.error = true
      if (state.creatingTagGroup.success) {
        state.creatingTagGroup.success = false
      }
    })
    addCase(createNewTagField.pending, (state) => {
      state.creatingTagField.loading = true
      if (state.creatingTagField.success) {
        state.creatingTagField.success = false
      }
    })

    addCase(createNewTagField.fulfilled, (state, action) => {
      state.creatingTagField.loading = false
      state.creatingTagField.success = true
    })

    addCase(createNewTagField.rejected, (state, action) => {
      state.creatingTagField.loading = false
      state.creatingTagField.error = true
      if (state.creatingTagField.success) {
        state.creatingTagField.success = false
      }
    })

    addCase(setGroupConditions.pending, (state) => {
      state.settingGroupCondition.loading = true
      if (state.settingGroupCondition.success) {
        state.settingGroupCondition.success = false
      }
    })

    addCase(setGroupConditions.fulfilled, (state, action) => {
      state.settingGroupCondition.loading = false
      state.settingGroupCondition.success = true
    })

    addCase(setGroupConditions.rejected, (state, action) => {
      state.settingGroupCondition.loading = false
      state.settingGroupCondition.error = true
      if (state.settingGroupCondition.success) {
        state.settingGroupCondition.success = false
      }
    })
  }
})

export const { addChoiceConditionsToGroup, removeChoiceConditionsFromGroup, initializeConditions } = tagsSlice.actions
export default tagsSlice.reducer
