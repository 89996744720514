import { createSlice } from '@reduxjs/toolkit'
import locationsState from './types.locations'
import { retrieveHierarchyGroups, retrieveLocationGroups, retrieveLocations } from './dispatchers.locations'

const initialState: locationsState = {
  locationGroups: {
    data: undefined,
    loading: false,
    error: undefined
  },
  hierarchyGroups: {
    data: undefined,
    loading: false,
    error: undefined
  },
  locations: {
    data: undefined,
    loading: false,
    error: undefined
  },
  locationsSelected: []
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clearLocationPermissions: (state) => {
      state.locationGroups = initialState.locationGroups
      state.hierarchyGroups = initialState.hierarchyGroups
    },
    setSelectedLocations: (state, action) => {
      state.locationsSelected = action.payload
    }
  },
  extraReducers: ({ addCase }) => {
    addCase(retrieveLocationGroups.pending, (state) => {
      state.locationGroups.loading = true
    })

    addCase(retrieveLocationGroups.rejected, (state, action) => {
      state.locationGroups.loading = false
    })

    addCase(retrieveLocationGroups.fulfilled, (state, action) => {
      state.locationGroups.data = action.payload
      state.locationGroups.loading = false
    })
    addCase(retrieveHierarchyGroups.pending, (state) => {
      state.hierarchyGroups.loading = true
    })

    addCase(retrieveHierarchyGroups.rejected, (state) => {
      state.hierarchyGroups.loading = false
    })

    addCase(retrieveHierarchyGroups.fulfilled, (state, action) => {
      state.hierarchyGroups.data = action.payload
      state.hierarchyGroups.loading = false
    })
    addCase(retrieveLocations.pending, (state) => {
      state.locations.loading = true
    })

    addCase(retrieveLocations.rejected, (state) => {
      state.locations.loading = false
    })

    addCase(retrieveLocations.fulfilled, (state, action) => {
      state.locations.data = action.payload
      state.locations.loading = false
    })
  }
})

export const { clearLocationPermissions, setSelectedLocations } = locationsSlice.actions

export default locationsSlice.reducer
