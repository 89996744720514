import { TextInput } from '@reply-pro/component-library'
import { SurveyDetailsTypes } from './hooks'
import { isValidHttpsUri } from 'utils/common/isValidHttpsUri'

interface PrivacyPolicySetupProps {
    surveyDetails: SurveyDetailsTypes
    handleChange: (key: keyof SurveyDetailsTypes, value: string | boolean) => void
}

function PrivacyPolicySetup ({ surveyDetails, handleChange }: PrivacyPolicySetupProps) {
  return (
    <div className='privacy-policy-inputs'>
      <TextInput
        className='detail-input'
        value={surveyDetails.customPrivacyPolicyText}
        onChange={(e) => handleChange('customPrivacyPolicyText', e.target.value)}
        error={!surveyDetails.customPrivacyPolicyText}
        placeholder='Visible text'
      />
      <TextInput
        className='detail-input'
        value={surveyDetails.customPrivacyPolicyLink}
        onChange={(e) => handleChange('customPrivacyPolicyLink', e.target.value)}
        error={!isValidHttpsUri(surveyDetails.customPrivacyPolicyLink)}
        placeholder='https://example.com'
      />
    </div>
  )
}

export default PrivacyPolicySetup
