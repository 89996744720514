/* eslint-disable no-unused-vars */
export enum ENTITIES {
  INTERACTION = 'Interaction',
  ACTION = 'Action',
  CONTACT = 'Contact',
  LOCATION = 'Location',
  CAMPAIGN = 'Campaign'
}

export type entity = (typeof ENTITIES)[keyof typeof ENTITIES]

export interface AttributeChangeType {
  onAttributeChange: (attribute: string, value: any) => void
}

export const TRIGGER_TYPES = { QUESTION: 'The person has replied to a specific question', SUBMIT: 'The person has submitted the last question' }

export const POST_ACTIONS = {
  MARK_AS_OPEN: 'Mark Interaction as Open',
  TAG: 'Tag Interaction',
  AUTO_REPLY: 'Auto Reply',
  CREATE_ACTIVITY: 'Create Activity',
  MATCH_PURCHASE: 'Match Purchase',
  CREATE_CHARTICKET: 'Create Charticket'
}
export const AVAILABLE_ACTIONS = Object.values(POST_ACTIONS)
