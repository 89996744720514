import { createAsyncThunk } from '@reduxjs/toolkit'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { request } from 'api'
import { AppDispatch, RootState } from 'store'
import { waitForAccounts } from 'store/waitForAccounts'
import { AttachFileParams, AttachFileResponse } from './filesTypes'

export const attachFile = createAsyncThunk(
  'files/attachFile',
  async ({ file } : AttachFileParams, thunkApi) => {
    try {
      await waitForAccounts(thunkApi as BaseThunkAPI<RootState, null, AppDispatch>)
      const currentState = thunkApi.getState() as RootState
      const accountId = currentState.accounts.accountId
      const { access } = currentState.login
      const account = currentState.accounts.accounts.data?.accounts.edges.find(({ node: { id } }) => id === accountId)

      if (!account) {
        throw new Error('Failed to retrieve account')
      }

      const form = new FormData()

      form.append('file', file)
      form.append('mimetype', file.type)
      form.append('name', file.name)

      const response: AttachFileResponse = await request({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVICES_BASE}/files`,
        requestOptions: {
          body: form,
          headers: {
            Authorization: `Bearer ${access}`,
            cacct: accountId
          }
        }
      })
      return { response, name: file.name }
    } catch (e:any) {
      throw new Error(e)
    }
  }
)
