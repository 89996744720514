import { createSlice } from '@reduxjs/toolkit'
import { formState } from './formTypes'

const initialState: formState = {
  savedForm: {
    data: {}
  }
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    saveForm: (state, action) => {
      state.savedForm.data[action.payload.key] = action.payload.value
    },
    resetForm: (state) => {
      state.savedForm.data = {}
    },
    populateForm: (state, action) => {
      resetForm()
      state.savedForm.data = {
        name: action.payload.name,
        content: action.payload.content,
        userSetVariables: action.payload.userSetVariables
      }
    }
  }
})

export const { saveForm, resetForm, populateForm } = formSlice.actions
export default formSlice.reducer
