import { NavItem } from '@reply-pro/component-library'
import classNames from 'classnames'
import { MouseEventHandler } from 'react'
import { userGroupNode } from 'store/settingsSlice/settingsTypes'
import EditUserGroup from './EditUserGroup'
import RemoveUserGroup from './RemoveUserGroup'
import './UserGroup.scss'

interface userGroupProps {
  userGroup: userGroupNode
  active: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  count: number
}

const UserGroup = ({ userGroup, active, onClick, count }: userGroupProps) => {
  return (
    <NavItem
      className={classNames({ active }, 'user-group')}
      onClick={onClick}
    >
      <span className='user-group-name'>{userGroup.node.name} ({count})</span>
      <EditUserGroup
        id={userGroup.node.id}
        name={userGroup.node.name}
        description={userGroup.node.description}
        className='user-group-edit-icon'
      />
      <RemoveUserGroup id={userGroup.node.id} name={userGroup.node.name} />
    </NavItem>
  )
}

export default UserGroup
