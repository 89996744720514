import { LoadingSpinner, NavItem } from '@reply-pro/component-library'
import { user } from 'types/user'
import UserPasswordManager from './UserPasswordManager/UserPasswordManager'
import UserPermissions from './UserPermissions/UserPermissions'
import useUserStates from './hooks'
import UserActions from './UserActions'

interface UserPropsList {
  users: {
    users: {
      edges: {
        node: user;
      }[];
    };
  } | undefined
  loading: boolean
  viewingActive: boolean
}

export default function UserList ({ users, loading, viewingActive }: UserPropsList) {
  const { editingUser, modals, setModals, handlePasswordManager, handleUserPermissions } = useUserStates()

  return (
    <div className='users-container'>
      {loading
        ? <div className='users-loading'><LoadingSpinner size='small' /></div>
        : (
            users?.users?.edges?.map((user, index) => (
              <NavItem className='user-container' key={index + '-' + user.node.id}>
                <div className='user'>
                  <div className='name-and-email-container'>
                    <div className='name'>{user.node.firstName || user.node.email.split('@')[0].split('.')[0]} {user.node.lastName}</div>
                    <div className='email'>{user.node.email}</div>
                  </div>
                  <div className='user-actions'>
                    <div className='user-type'>
                      {user.node.isSuperuser ? 'SUPERUSER' : user.node.isEmployee ? 'EMPLOYEE' : 'USER'}
                    </div>
                    <UserActions handlePasswordManager={handlePasswordManager} handleUserPermissions={handleUserPermissions} user={user} viewingActive={viewingActive} />
                  </div>
                </div>
              </NavItem>
            ))
          )}
      {modals.isPasswordManagerOpen && <UserPasswordManager isPasswordManagerOpen={modals.isPasswordManagerOpen} setIsPasswordManagerOpen={(value) => setModals({ ...modals, isPasswordManagerOpen: value })} editingUser={editingUser} />}
      {modals.isPermissionsModalOpen && <UserPermissions isPermissionsModalOpen={modals.isPermissionsModalOpen} setIsPermissionsModalOpen={(value) => setModals({ ...modals, isPermissionsModalOpen: value })} userDetails={editingUser} />}
    </div>
  )
}
