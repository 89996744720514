import { LoadingSpinner } from '@reply-pro/component-library'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'

function LocationPermissionsSettings () {
  const navigate = useNavigate()

  const { data, loading, error } = useSelector((store: RootState) => store.locations.locationGroups)

  useEffect(() => {
    if (data && !loading) {
      navigate(`/settings/location-permissions/groups/${data.locationGroups?.edges?.[0]?.node?.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  return (
    <div className='location-permissions-page'>
      <LoadingSpinner size='small' />
    </div>
  )
}

export default LocationPermissionsSettings
