import { CheckInput, FileUploadIcon, RoundCheckIcon, TextInput } from '@reply-pro/component-library'
import { ChangeEventHandler } from 'react'
import { BooleanEndpointInput, FileEndpointInput, Input, NumberEndpointInput, StringEndpointInput } from '../../data/Webhooks.types'
import './InputFields.scss'
import classNames from 'classnames'

const isFileInput = (input: Input): input is FileEndpointInput => input.type === 'file'
const isBooleanInput = (input: Input): input is BooleanEndpointInput => input.type === 'boolean'
const isNumberInput = (input: Input): input is NumberEndpointInput => input.type === 'number'
const isStringInput = (input: Input): input is StringEndpointInput => input.type === 'string'

export const renderInputField = (input: Input, onChange?: ChangeEventHandler<HTMLInputElement>, value?: boolean | string | File) => {
  if (isFileInput(input)) {
    return (
      <div className='endpoint-input-field'>
        <span className='endpoint-input-field-title'>{input.title}{input.required && <span className='endpoint-input-field-required'>*</span>}</span>
        <label className={classNames('endpoint-input-field-component', { success: value })}>
          <input type='file' onChange={onChange} accept={input.formats.join(',')} />
          <span>{value ? <RoundCheckIcon /> : <FileUploadIcon />}{(value as undefined | File)?.name || 'Select a file'}</span>
        </label>
      </div>
    )
  } else if (isBooleanInput(input)) {
    return (
      <div className='endpoint-input-field'>
        <CheckInput
          className='endpoint-input-field-check-input'
          label={<>{input.title}{input.required && <span className='endpoint-input-field-required'>*</span>}</>}
          onChange={onChange}
          checked={value as boolean}
        />
      </div>
    )
  } else if (isNumberInput(input)) {
    return (
      <div className='endpoint-input-field'>
        <label className='endpoint-input-field-title'>{input.title}{input.required && <span className='endpoint-input-field-required'>*</span>}</label>
        <TextInput
          type='number'
          min={input.min}
          max={input.max}
          required={input.required}
          onChange={onChange}
          value={value as string}
        />
      </div>
    )
  } else if (isStringInput(input)) {
    return (
      <div className='endpoint-input-field'>
        <label className='endpoint-input-field-title'>{input.title}{input.required && <span className='endpoint-input-field-required'>*</span>}</label>
        <TextInput
          type='text'
          maxLength={input.maxLength}
          required={input.required}
          onChange={onChange}
          value={value as string}
        />
      </div>
    )
  } else {
    return null
  }
}
