import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import AccountsState, { FeatureMaintenancePayload } from './accountsTypes'
import { retrieveAccounts, retrieveFeatureMaintenance, setAccount, toggleAccountNotifications, setFeatureMaintenance, setAccountFeatures } from './accountsDispatchers'
import { updateFeaturesData, updateNotificationsInData } from './functions'

const initialState: AccountsState = {
  accountId: '',
  accounts: {
    data: undefined,
    loading: true,
    error: undefined
  },
  toggleAccountLoading: false,
  featureMaintenance: {
    loading: false,
    reporting: {
      isMaintenance: undefined,
      status: undefined,
      message: undefined
    },
    inbox: {
      isMaintenance: undefined,
      status: undefined,
      message: undefined
    },
    activities: {
      isMaintenance: undefined,
      status: undefined,
      message: undefined
    }
  },
  successMessage: null,
  errorMessage: null
}

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveAccounts.pending, (state, action) => {
      state.accounts.loading = true
    })

    builder.addCase(retrieveAccounts.fulfilled, (state, action) => {
      state.accounts.loading = false
      state.accounts.error = undefined
      state.accounts.data = action.payload

      if (!state.accountId && action.payload.accounts.edges.length > 0) {
        state.accountId = action.payload.accounts.edges[0].node.id
      }
    })

    builder.addCase(retrieveAccounts.rejected, (state, action) => {
      state.accounts.loading = false
      state.accounts.error = action.error
    })

    builder.addCase(setAccount.pending, (state) => {
      state.accounts.loading = true
    })

    builder.addCase(setAccount.fulfilled, (state, action) => {
      state.accounts.loading = false
      state.accountId = action.payload
    })

    builder.addCase(setAccount.rejected, (state) => {
      state.accounts.loading = false
    })

    builder.addCase(toggleAccountNotifications.pending, (state) => {
      state.toggleAccountLoading = true
    })

    builder.addCase(toggleAccountNotifications.fulfilled, (state, action) => {
      state.toggleAccountLoading = false
      state.accounts.data = updateNotificationsInData(state, action)
    })

    builder.addCase(setAccountFeatures.fulfilled, (state, action) => {
      state.accounts.data = updateFeaturesData(state, action)
    })

    builder.addCase(toggleAccountNotifications.rejected, (state) => {
      state.toggleAccountLoading = false
    })

    builder.addCase(setFeatureMaintenance.pending, (state) => {
      state.featureMaintenance.loading = true
    })

    builder.addCase(setFeatureMaintenance.fulfilled, (state) => {
      state.featureMaintenance.loading = false
    })

    builder.addCase(setFeatureMaintenance.rejected, (state) => {
      state.featureMaintenance.loading = false
    })

    builder.addCase(retrieveFeatureMaintenance.pending, (state) => {
      state.featureMaintenance.loading = true
    })

    builder.addCase(retrieveFeatureMaintenance.fulfilled, (state, action: PayloadAction<FeatureMaintenancePayload>) => {
      state.featureMaintenance.loading = false

      const featureMap: Record<keyof FeatureMaintenancePayload, keyof FeatureMaintenancePayload> = {
        reporting: 'reporting',
        inbox: 'inbox',
        activities: 'activities'
      }

      Object.keys(featureMap).forEach((key) => {
        const typedKey = key as keyof FeatureMaintenancePayload
        state.featureMaintenance[featureMap[typedKey]] = action.payload[typedKey]
      })
    })

    builder.addCase(retrieveFeatureMaintenance.rejected, (state) => {
      state.featureMaintenance.loading = false
    })
  }
})

export default accountsSlice.reducer
