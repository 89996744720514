import Modal from 'components/Modal'
import { user } from 'types/user'
import './UserPasswordManager.scss'
import { Button } from '@reply-pro/component-library'
import { useState } from 'react'
import ChangePasswordModal from './ChangePasswordModal'
import { usePasswordManager } from './hooks'

interface UserPasswordManagerProps {
  isPasswordManagerOpen: boolean,
  setIsPasswordManagerOpen: (bool: boolean) => void,
  editingUser?: user
}

function UserPasswordManager ({ isPasswordManagerOpen, setIsPasswordManagerOpen, editingUser }: UserPasswordManagerProps) {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)
  const { handleRequestPasswordReset, loading } = usePasswordManager({ setModalOpen: setIsPasswordManagerOpen })
  return (
    <>
      <Modal
        showModal={isPasswordManagerOpen}
        toggleModalOpen={() => setIsPasswordManagerOpen(false)}
        title='Manage user password'
        actions={
          <div className='user-manager-modal-actions'>
            <Button stroked onClick={() => handleRequestPasswordReset({ email: editingUser?.email })} disabled={loading}>Send password reset</Button>
            <Button backgroundColor='red2' onClick={() => setIsChangePasswordModalOpen(true)} disabled={loading}>Change password</Button>
          </div>
        }
      >
        <div className='user-manager-modal-body'>
          What change do you want to make for {editingUser?.firstName || editingUser?.email}?
        </div>
      </Modal>
      {isChangePasswordModalOpen && <ChangePasswordModal isChangePasswordModalOpen={isChangePasswordModalOpen} setIsChangePasswordModalOpen={setIsChangePasswordModalOpen} editingUser={editingUser} />}
    </>
  )
}

export default UserPasswordManager
