import { LoadingSpinner, NavigationFolder, TreeView } from '@reply-pro/component-library'
import { useGetLocationGroup } from './hooks'
import NoResultsCaption from 'components/NoResultsCaption'
import '../LocationPermissions.scss'
import LocationParentNav from './LocationParentNav'

interface LocationGroupViewerProps {
  viewingLocationGroup?: string
  noLocationGroups?: boolean
}

function LocationGroupViewer ({ viewingLocationGroup, noLocationGroups }: LocationGroupViewerProps) {
  const { locationGroup, loading, onClick, onExpand } = useGetLocationGroup({ id: viewingLocationGroup })
  return (
    <div>
      {noLocationGroups
        ? (
          <div className='empty-permission-group-viewer'>
            <NoResultsCaption />
          </div>
          )
        : (
          <>
            <div className='right-details'>{locationGroup?.name || 'Loading...'}</div>
            <div className='permission-group-viewer'>
              {loading
                ? (
                  <LoadingSpinner size='small' />
                  )
                : (
                  <>
                    <NavigationFolder
                      label='Users in this group'
                      large
                      expanded
                      collapsible={false}
                      className='users-nav'
                    >
                      <div className='user-list-container'>
                        {locationGroup?.users.edges.map((user, index) => {
                          const displayName = user?.node?.firstName && user?.node?.lastName ? (user?.node?.firstName || '') + ' ' + (user?.node?.lastName || '') : user.node.email

                          return (
                            <div className='single-user' key={index}>
                              {displayName}
                            </div>
                          )
                        })}
                      </div>
                    </NavigationFolder>
                    <NavigationFolder
                      label='Locations for this group'
                      large
                      expanded
                      collapsible={false}
                      className='locations-nav'
                    >
                      <div className='locations-tree-container'>
                        {locationGroup?.parents?.edges!?.length > 0 && (
                          <div className='location-parents'>
                            {locationGroup?.parents?.edges!.map((parent) => (
                              <LocationParentNav
                                key={parent?.node?.id}
                                id={parent?.node?.id}
                                name={parent?.node?.name}
                              />
                            ))}
                          </div>
                    )}
                        {locationGroup && (
                          <TreeView
                            data={locationGroup}
                            onExpand={onExpand}
                            onClick={onClick}
                          />
                        )}
                      </div>
                    </NavigationFolder>
                  </>
                  )}
            </div>
          </>
          )}
    </div>
  )
}

export default LocationGroupViewer
