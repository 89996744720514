import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { addReportPage } from 'store/settingsSlice/settingsReducers'
import ReportEditor from 'components/ReportEditor'

interface CreateReportModalProps {
  isShowCreateReportModal: boolean
  toggleIsShowCreateReportModal: () => void
  reportSectionId: string
}

export default function CreateReportModal ({ isShowCreateReportModal, toggleIsShowCreateReportModal, reportSectionId }: CreateReportModalProps) {
  const users = useSelector((state: RootState) => state.settings.reportPageUsers)
  const userGroups = useSelector((state: RootState) => state.settings.reportPageUserGroups)

  const [reportName, setReportName] = useState('')
  const [reportLink, setReportLink] = useState('')
  const [isBasicEmbedType, setIsBasicEmbedType] = useState(true)
  const [reportDashboardId, setReportDashboardId] = useState('')
  const [isForAllUsers, setIsForAllUsers] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [addedFilters, setAddedFilters] = useState<Record<string, string[] | null> | undefined>(undefined)

  const dispatch = useDispatch<AppDispatch>()

  const toggleModalOpen = () => {
    toggleIsShowCreateReportModal()
    setReportName('')
    setIsBasicEmbedType(true)
    setReportLink('')
    setReportDashboardId('')
  }

  const addNewReportPage = () => {
    dispatch(addReportPage({
      reportSectionId,
      name: reportName,
      isForAllUsers,
      link: reportLink,
      remoteId: !isBasicEmbedType ? reportDashboardId : undefined,
      authorized: isAuthorized,
      parameters: addedFilters
    }))
    toggleModalOpen()
  }

  return (
    <ReportEditor
      title='New Report'
      isShowModal={isShowCreateReportModal}
      toggleModalOpen={toggleModalOpen}
      reportName={reportName}
      isBasicEmbedType={isBasicEmbedType}
      reportLink={reportLink}
      onSubmit={addNewReportPage}
      setReportName={setReportName}
      setIsBasicEmbedType={setIsBasicEmbedType}
      setReportLink={setReportLink}
      setReportDashboardId={setReportDashboardId}
      isForAllUsers={isForAllUsers}
      setIsForAllUsers={setIsForAllUsers}
      submitText='Add'
      addedFilters={addedFilters}
      setAddedFilters={setAddedFilters}
      isAuthorized={isAuthorized}
      setIsAuthorized={setIsAuthorized}
      disabled={(!isForAllUsers && Object.values(users).length === 0 && Object.values(userGroups).length === 0) || !reportName || !reportLink}
      disabledReason={!reportName ? 'Input a report name to proceed' : !reportLink ? 'Input a report link (basic) or a URL (custom) to proceed' : 'Toggle ‘All Users’ or select individual users to proceed.'}
    />
  )
}
