import { LoadingSpinner, NavigationFolder, TreeView } from '@reply-pro/component-library'
import NoResultsCaption from 'components/NoResultsCaption'
import { useGetHierarchy } from './hooks'
import '../LocationPermissions.scss'

interface HierarchyGroupViewerProps {
  viewingHierarchyGroup?: string
  noHierarchyGroups?: boolean
}

function HierarchyGroupViewer ({ viewingHierarchyGroup, noHierarchyGroups }: HierarchyGroupViewerProps) {
  const { hierarchyGroup, loading, onClick, onExpand } = useGetHierarchy({ id: viewingHierarchyGroup })
  return (
    <div>
      {noHierarchyGroups
        ? (
          <div className='empty-permission-group-viewer'>
            <NoResultsCaption />
          </div>
          )
        : (
          <>
            <div className='right-details'>{hierarchyGroup?.name || 'Loading...'}</div>
            <div className='permission-group-viewer'>
              {loading
                ? (
                  <LoadingSpinner size='small' />
                  )
                : (
                  <>
                    <NavigationFolder
                      label='Users in this group'
                      large
                      expanded
                      collapsible={false}
                      className='users-nav'
                    >
                      <div className='user-list-container'>
                        {hierarchyGroup?.users.edges.map((user, index) => {
                          const displayName = user?.node?.firstName && user?.node?.lastName ? (user?.node?.firstName || '') + ' ' + (user?.node?.lastName || '') : user.node.email
                          return (
                            <div className='single-user' key={index}>
                              {displayName}
                            </div>
                          )
                        })}
                      </div>
                    </NavigationFolder>
                    <NavigationFolder
                      label='Locations for this group'
                      large
                      expanded
                      collapsible={false}
                      className='locations-nav'
                    >
                      <div className='locations-tree-container'>
                        {hierarchyGroup && (
                          <TreeView
                            data={hierarchyGroup}
                            onExpand={onExpand}
                            onClick={onClick}
                          />
                        )}
                      </div>
                    </NavigationFolder>
                  </>
                  )}
            </div>
          </>
          )}
    </div>
  )
}

export default HierarchyGroupViewer
