export const isValidHttpsUri = (uri?: string) => {
  const httpsUriRegex = /^https:\/\/.*/

  if (uri) {
    return httpsUriRegex.test(uri)
  }
}

export const isValidHttpUri = (uri: string) => {
  const httpsUriRegex = /^http:\/\/.*/

  return httpsUriRegex.test(uri)
}
