import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { removeUsersFromUserGroup } from 'store/accountsSlice/users/dispatchers.users'
import { user } from 'types/user'

interface useRemoveUserGroupUserProps {
  id?: string
  user: user
}

export const useRemoveUserGroupUser = ({ id, user }: useRemoveUserGroupUserProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleRemoveUser = () => {
    if (!id) return
    dispatch(removeUsersFromUserGroup({ userGroupId: id, users: [user.id] }))
  }

  return handleRemoveUser
}
