import { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import './SurveyDetailsSetup.scss'
import getSurveyFieldDetails from './getSurveyFieldDetails'
import { useGetLocations, useSaveDetails, useSurveyDetailsSetup } from './hooks'

interface Props {
  surveyType: string
  getAllLocations: LazyQueryExecFunction<any, OperationVariables>
  loadingAllLocations: boolean
}

function SurveyDetailsSetup ({ surveyType, getAllLocations, loadingAllLocations } : Props) {
  const { surveyDetails, handleChange, disabledSaveButton, setSurveyDetails, disableSetup } = useSurveyDetailsSetup()
  const { input, onAdd, onRemove, clearSelection, locations, loadingLocations, handleSearch, values } = useGetLocations(surveyDetails, setSurveyDetails, getAllLocations)
  const { handleSaveDetails, isSavingDetails } = useSaveDetails({ surveyDetails, surveyType })
  const detailFields = getSurveyFieldDetails({
    surveyDetails,
    handleChange,
    disabledSaveButton,
    handleSaveDetails,
    isSavingDetails,
    locations,
    loadingLocations,
    loadingAllLocations,
    onAdd,
    disableSetup,
    onRemove,
    clearSelection,
    handleSearch,
    input,
    values,
    surveyType
  })

  return (
    <div className='details-setup-container'>
      {detailFields.map(({ title, render, toggle }, index) => (
        (title || render) &&
          <div key={index} className='details-input-container'>
            {title &&
              <div className='detail-title-container'>
                {title && <span className='detail-title'>{title}</span>}
                {toggle?.()}
              </div>}
            {render &&
              <div className='detail-content'>
                {render()}
              </div>}
          </div>
      ))}
    </div>
  )
}

export default SurveyDetailsSetup
