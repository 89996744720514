import { useSelector } from 'react-redux'
import { RootState } from 'store'
import './sectionsList.scss'
import Section from './Section/Section'

export default function SectionsList () {
  const { reportCollectionDetail } = useSelector((state: RootState) => state.settings)
  return (
    <div className='sections-list'>
      {reportCollectionDetail?.reportSections.map((section) =>
        <Section
          key={section.id}
          sectionName={section.name}
          reportPages={section.reportPages}
          sectionId={section.id}
        />)}
    </div>
  )
}
