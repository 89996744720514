import { AstronautEmptyState as AstronautEmptyStateComponent } from '@reply-pro/component-library'
import './AstronautEmptyState.scss'

const AstronautEmptyState = ({ copy = "You don't have anything yet!", helper }: { copy?: string, helper?: string }) => {
  return (
    <div className='atronaut-empty-state-container'>
      <div className='astronaut-image'>
        <AstronautEmptyStateComponent />
      </div>
      <div className='text'>
        <span>{copy}</span>
        {helper && <span>{helper}</span>}
      </div>
    </div>
  )
}

export default AstronautEmptyState
