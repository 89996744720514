import { Button, TextInput } from '@reply-pro/component-library'
import { useState, ReactNode } from 'react'
import Modal from 'components/Modal'
import './addNewModal.scss'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'

interface AddNewModalProps {
  toggleIsAddNewModalOpen: () => void
  isAddNewModalOpen: boolean
  dispatchAddNew: (payload: string) => any
  title: string
  children: ReactNode
  inputLabel: string
}

export default function AddNewModal ({ isAddNewModalOpen, toggleIsAddNewModalOpen, dispatchAddNew, children, title, inputLabel }: AddNewModalProps) {
  const [label, setLabel] = useState('')
  const dispatch = useDispatch<AppDispatch>()

  const toggleModalOpen = () => {
    toggleIsAddNewModalOpen()
    setLabel('')
  }

  const addNew = () => {
    dispatch(dispatchAddNew(label))
    toggleModalOpen()
  }

  return (
    <div>
      <Modal
        title={title}
        actions={<Button disabled={!label} onClick={addNew}>Add</Button>}
        showModal={isAddNewModalOpen}
        toggleModalOpen={toggleModalOpen}
      >
        <div className='add-new-modal'>
          {children}
          <TextInput
            label={inputLabel}
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  )
}
