import { FormIcon } from '@reply-pro/component-library'
import FormsManager from 'components/SurveysManager'
import { useSurveysList } from 'components/SurveysManager/ManageSurveysList/hooks'
import SurveyStatus from 'components/SurveysManager/constants'
import { useState } from 'react'
export const FORM = 'Form'
export const FORMS = `${FORM}s`
function ManageForms () {
  const [status, setStatus] = useState<SurveyStatus[keyof SurveyStatus]>('enabled')
  const { data, loading } = useSurveysList({ status, type: FORM.toLowerCase() })
  return (
    <FormsManager
      surveyType={FORM}
      url='/settings/manage-forms/form-setup/'
      title={`Manage ${FORMS}`}
      icon={FormIcon}
      buttonTextContent={`Add New ${FORM}`}
      setStatus={setStatus}
      searchPlaceholder={`Search ${FORMS}`}
      data={data && data.surveys}
      loading={loading}
    />
  )
}

export default ManageForms
