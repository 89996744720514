import { MultiActionButton, HelmetIcon, SettingsHeader } from '@reply-pro/component-library'
import { SetStateAction } from 'react'

interface ManageUsersHeaderProps {
  setIsModalOpen: (value: SetStateAction<boolean>) => void
  setIsAddNewUserModalOpen: (value: SetStateAction<boolean>) => void
}

export default function ManageUsersHeader ({ setIsModalOpen, setIsAddNewUserModalOpen } : ManageUsersHeaderProps) {
  return (
    <SettingsHeader
      title='Manager Users'
      icon={<HelmetIcon />}
      button={
        <MultiActionButton
          onClick={() => setIsAddNewUserModalOpen(true)}
          actions={[
            {
              label: 'Add Existing User',
              onClick: () => setIsModalOpen(true)
            }
          ]}
        >
          Add New User
        </MultiActionButton>
}
    />
  )
}
