import { MutableRefObject } from 'react'

const cleanString = (inputString: string): string => {
  return inputString
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
}

export const downloadQrPng = ({ qrCodeRef, title }: {qrCodeRef: MutableRefObject<null>, title: string}) => {
  if (qrCodeRef.current) {
    const canvas = document.createElement('canvas')
    const scale = 2
    const size = 512 * scale
    canvas.width = size
    canvas.height = size
    const ctx = canvas.getContext('2d')
    const img = new Image(size, size)

    img.onload = () => {
      ctx?.drawImage(img, 0, 0, size, size)
      const pngUrl = canvas.toDataURL('image/png')

      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = `qr-code-${cleanString(title)}.png`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(pngUrl)
    }

    const svgData = new XMLSerializer().serializeToString(qrCodeRef.current)
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
    const url = URL.createObjectURL(svgBlob)
    img.src = url
  }
}
export default downloadQrPng

export const downloadSvg = ({ qrCodeRef, title }: {qrCodeRef: MutableRefObject<null>, title: string}) => {
  if (qrCodeRef.current) {
    const svgData = new XMLSerializer().serializeToString(qrCodeRef.current)
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
    const url = URL.createObjectURL(svgBlob)

    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = `qr-code-${cleanString(title)}.svg`

    document.body.appendChild(downloadLink)
    downloadLink.click()

    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(url)
  }
}
