import { Button, MainModal, PlayIcon, ReloadIcon, Splitter } from '@reply-pro/component-library'
import { Dispatch, SetStateAction } from 'react'
import { renderInputField } from './InputFields/renderInputField'
import { useAdjustSplitter, useHandleEndpoint } from './hooks'
import classNames from 'classnames'
import { Endpoint } from '../data/Webhooks.types'
import './EndpointModal.scss'

interface Props {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  endpoint?: Endpoint
}

const EndpointModal = ({ showModal, setShowModal, endpoint }: Props) => {
  const { endpointInputRef, runButtonRef, isAdjustingRunButtonPosition, adjustRunButtonPosition, setIsAdjustingRunButtonPosition, handleMeasuredSizesChanged } = useAdjustSplitter()
  const { input, loading, output, handleOnChange, runEndpoint } = useHandleEndpoint({ endpoint, adjustRunButtonPosition })

  return (
    <MainModal
      className='endpoint-modal'
      bodyClassName='endpoint-modal-body'
      title={endpoint?.name}
      showModal={showModal}
      toggleModalOpen={() => setShowModal(false)}
      actions={
        <Button onClick={() => setShowModal(false)} disabled={loading}>
          Done
        </Button>
      }
    >
      <div className='endpoint-modal-body-wrapper'>
        <Splitter
          direction='vertical'
          onSplitChanged={() => {
            setIsAdjustingRunButtonPosition(true)
            adjustRunButtonPosition()
          }}
          onMeasuredSizesChanged={handleMeasuredSizesChanged}
          minPrimarySize='25%'
          minSecondarySize='25%'
        >
          {[
            <div key='input' ref={endpointInputRef} className='endpoint-modal-input'>
              {endpoint?.input.map((field) =>
                renderInputField(field, (e) =>
                  handleOnChange(
                    e,
                    field.type,
                    field.name, 'min' in field ? field.min : undefined,
                    'max' in field ? field.max : undefined,
                    'maxLength' in field ? field.maxLength : undefined,
                    'formats' in field ? field.formats : undefined,
                    'maxSizeInBytes' in field ? field.maxSizeInBytes : undefined
                  ), input[field.name]))}
            </div>,
            <div key='output' className='endpoint-modal-output'>
              <pre>
                {
                  !output
                    ? <div className='endpoint-modal-output-description'>
                      {!loading
                        ? <div className='endpoint-modal-output-description-segment'>Click on the <ReloadIcon /> icon to run the Webhook.</div>
                        : <div>Processing...</div>}
                      {/* eslint-disable-next-line react/jsx-indent */}
                      </div>
                    : JSON.stringify(output, null, 2)
                }
              </pre>
            </div>
          ]}
        </Splitter>

        <Button
          ref={runButtonRef}
          className={classNames('endpoint-modal-run-button', { 'adjusting-position': isAdjustingRunButtonPosition })}
          stroked
          onClick={runEndpoint}
          disabled={loading || !endpoint?.input.map(field => field.name).every(element => Object.keys(input).includes(element))}
        >
          {!loading ? <PlayIcon /> : <ReloadIcon className='reload-icon' />}
        </Button>
      </div>
    </MainModal>
  )
}

export default EndpointModal
