import { AstronautEmptyState, ListOfElementsToNavigate, SettingsPageStructure } from '@reply-pro/component-library'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import LocationPermissionsHeader from '../LocationPermissionsHeader'
import LocationPermissionsAside from '../LocationPermissionsAside'
import { RootState } from 'store'
import { LocationGroupEdge } from 'types/location'
import LocationGroupViewer from './LocationGroupViewer'
import { LOCATION_PERMISSIONS } from '../constants'
import { useSelector } from 'react-redux'

const { GROUPS } = LOCATION_PERMISSIONS

function LocationGroupsSettings () {
  const { data, loading } = useSelector((store: RootState) => store.locations.locationGroups)
  const params = useParams()
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <SettingsPageStructure
      settingsHeader={<LocationPermissionsHeader />}
      asideChildren={
        <LocationPermissionsAside parent={GROUPS}>
          <ListOfElementsToNavigate
            loadingList={loading}
            NavLink={NavLink}
            noElementsToShow={
              <div className='no-location-permissions-container'>
                <div className='astronaut-image'>
                  <AstronautEmptyState />
                </div>
                <div className='text'>
                  <span>You have no Location Groups!</span>
                </div>
              </div>
            }
            listOfElements={
              data &&
              data.locationGroups?.edges.map((locationGroup: LocationGroupEdge, index: number) => {
                const { id, name } = locationGroup?.node
                return {
                  id,
                  name,
                  url: `location-permissions/groups/${id}`,
                  index,
                  label: locationGroup?.node?.label?.name
                }
              })
            }
            params={params}
            navigate={navigate}
          />
        </LocationPermissionsAside>
      }
    >
      <LocationGroupViewer
        viewingLocationGroup={id}
        noLocationGroups={data?.locationGroups?.edges?.length === 0}
      />
    </SettingsPageStructure>
  )
}

export default LocationGroupsSettings
