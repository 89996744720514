/* eslint-disable react/jsx-closing-tag-location */
import { LoadingSpinner } from '@reply-pro/component-library'
import { ReactNode, useEffect } from 'react'
import './modalWithFilterButtons.scss'
import FilterSelectButton, { FilterOptions } from './FilterSelectButton'
import Modal from 'components/Modal/Modal'
import { useDispatch } from 'react-redux'

export interface ModalWithFilterButtonsProps {
  isShowRelationModal: boolean
  toggleModalOpen: () => void
  inboundInteractionName?: string
  filterBy?: FilterOptions
  title: string
  loading?: boolean
  selectedFilter?: string
  setSelectedFilter?: (element: string) => void
  dispatcher?: any
  elementsToDispatch?: {[key: string]: any}
  children?: ReactNode
  deactivateButton?: string | boolean
  searchPlaceholder?: string
  otherFilters?: ReactNode
  className?: string
  toggleFilter?: ReactNode
  rightTopElement?: ReactNode
  onClick?: () => void
}

export default function ModalWithFilterButtons ({
  otherFilters,
  dispatcher,
  elementsToDispatch,
  isShowRelationModal,
  toggleModalOpen,
  filterBy,
  title,
  loading,
  selectedFilter,
  setSelectedFilter,
  children,
  deactivateButton,
  className,
  toggleFilter,
  rightTopElement,
  onClick
}: ModalWithFilterButtonsProps) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isShowRelationModal && dispatcher && !loading) {
      dispatch(dispatcher(elementsToDispatch))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowRelationModal, selectedFilter, dispatcher, title])

  return (
    <Modal
      className='modal-with-filter-buttons'
      title={title}
      showModal={isShowRelationModal}
      toggleModalOpen={toggleModalOpen}
    >
      <div className='filter-buttons'>
        <div className='filters'>
          <div className='toggle-and-other-filters'>
            <div className='toggle'>{toggleFilter}</div>
            <div className='other-filters'>{otherFilters}</div>
          </div>
          <div className='filter-select-buttons'>
            {filterBy &&
              <FilterSelectButton
                filterBy={filterBy}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                loading={loading}
                deactivateButton={deactivateButton}
                className={className}
              />}
            {rightTopElement && rightTopElement}
          </div>
        </div>
        <div className='line-2' />
      </div>
      {loading
        ? (
          <div className='loading-relation-interactions'>
            <LoadingSpinner />
          </div>
          )
        : (
          <>
            <div className='children-list'>{children}</div>
          </>
          )}
    </Modal>
  )
}
