import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ACTIVITY_WORKFLOWS } from 'requests/activities'
import { RootState } from 'store'

export const useGetActivityWorkflows = (onAttributeChange: (attribute: string, value: any) => void) => {
  const { data, loading } = useQuery(ACTIVITY_WORKFLOWS)
  const currentUser = useSelector((state: RootState) => state.metadata.user.uuid)

  useEffect(() => {
    onAttributeChange('due', 2)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser) {
      onAttributeChange('user', currentUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return { workflows: data, loading }
}
