export interface eventTargetValue {
  target: {
    value: string
  }
}

export const getObjectEntriesWithTypes = <T extends object>(object: T): [keyof T, T[keyof T]][] => {
  return Object.entries(object) as [keyof T, T[keyof T]][]
}

export const getObjectKeysWithTypes = <T extends object>(object: T): (keyof T)[] => {
  return Object.keys(object) as (keyof T)[]
}

export function getLowerCaseStringWithType<T extends string> (input: T): Lowercase<T> {
  return input.toLowerCase() as Lowercase<T>
}

export type ConcatenatedString<T extends readonly string[]> =
  T extends [infer First, ...infer Rest]
    ? First extends string
      ? `${First & string}${ConcatenatedString<Extract<Rest, readonly string[]>>}`
      : never
    : ''

export function concatStringsWithType<T extends readonly string[]> (...inputs: T): ConcatenatedString<T> {
  return inputs.join('') as ConcatenatedString<T>
}
