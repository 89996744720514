import { SelectInput, TextInput } from '@reply-pro/component-library'
import { timeZoneObject } from 'utils/common/timeZoneChoices'

const TYPE_HERE = 'Type here'

export const newAccountInitialState: {
  name: string
  legacyId?: number
  timeZone: string
  } = {
    name: '',
    legacyId: undefined,
    timeZone: ''
  }

export const formConfig = {
  name: { title: 'Account Name', placeholder: TYPE_HERE, mandatory: true, type: 'text', component: TextInput, options: undefined },
  legacyId: { title: 'Legacy ID', placeholder: TYPE_HERE, mandatory: false, type: 'number', component: TextInput, options: undefined },
  timeZone: { title: 'Time Zone', placeholder: TYPE_HERE, mandatory: true, type: 'text', component: SelectInput, options: timeZoneObject }
}
