import { Tab, Tabs } from '@reply-pro/component-library'
import ManageSurveysList from './ManageSurveysList'
import SurveyStatus from './constants'
import { Dispatch, FunctionComponent, SVGProps, SetStateAction } from 'react'
import { SurveyNode } from 'store/surveysSlice/surveyTypes'

const { ENABLED, DISABLED } = SurveyStatus
interface ManageSurveysListProps {
  surveyType: string
  url: string
  title: string
  icon: FunctionComponent<SVGProps<SVGSVGElement> & {
      title?: string | undefined;
  }>
  buttonTextContent: string
  setStatus: Dispatch<SetStateAction<SurveyStatus[keyof SurveyStatus]>>
  searchPlaceholder: string
  data?: {edges: SurveyNode[]}
  loading?: boolean
}

function SurveysManager ({ surveyType, url, title, icon, buttonTextContent, data, loading, searchPlaceholder, setStatus }:ManageSurveysListProps) {
  return (
    <div className='full-screen-card-tabs-settings'>
      <div className='tabs-settings-container'>
        <Tabs
          tabHeaderWidth='30rem'
          tabHeaderTopMargin='1.5rem'
          smallTab
        >
          <div className='tabs-container'>
            <Tab
              key='enabled-surveys-tab'
              label={`${ENABLED.toLocaleUpperCase()} ${surveyType.toLocaleUpperCase()}S`}
            >
              <ManageSurveysList surveyType={surveyType} status={ENABLED} url={url} title={title} icon={icon} buttonTextContent={buttonTextContent} data={data} loading={loading} setStatus={setStatus} searchPlaceholder={searchPlaceholder} />
            </Tab>
            <Tab
              key='disabled-surveys-tab'
              label={`${DISABLED.toLocaleUpperCase()} ${surveyType.toLocaleUpperCase()}S`}
            >
              <ManageSurveysList surveyType={surveyType} status={DISABLED} url={url} title={title} icon={icon} buttonTextContent={buttonTextContent} data={data} loading={loading} setStatus={setStatus} searchPlaceholder={searchPlaceholder} />
            </Tab>
          </div>
        </Tabs>
      </div>
    </div>
  )
}

export default SurveysManager
