import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { POST_SURVEY_ACTIONS, POST_SURVEY_ACTION_REMOVE } from 'requests/Surveys'
import { RootState } from 'store'

export const usePostSurveyActions = (id: string) => {
  const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false)
  const { localData } = useSelector((store: RootState) => store.surveys.surveyQuestions)
  const toggleAddActionModal = () => {
    setIsAddActionModalOpen(prev => !prev)
  }

  const { data, loading, refetch } = useQuery(POST_SURVEY_ACTIONS, {
    variables: { survey: id }
  })

  function getQuestion (questionNameJson: string): string | null {
    const questionNameObj = JSON.parse(questionNameJson)?.if?.[0]
    const questionName = questionNameObj?.['===']?.[0]?.var

    if (!questionName || !localData || !localData.pages) return null

    const page = localData.pages.find((page: any) =>
      page.elements.some((element: any) => element.name === questionName)
    )

    return page?.elements.find((element: any) => element.name === questionName)?.title ?? null
  }

  function getAnswer (data: string): string | null {
    const regex = /{"var":\s*"[^"]+"},\s*"([^"]+)"/
    const match = data.match(regex)
    return match ? match[1] : null
  }

  const getPostActionName = (abbreviation: string): string => {
    const abbreviationToText: { [key: string]: string } = {
      MO: 'Mark Interaction as Open',
      T: 'Tag Interaction',
      AR: 'Auto Reply',
      CA: 'Create Activity',
      MP: 'Match Purchase',
      CC: 'Create Charticket'
    }

    return abbreviationToText[abbreviation] ? abbreviationToText[abbreviation] : abbreviation
  }

  return { isAddActionModalOpen, toggleAddActionModal, data, loading, getQuestion, getAnswer, getPostActionName, refetch }
}

export const useRemovePostSurveyAction = (refetch: () => void) => {
  const [postActionSelected, setPostActionSelected] = useState<string | undefined>(undefined)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [removePostSurveyAction, { loading }] = useMutation(POST_SURVEY_ACTION_REMOVE)

  const openConfirmationModal = (id: string) => {
    setIsConfirmationModalOpen(true)
    setPostActionSelected(id)
  }
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleRemovePostSurveyAction = async () => {
    await removePostSurveyAction({ variables: { input: { postAction: postActionSelected } } })
    refetch()
    closeConfirmationModal()
  }
  return { handleRemovePostSurveyAction, openConfirmationModal, closeConfirmationModal, isConfirmationModalOpen, loading }
}
