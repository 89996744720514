import { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { addUsersToUserGroup, getUserGroupUsers } from 'store/accountsSlice/users/dispatchers.users'
import { user } from 'types/user'

interface useSelectUsersToAddProps {
  userGroupId?: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  searchValueUserGroupUsers?: string
}

export const useSelectUsersToAdd = ({ userGroupId, setShowModal, searchValueUserGroupUsers }: useSelectUsersToAddProps) => {
  const { loading, error } = useSelector((store: RootState) => store.users.addUsersToUserGroup)

  const [usersToAdd, setUsersToAdd] = useState<user[] | undefined>(undefined)

  const addUser = (user: user) => {
    if (usersToAdd) {
      if (usersToAdd.every(userToAdd => userToAdd.id !== user.id)) {
        setUsersToAdd([...usersToAdd, user])
      }
    } else {
      setUsersToAdd([user])
    }
  }

  const removeUser = (id: string) => {
    const remainingUsers = usersToAdd?.filter(user => user.id !== id)
    setUsersToAdd(remainingUsers)
  }

  const dispatch = useDispatch<AppDispatch>()

  const handleOnSubmit = async () => {
    if (userGroupId && usersToAdd) {
      const action = await dispatch(addUsersToUserGroup({ userGroupId, users: usersToAdd?.map(userToAdd => userToAdd.id) }))

      if (action.payload) {
        setShowModal(false)
        dispatch(getUserGroupUsers({ userGroupId, search: searchValueUserGroupUsers }))
      }
    }
  }

  return { usersToAdd, setUsersToAdd, addUser, removeUser, handleOnSubmit, loading, error }
}
