import { roles } from '../AddUsersModal/utils'

const TYPE_HERE = 'Type here'
const SELECT = 'Select'

export const newUserInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  type: ''
}

export const formConfig = {
  firstName: { label: 'FIRST NAME', title: undefined, placeholder: TYPE_HERE, mandatory: true, type: 'text', component: 'textInput', options: undefined },
  lastName: { label: 'LAST NAME', title: undefined, placeholder: TYPE_HERE, mandatory: false, type: 'text', component: 'textInput', options: undefined },
  email: { label: undefined, title: 'Email', placeholder: TYPE_HERE, mandatory: true, type: 'text', component: 'textInput', options: undefined },
  type: { label: undefined, title: 'Role', placeholder: SELECT, mandatory: true, type: 'select', component: 'selectInput', options: roles }
}
