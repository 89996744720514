import { Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { addUserGroup, getUserGroups } from 'store/accountsSlice/users/dispatchers.users'

interface useAddUserGroupProps {
  setShowModal: Dispatch<SetStateAction<boolean>>
  searchValueUserGroups?: string
}

export const useAddUserGroup = ({ setShowModal, searchValueUserGroups }: useAddUserGroupProps) => {
  const { loading, error } = useSelector((store: RootState) => store.users.addUserGroup)

  const dispatch = useDispatch<AppDispatch>()

  async function handleOnSubmit (userGroupData: { name: string, description?: string }) {
    const action = await dispatch(addUserGroup(userGroupData))

    if (action.payload) {
      setShowModal(false)
      dispatch(getUserGroups({ search: searchValueUserGroups }))
    }
  }

  return { handleOnSubmit, loading, error }
}
