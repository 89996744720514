import Modal from 'components/Modal'
import QRCode from 'react-qr-code'
import './QrCodeModal.scss'
import { Button, DownloadFileIcon } from '@reply-pro/component-library'
import { useRef } from 'react'
import { downloadQrPng, downloadSvg } from './utils'

interface QrCodeModalProps {
  url: string;
  showModal: boolean;
  title: string;
  toggleQrModalOpen: () => void;
}

function QrCodeModal ({
  title,
  url,
  showModal,
  toggleQrModalOpen
}: QrCodeModalProps) {
  const qrCodeRef = useRef(null)

  return (
    <Modal
      showModal={showModal}
      toggleModalOpen={toggleQrModalOpen}
      className='qr-modal'
      title={`${title}`}
      actions={
        <div className='download-button'>
          <Button stroked className='button' small onClick={() => downloadSvg({ qrCodeRef, title })}><DownloadFileIcon /><span>SVG</span></Button>
          <Button className='button' small onClick={() => downloadQrPng({ qrCodeRef, title })}><DownloadFileIcon /><span>PNG</span></Button>
        </div>
      }
    >
      <div className='qr-code-card-container'>
        <div className='qr-code-card'>
          <QRCode
            className='qr-code'
            fgColor='dark'
            bgColor='white'
            value={url}
            size={256}
            ref={qrCodeRef}
          />
        </div>
      </div>
    </Modal>
  )
}

export default QrCodeModal
