import { ArrowLeft, ArrowRight, WarningMessage } from '@reply-pro/component-library'
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react'
import './tagsManagerModal.scss'
import classNames from 'classnames'
import RightModal from 'components/Modal/RightModal'

interface TagsManagerModalProps {
  showModal: boolean
  toggleModalOpen?: () => void
  title: string
  children: ReactNode
  locationName?: string
  icon?: ReactNode
  warningMessage?: string
  setIndex?: Dispatch<SetStateAction<number>>
  interactionLocationsLength?: number
  index?: number
}

export default function InteractionTagsManagerModal ({
  interactionLocationsLength,
  index,
  setIndex,
  showModal,
  toggleModalOpen,
  title,
  children,
  locationName,
  icon,
  warningMessage
}: TagsManagerModalProps) {
  useEffect(() => {
    if (setIndex && index) {
      setIndex(index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <RightModal
      showModal={showModal}
      toggleModalOpen={toggleModalOpen}
      title={title}
      className='location-manager-modal'
    >
      <div className='location-manager-modal-title'>
        <div className='icon'>{icon}</div>
        <div className='name'>
          {setIndex && typeof index === 'number' && (
            <div>
              <ArrowLeft
                className={classNames('arrow-left', { disabled: index === 0 })}
                onClick={() => index > 0 && setIndex(index - 1)}
              />
            </div>
          )}
          <h1>{locationName}</h1>
          {interactionLocationsLength && setIndex && typeof index === 'number' && (
            <ArrowRight
              className={classNames('arrow-right', { disabled: index === interactionLocationsLength - 1 })}
              onClick={() => index < interactionLocationsLength - 1 && setIndex(index + 1)}
            />
          )}
        </div>
      </div>
      {warningMessage && <WarningMessage warningMessage={warningMessage} />}
      {children}
    </RightModal>
  )
}
