import { dataLayerPush } from './gtm'

export function userSessionTrackingEvent (userId: string) {
  dataLayerPush({ event: 'userSession', userId })
}

export function addNewUserTrackingEvent (value: string) {
  dataLayerPush({ event: 'addNewUser', value })
}

export function resetPasswordRequestTrackingEvent () {
  dataLayerPush({ event: 'resetPasswordRequest' })
}

export function changeUserPasswordTrackingEvent () {
  dataLayerPush({ event: 'changeUserPassword' })
}
