import { Outlet } from 'react-router-dom'
import './AppHeader.scss'

function AppHeader () {
  return (
    <header className='app-header'>
      <div className='context-header'>
        <Outlet />
      </div>
    </header>
  )
}

export default AppHeader
