import { NavLink, useLocation } from 'react-router-dom'
import { NavigationLink, NavigationFolder, Reports, HelmetIcon, Conditions, AccountIcon, SurveyIcon, UserGroup as UserGroupIcon, FormIcon, MaintenanceIcon, CodingIcon } from '@reply-pro/component-library'
import LeftNav from 'components/LeftNavLayout/LeftNavLayout'
import Logout from './Logout'
import ManageAccounts from '../ManageAccounts'
import { pageNavTrackingEvent } from 'utils/dataTracking/navTracking'
import './settingsNav.scss'

function SettingsNav () {
  const { pathname } = useLocation()

  return (
    <>
      <LeftNav>
        <div className='settings-nav-container'>
          <ManageAccounts />
          <div className='settings-nav'>
            <NavigationFolder className='nav-folder' label='Account' defaultExpanded>
              <NavigationLink
                className={pathname === '/settings/account-details' ? 'active' : ''}
                component={NavLink}
                to='/settings/account-details'
                onClick={() => pageNavTrackingEvent('account-details')}
                icon={<AccountIcon />}
              >
                Account Details
              </NavigationLink>
              <NavigationLink
                className={pathname.includes('/settings/manage-reports') ? 'active' : ''}
                component={NavLink}
                to='/settings/manage-reports'
                onClick={() => pageNavTrackingEvent('manage-reports')}
                icon={<Reports />}
              >
                Manage Reports
              </NavigationLink>
              <NavigationLink
                className={pathname === '/settings/manage-users' ? 'active' : ''}
                component={NavLink}
                to='/settings/manage-users'
                onClick={() => pageNavTrackingEvent('manage-users')}
                icon={<HelmetIcon />}
              >
                Manage Users
              </NavigationLink>
              <NavigationLink
                className={pathname === '/settings/user-groups' ? 'active' : ''}
                component={NavLink}
                to='/settings/user-groups'
                onClick={() => pageNavTrackingEvent('user-groups')}
                icon={<UserGroupIcon />}
              >
                User Groups
              </NavigationLink>
              <NavigationLink
                id='settings--SettingsNav--location-permissions'
                className={pathname.startsWith('/settings/location-permissions') ? 'active' : ''}
                component={NavLink}
                to='/settings/location-permissions'
                onClick={() => pageNavTrackingEvent('location-permissions')}
                icon={<Conditions />}
              >
                Location Permissions
              </NavigationLink>
              <NavigationLink
                id='settings--SettingsNav--manage-surveys'
                className={pathname.startsWith('/settings/manage-surveys') ? 'active' : ''}
                component={NavLink}
                to='/settings/manage-surveys'
                onClick={() => pageNavTrackingEvent('manage-surveys')}
                icon={<SurveyIcon />}
              >
                Manage Surveys
              </NavigationLink>
              <NavigationLink
                id='settings--SettingsNav--manage-forms'
                className={pathname.startsWith('/settings/manage-forms') ? 'active' : ''}
                component={NavLink}
                to='/settings/manage-forms'
                onClick={() => pageNavTrackingEvent('manage-forms')}
                icon={<FormIcon />}
              >
                Manage Forms
              </NavigationLink>
              <NavigationLink
                id='settings--SettingsNav--maintenance'
                className={pathname.startsWith('/settings/maintenance') ? 'active' : ''}
                component={NavLink}
                to='/settings/maintenance'
                onClick={() => pageNavTrackingEvent('maintenance')}
                icon={<MaintenanceIcon />}
              >
                Maintenance
              </NavigationLink>
              <NavigationLink
                id='settings--SettingsNav--client-webhooks'
                className={pathname.startsWith('/settings/client-webhooks') ? 'active' : ''}
                component={NavLink}
                to='/settings/client-webhooks'
                onClick={() => pageNavTrackingEvent('client-webhooks')}
                icon={<CodingIcon />}
              >
                Webhooks
              </NavigationLink>
              <Logout />
            </NavigationFolder>
          </div>
        </div>
      </LeftNav>
    </>
  )
}

export default SettingsNav
