import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { addUserGroupToReportPage, addUserToReportPage, removeUserFromReportPage, removeUserGroupFromReportPage } from 'store/settingsSlice'
import { user, userGroup } from 'types/user'

export const useAddUserToReportPage = () => {
  const dispatch: AppDispatch = useDispatch()
  return (user: user) => dispatch(addUserToReportPage(user))
}

export const useRemoveUserToReportPage = () => {
  const dispatch: AppDispatch = useDispatch()
  return (userId: string) => dispatch(removeUserFromReportPage(userId))
}

export const useAddUserGroupToReportPage = () => {
  const dispatch: AppDispatch = useDispatch()
  return (userGroup: userGroup) => dispatch(addUserGroupToReportPage(userGroup))
}

export const useRemoveUserGroupToReportPage = () => {
  const dispatch: AppDispatch = useDispatch()
  return (userGroupId: string) => dispatch(removeUserGroupFromReportPage(userGroupId))
}
