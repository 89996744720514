export function validatePassword (password: string) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:<>,.?~\\/-]).{8,}$/

  if (password.length < 8) {
    return 'Password must be at least 8 characters long.'
  }

  if (passwordRegex.test(password)) {
    return 'success'
  }

  const missingCriteria = []

  if (!/(?=.*[A-Z])/.test(password)) {
    missingCriteria.push('an uppercase letter')
  }

  if (!/(?=.*[a-z])/.test(password)) {
    missingCriteria.push('a lowercase letter')
  }

  if (!/(?=.*\d)/.test(password)) {
    missingCriteria.push('a number')
  }

  if (!/(?=.*[!@#$%^&*()_+{}[\]:<>,.?~\\/-])/.test(password)) {
    missingCriteria.push('a special character')
  }

  return `Use ${missingCriteria.join(', ')}.`
}
