import './LeftNavLayout.scss'
import React from 'react'

type Props = {
  children?: React.ReactNode
}

function LeftNavLayout ({ children }: Props) {
  return (
    <div className='left-nav'>
      {children}
    </div>
  )
}

export default LeftNavLayout
